import React, { useEffect, useState } from "react";
import "./HomepageModifier.css";
import Drag from "../../assets/images/drag.svg"
import { List, arrayMove } from "react-movable";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { getTokenApi, putTokenApi } from "../../api_interface/apis";
import { CATEGORY_HOMEPAGE, CATEGORY_PATH, HIBEE_PRODUCT_URL, HOMEPAGE_VISIBILITY } from "../../api_interface/apiURLs";
import MainTemplate from "../../components/MainTemplate";

function HomepageModifierCategory() {

	const [loader, setLoader] = useState(false)
	const [categories, setCategories] = useState([])

	// const [itemOffset, setItemOffset] = useState(0);
	// const itemsPerPage = 10
	// const endOffset = itemOffset + itemsPerPage;
	// const pageCount = Math.ceil(categories.length / itemsPerPage);
	// const currentItems = categories.slice(itemOffset, endOffset);

	// const handlePageClick = (event) => {
	// 	const newOffset = (event.selected * itemsPerPage) % categories.length;
	// 	setItemOffset(newOffset);
	// };

	const updateDataVisibility = async (id, activated, index) => {
		setCategories([
			...categories.slice(0, index),
			{ ...categories?.[index], activated },
			...categories?.slice(index + 1)
		])
		try {
			const res = await putTokenApi(HIBEE_PRODUCT_URL + CATEGORY_PATH + HOMEPAGE_VISIBILITY + `/${id}?activated=${activated}`)
			if (res?.data?.message) {
				toast.success(res?.data?.message || "Visibility Updated", { position: "top-center", autoClose: 2000 })
			}
		} catch (err) {
			const message = err?.response?.data?.message || "Something went wrong";
			toast.error(message, { position: "top-center", autoClose: 2000 });
		}

	}

	const handleOrderChanged = async ({ oldIndex, newIndex }) => {
		const newArray = arrayMove(categories, oldIndex, newIndex);
		setCategories(newArray);
		try {
			const payload = newArray?.map((el, index) => ({ id: el?._id, index, title: el?.name }))
			const res = await putTokenApi(HIBEE_PRODUCT_URL + CATEGORY_PATH + CATEGORY_HOMEPAGE, payload)
			if (res?.data?.message) {
				toast.success(res?.data?.message || "Categories Order Updated", { position: "top-center", autoClose: 2000 })
			}
		} catch (err) {
			const message = err?.response?.data?.message || "Something went wrong";
			toast.error(message, { position: "top-center", autoClose: 2000 });
		}
	};

	const getCategories = async () => {
		try {
			setLoader(true)
			const res = await getTokenApi(HIBEE_PRODUCT_URL + CATEGORY_PATH)
			if (res?.data?.data) {
				setCategories(res?.data?.data?.sort((a, b) => a?.index > b?.index ? 1 : -1))
			}
			setLoader(false)
		} catch (err) {
			const message = err?.response?.data?.message || "Something went wrong";
			toast.error(message, { position: "top-center", autoClose: 2000 });
		}
	}

	useEffect(() => {
		getCategories()
	}, [])

	return (
		<MainTemplate categoryName="Categories" categoryList={["Homepage Modifier", "Categories	"]}>
			<div className="row mb-4 rounded p-2 px-4 bg-white overflow-auto text-center">
				<div className="row font-weight-bold text-uppercase" style={{ marginLeft: "0px" }}>
					<div className="col-1 p-2"></div>
					<div className="col-1 p-2" style={{ marginLeft: '3px' }}>No.</div>
					<div className="col-4 p-2">Title</div>
					<div className="col-4 p-2">Visibility</div>
					<div className="col-1 p-2"></div>
					<div className="col-1 p-2"></div>
				</div>
				{
					loader == false ?
						<List
							values={categories}
							onChange={handleOrderChanged}
							renderList={({ children, props }) => (
								<div
									// className="row homepageModifierCategoryScroll overflow-auto" {...props}
									className="homepageModifierCategoryScroll overflow-auto" {...props}
								>
									{children}
								</div>
							)}
							renderItem={({ value, props, isDragged }) => (

								<div className="row border rounded p-3 mb-2 bg-white" {...props}>
									<div className="col-1 p-2 d-flex flex-column">
										{
											props?.key > 0
												?
												<svg onClick={() => handleOrderChanged({ oldIndex: props?.key, newIndex: props?.key - 1 })} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up" viewBox="0 0 16 16">
													<path d="M3.204 11h9.592L8 5.519 3.204 11zm-.753-.659 4.796-5.48a1 1 0 0 1 1.506 0l4.796 5.48c.566.647.106 1.659-.753 1.659H3.204a1 1 0 0 1-.753-1.659z" />
												</svg>
												:
												""
										}
										{
											props?.key < categories?.length - 1
												?
												<svg onClick={() => handleOrderChanged({ oldIndex: props?.key, newIndex: props?.key + 1 })} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down" viewBox="0 0 16 16">
													<path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z" />
												</svg>
												:
												""
										}
									</div>
									<div className="col-1 p-2">{!isNaN(props?.key) ? props?.key + 1 : ""}</div>
									<div className="col-4 p-2">
										<Link to={`/homepage-modifier/categories/${value?._id}`} style={{ textDecoration: "none" }}>
											{value?.name}
										</Link>
									</div>
									<div className="col-4 p-2">
										<label className="switch">
											<input className="switch-input" type="checkbox" checked={value?.activated} onChange={e => updateDataVisibility(value?._id, e.target.checked, props?.key)} />
											<span className="switch-label"></span>
											<span className="switch-handle"></span>
										</label>
									</div>
									<div className="col-1 p-2"><img style={{ cursor: isDragged ? 'grabbing' : 'grab', }} data-movable-handle src={Drag} /></div>
									<div className="col-1 p-2">
										{/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots" viewBox="0 0 16 16">
											<path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
										</svg> */}
									</div>
								</div>
							)}
						/>
						:
						(
							<div className="text-center mt-5">
								<button
									class="btn mt-5"
									style={{ backgroundColor: "#FFEDC2" }}
									type="button"
								>
									<span
										class="spinner-border spinner-border-sm"
										role="status"
										aria-hidden="true"
									></span>{" "}
									Loading...
								</button>
							</div>
						)
				}
			</div>

			{/* <div className="row m-2 d-flex justify-content-center align-items-center">
				<ReactPaginate
					breakLabel="..."
					nextLabel=">"
					onPageChange={handlePageClick}
					pageRangeDisplayed={3}
					pageCount={pageCount}
					previousLabel="<"
					renderOnZeroPageCount={null}
					pageClassName="page-item mb-0"
					subContainerClassName="pages "
					pageLinkClassName="page-link"
					previousClassName="page-item"
					previousLinkClassName="page-link"
					nextClassName="page-item"
					nextLinkClassName="page-link"
					breakClassName="page-item"
					breakLinkClassName="page-link"
					marginPagesDisplayed={2}
					containerClassName="pagination"
					activeClassName="active"
				/>
			</div> */}
		</MainTemplate>
	);
}

export default HomepageModifierCategory;