import React, { useEffect, useState, useMemo } from "react";
import "./NotificationList.css";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { deleteTokenApi, getTokenApi, postTokenApi } from "../../api_interface/apis";
import { HIBEE_ADMIN_URL, REACT_NOTIFICATIONS_PATH } from "../../api_interface/apiURLs";
import { customActionModalStyles, customModalStyles, debounce } from "../../utils/helperFunctin";
import MainTemplate from "../../components/MainTemplate";

function NotificationList() {
  const [notification, setNotification] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [loader, setLoader] = useState(false);
  const [modal, setModal] = useState(false);
  const [notificationData, setNotificationData] = useState({
    title: "",
    push_datetime: "",
    push_date: "",
    push_time: "",
    description: "",
    image: "",
  });
  const [actionModal, setActionModal] = useState({
    loader: false,
    show: false,
    type: "",
    data: {},
  });
  const [notificationListLoader, setNotifiicationListLoader] = useState(false)
  const [img1, setImg1] = useState('')
  const [sort, setSort] = useState("")
  const [query, setQuery] = useState("")

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const endOffset = itemOffset + itemsPerPage;
  const pageCount = Math.ceil(notification.length / itemsPerPage);
  const currentItems = notification.slice(itemOffset, endOffset);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % notification.length;
    setItemOffset(newOffset);
  };

  const getNotification = async search => {
    setNotifiicationListLoader(true)
    const res = await getTokenApi(HIBEE_ADMIN_URL + REACT_NOTIFICATIONS_PATH + `?sort=${sort}&search=${search || query || ""}`);
    if (res.status == 200) {
      setNotifiicationListLoader(false)
      setNotification(res?.data?.data.reverse());

    }
  };

  const Submit = async (e) => {
    e.preventDefault();
    setLoader(true);
    const { title, description, _id, push_date, push_time, image } = notificationData;
    const fd = new FormData();
    fd.append("title", title);
    fd.append("description", description);
    fd.append("push_date", push_date);
    fd.append("push_time", push_time);
    fd.append("image", image);
    if (_id) {
      fd.append("_id", _id);
    }
    const res = await postTokenApi(HIBEE_ADMIN_URL + REACT_NOTIFICATIONS_PATH, fd, { "Content-Type": "multipart/form-data" });
    if (res.status == 200) {
      setModal(false);
      setLoader(false);
      getNotification();
    }
  };

  const deleteOffer = async (id) => {
    const res = await deleteTokenApi(HIBEE_ADMIN_URL + REACT_NOTIFICATIONS_PATH + `/${id}`);
    setActionModal({});
    getNotification();
  };

  const archiveOffer = (cat) => {
    setActionModal({ ...actionModal, loader: true });

    setActionModal({});
    getNotification();
    // setActionModal({...actionModal, show:true})
  };
  function disableDates() {
    var dtToday = new Date();

    var month = dtToday.getMonth() + 1;
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();
    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();

    var maxDate = year + "-" + month + "-" + day;
    return maxDate;
  }
  const updateSort = e => {
    setSort(e.target.value)
  }

  const getSearchedData = useMemo(() => debounce(async (search) => {
    getNotification(search)
  }, 500), [])

  useEffect(() => {
    getNotification()
  }, [sort])

  useEffect(() => {
    getSearchedData(query)
  }, [query])



  return (
    <MainTemplate
      categoryName="Notification Listing"
      categoryList={["Admin", "Notification Listing"]}
    >
      <div className="row mb-4 rounded p-2 bg-white">
        <div className="col-sm-6 my-3 col-md-2">
          <select className="t-12 form-select" aria-label="Default select example" onChange={updateSort}>
            <option value="" selected>Sort by</option>
            <option value="name">Alphabatically</option>
            <option value="dateold">Date: Acsending</option>
            <option value="datenew">Date: Descending</option>
          </select>
        </div>
        <div className="col-sm-6 my-3 col-md-2">
          <select className="t-12 form-select" aria-label="Default select example" onChange={e => setItemsPerPage(e.target.value)}>
            <option value="10" selected>10 per page</option>
            <option value="20">20 per page</option>
            <option value="50">50 per page</option>
            <option value="100">100 per page</option>
          </select>
        </div>
        <div className="col-sm-6 my-3 col-md-3">
          <input type="text" name="query" className="t-12 form-control" placeholder="Search" value={query} onChange={e => setQuery(e.target.value)} />
        </div>
        <div className="col-sm-6 my-3 col-md-2 offset-2 mobile-addnoti">
          <Link
            to="/notification"
            state={{ data: notificationData }}
            className="text-dark px-3 py-1 rounded"
            style={{ textDecoration: "none" }}
          >
            <button
              type="button"
              className="t-12 w-100 btn btn-dark text-warning"
            >
              Add notification
            </button>
          </Link>
        </div>
       

        {/* Update Notification */}

        <Modal
          isOpen={modal}
          onRequestClose={() => {
            setModal(false);
            setNotificationData({});
            setLoader(false);
          }}
          style={customModalStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <div className="container containerbg overflow-auto rounded bg-white row w-100 ">
            <div className="px-3 py-3">
              <div className="row">
                <span className="d-flex justify-content-end cursorPointer">
                  <svg
                    onClick={() => {
                      setModal(false);
                      setNotificationData({});
                      setLoader(false);
                    }}
                    className="d-inline"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                  </svg>
                </span>
              </div>
              <div className="row">
                <div className="form-group my-3">
                  <label className="my-2">Notifications Title</label>
                  <input
                    value={notificationData?.title}
                    onChange={(e) =>
                      setNotificationData({ ...notificationData, title: e.target.value })
                    }
                    type="text"
                    className="placeholder-light form-control my-2 py-2 px-3"
                    placeholder="eg: OFFER25"
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group my-3">
                  <label className="my-2">Notification Decription</label>
                  <input
                    type="text"
                    value={notificationData?.description}
                    onChange={(e) =>
                      setNotificationData({ ...notificationData, description: e.target.value })
                    }
                    className="placeholder-light form-control my-2 py-2 px-3"
                    placeholder="eg: 20"
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group my-3">
                  <label className="my-2">Notification Date</label>
                  <input
                    type="date"
                    value={notificationData?.push_date}
                    min={disableDates()}
                    onChange={(e) =>
                      setNotificationData({ ...notificationData, push_date: e.target.value })
                    }
                    className="placeholder-light form-control my-2 py-2 px-3"
                    placeholder="eg: 20"
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group my-3">
                  <label className="my-2">Notification Time</label>
                  <input
                    type="time"
                    value={notificationData?.push_time}
                    onChange={(e) => setNotificationData({ ...notificationData, push_time: e.target.value })}
                    className="placeholder-light form-control my-2 py-2 px-3"
                    placeholder="eg: 20"
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group py-2  mb-4 position-relative">
                  <label>Upload Image</label>
                  <input
                    type="file"
                    name="image"
                    className="homepageModifierInput opacity0 position-absolute w-75 placeholder-light form-control py-2 px-3"
                    placeholder="eg: https://www.apple.com/in/watch"
                    onChange={(e) => { setNotificationData({ ...notificationData, image: e?.target?.files?.[0] }); setImg1(e?.target?.files?.[0]) }}
                  />
                  <div className={`homepageModifierInput select-text-light d-flex align-items-center justify-content-center border rounded w-75}`} >
                    {
                      notificationData?.image ? (
                        <img
                          src={img1 == '' ? notificationData?.image : URL.createObjectURL(img1)}
                          className="rounded h-100 w-100 shadow"
                          alt="img"
                        />
                      ) : (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-cloud-arrow-up"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z"
                            />
                            <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                          </svg>
                          <span className="px-2">Upload Photo</span>
                        </>
                      )}
                  </div>
                </div>
              </div>

              <div className="form-group my-3">
                <button
                  disabled={loader}
                  className="btn btn-dark text-warning px-5"
                  onClick={Submit}
                >
                  {loader ? (
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span class="sr-only"></span>
                    </div>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={actionModal?.show}
          onRequestClose={() => setActionModal({})}
          style={customActionModalStyles}
          contentLabel="Action Modal"
          ariaHideApp={false}
        >
          <div className="px-3 py-3">
            <div className="row">
              <span className="d-flex justify-content-end cursorPointer">
                <svg
                  onClick={() => setActionModal({})}
                  className="d-inline"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                </svg>
              </span>
            </div>
            <div className="row">
              <h4 className="text-danger d-flex justify-content-center">
                Do you want to {actionModal?.type} the notification?
              </h4>
            </div>
            <div className="row my-5">
              <h5 className="d-flex justify-content-center">
                {actionModal?.data?.name}
              </h5>
            </div>
            <div className="form-group my-3 d-flex justify-content-center">
              <button
                disabled={actionModal?.loader}
                className="btn btn-dark me-3 mb-2 text-warning px-5"
                onClick={() =>
                  actionModal?.type === "DELETE"
                    ? deleteOffer(actionModal?.data?._id)
                    : archiveOffer(actionModal?.data?._id)
                }
              >
                {actionModal?.loader ? (
                  <div class="spinner-border spinner-border-sm" role="status">
                    <span class="sr-only"></span>
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
              <button
                disabled={actionModal?.loader}
                className="btn btn-light me-3 mb-2 shadow-sm text-dark border px-5"
                onClick={() => setActionModal({})}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      </div>
      <div className="row rounded px-3 bg-white">
          {/* header */}
          <div className="row m-0">

            <div className="font-weight-bold t-12 text-uppercase my-3 col-md-2">
              Title
            </div>
            <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
              IMAGE
            </div>
            <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
              Date
            </div>
            <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
              Time
            </div>
            <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
              Edit
            </div>
          </div>

          <div className="m-0 p-0 pe-3 overflow-auto caterogyPageScroll">
            {notificationListLoader == false && currentItems.map((el) => (
              <div
                className="row border rounded m-0 px-2  mb-2 d-flex align-items-center"
                key={el?._id}
              >
                <div className="col-md-2 my-4">{el.title}</div>
                <div className="t-12 text-uppercase my-4 col-md-2">
                  <div className="categoryImageDiv shadow-sm  rounded p-1">
                    {/* <Link
                    className="text-dark"
                    to={`/categories/${el?._id}`}
                    style={{ textDecoration: "none" }}
                  > */}
                    <img
                      src={el?.image}
                      className="categoryImage h-100 rounded"
                    />
                    {/* </Link> */}
                  </div>
                </div>
                <div className="col-md-2 my-4">{el.push_date}</div>
                <div className="col-md-2 my-4">{el.push_time}</div>
                <div className="col-md-2 my-4">
                  <svg
                    className="bi bi-pencil border-bottom border-dark"
                    onClick={() => {
                      setModal(true);
                      setNotificationData(el);
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                  </svg>
                </div>

                <div className="col my-4 dotsize">
                  <div className="dropdown">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      data-menu-target={`drop-down-menu-${el?._id}`}
                      className="bi bi-three-dots dropdown-btn cursorPointer"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                    </svg>

                    <div
                      id={`drop-down-menu-${el?._id}`}
                      className="dropdown-content p-2 mobile-dropdown4"
                    >
                      <div
                        className="px-3 py-2 d-flex cursorPointer"
                        onClick={() =>
                          setActionModal({
                            ...actionModal,
                            show: true,
                            data: el,
                            type: "DELETE",
                          })
                        }
                      >
                        <span className="mx-2 d-flex align-items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-trash3"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                          </svg>
                        </span>

                        <span className="mx-2">
                          <div className="font-weight-bold">Delete</div>
                          <div className="">Delete this notification</div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {notificationListLoader == true &&
              <div className="text-center mt-5" >
                <button class="btn mt-5" style={{ backgroundColor: '#FFEDC2' }} type="button">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{" "}
                  Loading...
                </button>
              </div>
            }
          </div>
        </div>
        <div className="row m-2 d-flex justify-content-center align-items-center">
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            pageClassName="page-item mb-0"
            subContainerClassName="pages "
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            marginPagesDisplayed={2}
            containerClassName="pagination"
            activeClassName="active"
          />
        </div>
    </MainTemplate>
  );
}

export default NotificationList;
