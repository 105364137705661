import React, { useEffect, useState } from "react";
import MainTemplate from "../../components/MainTemplate";
import { Form, Spinner } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { HIBEE_PRODUCT_URL, SOCIETY_LIST } from "../../api_interface/apiURLs";
import { putTokenApi } from "../../api_interface/apis";
import { toast } from "react-toastify";


function SocietyDetails() {
    const navidate = useNavigate()
    const location = useLocation()
    const [societyData, setSocietyData] = useState({ societyName: "", addline1: "", addline2: "", landMark: "", city: "", state: "", pincode: "", latitude: "", longitude: "", tower: "", villa: "", _id: '', franchiseName: "", loader: false })
    const [societyDataError, setSocietyDataError] = useState({ societyName: "", addline1: "", addline2: "", landMark: "", city: "", state: "", pincode: "", latitude: "", longitude: "", tower: "", villa: "", _id: '', franchiseName: "", loader: 'false' })

    const handleChange = (ele) => {
        setSocietyData({ ...societyData, [ele.target.name]: ele.target.value })
        setSocietyDataError({ ...societyDataError, [ele.target.name]: null })
    }
    const handleValidation = () => {
        const { societyName, addline1, addline2, landMark, city, state, pincode, franchiseName } = societyData
        let newErrors = {}
        if (!societyName) newErrors.societyName = 'please enter society name'
        if (!addline1) newErrors.addline1 = 'please enter address line1'
        if (!addline2) newErrors.addline2 = 'please enter address line2'
        if (!landMark) newErrors.landMark = 'please enter land mark'
        if (!city) newErrors.city = 'please enter city'
        if (!state) newErrors.state = 'please enter state'
        if (!pincode) newErrors.pincode = 'please enter pincode'
        else if (pincode.length == 6) newErrors.pincode = 'pincode  should be 6 digits'
        return newErrors
    }

    const handleUpdate = async (ele) => {
        ele.preventDefault()
        const handleValidationObject = handleValidation()
        if (Object.keys(handleValidationObject).length > 0) {
            setSocietyDataError(handleValidationObject)
        } else {
            setSocietyData({ societyData, loader: true })
            const payload =
            {
                societyName: societyData?.societyName,
                addressLine1: societyData?.addline1,
                addressLine2: societyData?.addline2,
                city: societyData?.city,
                state: societyData?.state,
                pinCode: societyData?.pincode,
                // active: true,
                lat: societyData.latitude,
                long: societyData.longitude,
                landmark: societyData.landMark,
                _id: societyData?._id
                // towers: [
                //   {
                //     towerName: "Tower Alpha", "floors": [
                //       { "floorName": "1", "active": true },
                //       { "floorName": "2", "active": true },
                //       { "floorName": "3", "active": true }
                //     ]
                //   },
                //   {
                //     towerName: "Tower Beta", "floors": [
                //       { floorName: "1", "active": true },
                //       { floorName: "2", "active": true },
                //       { floorName: "3", "active": true }
                //     ]
                //   }
                // ],
                // villas: [{ villaName: "Villa A" }, { villaName: "Villa B" }, { villaName: "Villa C" }]
            }

            try {
                setSocietyData({ ...societyData, loader: true })
                const responce = await putTokenApi(HIBEE_PRODUCT_URL + SOCIETY_LIST, payload)
                if (responce.status == 200) {
                    toast.success(responce?.data?.message, { position: "top-center", autoClose: 2000 });
                    // handleCleareState()
                    navidate('/society-listing')
                    setSocietyData({ societyData, loader: false })

                }
            } catch (error) {
                const message = error?.response?.data?.message || "Something went wrong";
                toast.error(message, { position: "top-center", autoClose: 2000 });
                // handleCleareState()
                navidate('/society-listing')
                setSocietyData({ societyData, loader: false })
            }

        }
    }



    const handleNavidate = () => {
        navidate('/society-listing', { state: { show: true, one: 'on' } })
    }

    useEffect(() => {
        if (location?.state) {
            setSocietyData({
                ...societyData,
                societyName: location.state?.societyName,
                addline1: location?.state?.addressLine1,
                addline2: location?.state?.addressLine2,
                landMark: location?.state?.state,
                city: location?.state?.city,
                state: location?.state?.state,
                pincode: location?.state?.pinCode,
                latitude: location?.state?.lat,
                longitude: location?.state?.long,
                landMark: location.state?.landmark,
                tower: location?.state?.towers,
                villa: location?.state?.villas,
                _id: location?.state?._id
            })
        }
    }, [])


    return (
        <MainTemplate
            categoryName="Edit Society"
            categoryList={["Society", "Edit Society"]}
        >
            <div className="bg-white px-5 py-4 ">
                <div className="row " style={{ marginRight: "200px" }}>
                    <div className="form-group my-2 ">
                        <label className="">
                            Society Name<span className="text-danger">*</span>
                        </label>
                        <input
                            name="societyName"
                            type="text"
                            autoComplete="off"
                            className={`placeholder-light form-control my-2 py-2 px-3 ${societyDataError.societyName ? 'border-danger' : ""}`}
                            placeholder="Enter category name here..."
                            onChange={handleChange}
                            value={societyData.societyName}
                        />
                        <span>{societyDataError.societyName}</span>
                    </div>
                    <div className="form-group my-2">
                        <label className="">
                            Society Address<span className="text-danger">*</span>
                        </label>
                        <input
                            name="addline1"
                            onChange={handleChange}
                            value={societyData.addline1}
                            type="text"
                            autoComplete="off"
                            className={`placeholder-light form-control my-2 py-2 px-3 ${societyDataError.addline1 ? 'border-danger' : ""}`}
                            placeholder="Address Line 1"
                        />
                        <span className="text-danger">{societyDataError.addline1}</span>

                        <input
                            name="addline2"
                            onChange={handleChange}
                            value={societyData.addline2}
                            type="text"
                            autoComplete="off"
                            className={`placeholder-light form-control my-2 py-2 px-3  ${societyDataError.addline2 ? 'border-danger' : ""}`}
                            placeholder="Address Line 2"
                        />
                        <span className="text-danger">{societyDataError.addline2}</span>
                        <div className="d-flex">
                            <div className="col-6" >
                                <input
                                    name="landMark"
                                    onChange={handleChange}
                                    value={societyData.landMark}
                                    type="text"
                                    autoComplete="off"
                                    className={`placeholder-light form-control my-2 py-2 px-3  ${societyDataError.landMark ? 'border-danger' : ""}`}
                                    placeholder="Landmark / Locality"
                                />
                                <span className="text-danger">{societyDataError.landMark}</span>
                            </div>
                            <div className="col-6" style={{ paddingRight: '10px', marginLeft: '10px' }}>
                                <input
                                    name="city"
                                    onChange={handleChange}
                                    value={societyData.city}
                                    type="text"
                                    autoComplete="off"
                                    className={`placeholder-light form-control my-2 py-2 px-3 ${societyDataError.city ? 'border-danger' : ""}`}
                                    placeholder="City"
                                />
                                <span className="text-danger">{societyDataError.city}</span>
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="col-6">
                                <input
                                    name="state"
                                    onChange={handleChange}
                                    value={societyData.state}
                                    type="text"
                                    autoComplete="off"
                                    className={`placeholder-light form-control  my-2 py-2 px-3 ${societyDataError.state ? 'border-danger' : ""}`}
                                    placeholder="State"
                                />
                                <span className="text-danger">{societyDataError.state}</span>
                            </div>
                            <div className="col-6" style={{ paddingRight: '10px', marginLeft: '10px' }}>
                                <input
                                    name="pincode"
                                    onChange={handleChange}
                                    value={societyData.pincode}
                                    autoComplete="off"
                                    type="number"
                                    onWheelCapture={(e)=>e.target.blur()}

                                    className={`placeholder-light form-control my-2 py-2 px-3 remove-spinner ${societyDataError.pincode ? 'border-danger' : ""}`}
                                    placeholder="Pincode"
                                />
                                <span className="text-danger">{societyDataError.pincode}</span>
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="col-6" >
                                <label className="">Latitude</label>
                                <input
                                    name="latitude"
                                    onChange={handleChange}
                                    value={societyData.latitude}
                                    type="text"
                                    autoComplete="off"
                                    className={`placeholder-light form-control my-2 py-2 px-3 `}
                                    placeholder="Latitude"
                                />
                            </div>
                            <div className="col-6" style={{ paddingRight: '10px', marginLeft: '10px' }} >
                                <label className="">Longitude</label>
                                <input
                                    name="longitude"
                                    onChange={handleChange}
                                    value={societyData.longitude}
                                    type="text"
                                    autoComplete="off"
                                    className={`placeholder-light form-control my-2 py-2 px-3`}
                                    placeholder="Longitude"
                                />
                            </div>
                        </div>
                    </div>

                    {/* <div className="col mb-3">
                        <Form.Label>Franchise</Form.Label>
                        <div className="d-flex gap-5 justify-content-between align-items-baseline">
                            <Form.Select
                                className="Textbar"
                                controlId="Product category"
                                type="text"
                                placeholder="Select Franchise"
                                name="accountType"
                                autoComplete="off"
                            >
                                <option selected disabled>
                                    Select Franchise Name
                                </option>
                            </Form.Select>
                            <div className="">
                                <Link to="/society-listing" state={{ editData: '', show: true }} >
                                <button
                                    type="button"
                                    className="t-12 w-100 btn btn-outline-dark"
                                    onClick={handleNavidate}
                                >
                                    Edit Society Details
                                </button>
                                </Link>
                            </div>
                        </div>
                    </div> */}

                    <div className="col-3">
                        <Link
                            // to="/society-internal"
                            type="button" className="t-12 w-100 btn btn-outline-dark"
                            onClick={handleUpdate}
                        >
                            Save{" "}
                            {
                                societyData?.loader
                                    ?
                                    <Spinner
                                        animation="border"
                                        variant="light"
                                        size="sm"
                                    />
                                    :
                                    ''
                            }
                        </Link>
                    </div>
                    <div className="d-flex gap-4 justify-content-between align-items-center mt-5">
                        <div className="d-flex gap-4">
                            <div>
                                <label className="">Total Tower Count</label>
                                <p className={`placeholder-light form-control my-2 py-2 px-3`}>
                                    {societyData?.tower?.length ? societyData?.tower?.length : 0}
                                </p>
                            </div>
                            <div>
                                <label className="">Total Villa Count</label>
                                <p className={`placeholder-light form-control my-2 py-2 px-3`}>
                                    {societyData?.villa?.length ? societyData?.villa?.length : 0}
                                </p>
                            </div>
                        </div>
                        <div className="">
                            <Link to="/society-internal" state={societyData} type="button" className="t-12 w-100 btn btn-outline-dark">
                                Edit Tower/ Villa
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </MainTemplate>
    );
}

export default SocietyDetails;
