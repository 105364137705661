import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState, useMemo } from "react";
import "../FranchiseListing/FranchiseListing.css";
import MainTemplate from "../../components/MainTemplate";
import { getApi, deleteTokenApi, putTokenApi, getTokenApi } from "../../api_interface/apis";
import { CREATE_FRANCHISE_API_PATH, FRANCHISE_LIST_API_PATH, HIBEE_PRODUCT_URL, SOCIETY_LIST, UPDATE_FRANCHISE_API_PATH } from "../../api_interface/apiURLs";
import ReactPaginate from "react-paginate";
import Modal from "react-modal";
import { customActionModalStyles, customModalStyles, debounce } from "../../utils/helperFunctin";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Row, Spinner } from "react-bootstrap";
import placeholder from "../../assets/images/placeholder.png";
import { toast } from "react-toastify";
import imagenotavailable from '../../assets/images/imagenotavailable.png'


function FranchiseListing() {
  const navigate = useNavigate()
  const [itemOffset, setItemOffset] = useState(0);
  const [frnachiseList, setFranchiseList] = useState([]);
  const [actionModal, setActionModal] = useState({
    loader: false,
    show: false,
    type: "",
    data: {},
  });
  const [aadharImage, setAadharImage] = useState("");
  const [panImage, setpanImage] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [modal, setModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [sort, setSort] = useState("")
  const [query, setQuery] = useState("")
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [franchiseDetailes, setFranchiseDetailes] = useState([]);
  const [franchiseData, setFranchiseData] = useState({
    name: "",
    number: "",
    email: "",
    password: "",
    society: "",
    address1: "",
    address2: "",
    state: "",
    city: "",
    pincode: "",
    aadhar: "",
    aadhar_number: "",
    pan: "",
    pan_number: "",
    cod_status: false,
    minimum_cod_amount: "",
    bankName: "",
    accountHolderName: "",
    accountNumber: "",
    ifscCode: "",
    branchName: "",
    accountType: "",
    referred_by: "",
    profile: "",
    _id: "",
  });

  const [franchiseDataError, setFranchiseDataError] = useState({
    name: "",
    number: "",
    email: "",
    password: "",
    society: "",
    address1: "",
    address2: "",
    state: "",
    city: "",
    pincode: "",
    aadhar: "",
    aadhar_number: "",
    pan: "",
    pan_number: "",
    cod_status: false,
    minimum_cod_amount: "",
    bankName: "",
    accountHolderName: "",
    accountNumber: "",
    ifscCode: "",
    branchName: "",
    accountType: "",
    referred_by: "",
    profile: "",
    _id: "",
  });
  const [aprove, setAprove] = useState({
    status: "",
    index: ""
  })
  const [updateType, setUpdateType] = useState('true')

  const [banksList, setBanksList] = useState({
    data: [
      { id: '1', bankName: 'SBI' },
      { id: '2', bankName: 'INDIAN' },
      { id: '3', bankName: 'UNION' },
      { id: '4', bankName: 'ICICI' }
    ],
    error: false
  })

  const [accountType, setAccountType] = useState({
    data: [
      { id: '1', accountType: 'Select account type', value: '' },
      { id: '2', accountType: 'Saving', value: '' },
      { id: '3', accountType: 'Current' },
    ],
    error: false
  })
  const navigagation = useNavigate();
  const handleReeUploadFile = (ele) => {
    document.getElementById(ele).click();
  };

  /** haandle change **/
  const handleFranchisechange = (event) => {
    if (
      event.target.name != "aadhar" &&
      event.target.name != "pan" &&
      event.target.name != "profile"
    ) {
      setFranchiseData({
        ...franchiseData,
        [event.target.name]: event.target.value,
      });
      setFranchiseDataError({
        ...franchiseDataError,
        [event.target.name]: null,
      });
    }

    if (event.target.name == "aadhar") {
      var url = URL.createObjectURL(event.target.files[0]);
      setAadharImage(url);
      setFranchiseData({
        ...franchiseData,
        [event.target.name]: event.target.files[0],
      });
      setFranchiseDataError({
        ...franchiseDataError,
        [event.target.name]: null,
      });
    }
    if (event.target.name == "pan") {
      var url = URL.createObjectURL(event.target.files[0]);
      setpanImage(url);
      setFranchiseData({
        ...franchiseData,
        [event.target.name]: event.target.files[0],
      });
      setFranchiseDataError({
        ...franchiseDataError,
        [event.target.name]: null,
      });
    }
    if (event.target.name == "profile") {
      var url = URL.createObjectURL(event.target.files[0]);
      setProfileImage(url);
      setFranchiseData({
        ...franchiseData,
        [event.target.name]: event.target.files[0],
      });
      setFranchiseDataError({
        ...franchiseDataError,
        [event.target.name]: null,
      });
    }
  };

  const endOffset = itemOffset + itemsPerPage;
  const pageCount = Math.ceil(frnachiseList.length / itemsPerPage);
  const currentFranchiseList = frnachiseList.slice(itemOffset, endOffset);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % frnachiseList.length;
    setItemOffset(newOffset);
  };

  /** Franchise Listing(GET API) **/
  const handleGetFranchiseLists = async (search) => {
    setLoader(true);
    const one = localStorage.getItem('franchiseStatus')
    const two = localStorage.getItem('franchiseSort')
    await getTokenApi(HIBEE_PRODUCT_URL + FRANCHISE_LIST_API_PATH + `?sort=${two ? two : 'name'}&search=${search || query || ""}&${one == 'true' ? `status=${one}` : `status=${one}`}`)
      .then((ele, ind) => {
        if (ele.status == 200) {
          const data = ele.data.data
          const revData = data.reverse()
          setFranchiseList(revData);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateSort = e => {
    localStorage.setItem('franchiseSort', e.target.value)
    setSort(e.target.value)
  }

  const getSearchedData = useMemo(() => debounce(async (search) => {
    handleGetFranchiseLists(search)
  }, 500), [])

  useEffect(() => {
    handleGetFranchiseLists()
  }, [sort])

  useEffect(() => {
    handleGetFranchiseLists();
  }, [updateType]);


  useEffect(() => {
    getSearchedData(query)
  }, [query])

  const handleEditClick = (ele) => {
    setFranchiseData({
      ...franchiseData,
      name: ele?.owner_name,
      number: ele?.phone_number,
      email: ele?.email,
      password: "",
      society: ele?.society,
      address1: ele?.address?.street,
      address2: "",
      state: ele?.address?.state,
      city: ele?.address?.city,
      pincode: ele?.address?.zip,
      aadhar: ele?.adhar_img,
      aadhar_number: ele?.adhar_number,
      pan: ele?.pan_img,
      pan_number: ele?.pan_number,
      cod_status: ele?.cod_status,
      minimum_cod_amount: ele?.maximum_COD_amount,
      bankName: ele?.bank_name,
      accountHolderName: ele?.account_holder_name,
      accountNumber: ele?.account_number,
      ifscCode: ele?.ifsc_code,
      branchName: ele?.bank_branch,
      accountType: ele?.account_type,
      referred_by: ele?.referred_by,
      profile: ele?.profile_img,
      _id: ele?._id,
    });
    setModal(true);
  };

  /** handle Submit **/
  const handleSubmitFranchise = async (ele) => {
    ele.preventDefault();

    setLoader(true);
    const {
      name,
      number,
      email,
      password,
      society,
      address1,
      address2,
      state,
      city,
      pincode,
      aadhar,
      aadhar_number,
      pan,
      pan_number,
      cod_status,
      minimum_cod_amount,
      bankName,
      accountHolderName,
      accountNumber,
      ifscCode,
      branchName,
      accountType,
      referred_by,
      profile,
      _id,
    } = franchiseData;
    const formData = new FormData();
    formData.append("owner_name", name);
    formData.append("phone_number", number);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("society", society);
    formData.append(
      "address",
      JSON.stringify({
        street: address1,
        city: city,
        zip: pincode,
        state: state,
      })
    );
    formData.append("adhar_img", aadhar);
    formData.append("adhar_number", aadhar_number);
    formData.append("pan_img", pan);
    formData.append("pan_number", pan_number);
    formData.append("profile_img", profile);
    formData.append("cod_status", cod_status);
    formData.append("maximum_COD_amount", minimum_cod_amount);
    formData.append("bank_name", bankName);
    formData.append("account_holder_name", accountHolderName);
    formData.append("account_number", accountNumber);
    formData.append("ifsc_code", ifscCode);
    formData.append("bank_branch", branchName);
    formData.append("account_type", accountType);
    formData.append("referred_by", referred_by);
    formData.append("_id", _id);
    const res = await putTokenApi(HIBEE_PRODUCT_URL + UPDATE_FRANCHISE_API_PATH, formData, { "Content-Type": "multipart/form-data" });
    if (res.data.error == false) {
      toast.success(res.data.message, {
        position: "top-center",
        autoClose: 2000,
      });
      setLoader(false);
      setModal(false);
      handleGetFranchiseLists();
      setTimeout(() => {
        navigagation("/franchise-listing");
      }, 1000);
    } else {
      toast.error(res.data.message, {
        position: "top-center",
        autoClose: 2000,
      });
      setLoader(false);
      setModal(false);
      handleGetFranchiseLists();
      setTimeout(() => {
        navigagation("/franchise-listing");
      }, 1000);
    }
  };

  const handleCloseModal = () => {
    setModal(false);
    setFranchiseData({});
    setLoader(false);
    setAadharImage("");
    setpanImage("");
    setProfileImage("");
  };


  const handleDeleteFranchise = async (id) => {
    const franchiseId = id
    const res = await deleteTokenApi(HIBEE_PRODUCT_URL + UPDATE_FRANCHISE_API_PATH + `/ ${franchiseId} `);
    if (res.status == 200) {
      handleGetFranchiseLists();
      setActionModal({})
    } else {
      handleGetFranchiseLists();
      setActionModal({})
    }
  };


  const approveAdmin = async (ele, id) => {
    setAprove({ ...aprove, status: ele, index: id })
    // Path Params
    const franchiseId = id
    const status = ele
    localStorage.setItem('franchiseStatus', status)
    try {
      const res = await putTokenApi(HIBEE_PRODUCT_URL + FRANCHISE_LIST_API_PATH + `/${franchiseId}/${status}`)
      if (res.status == 200) {
        const newStatus = localStorage.getItem('franchiseStatus')
        localStorage.setItem('franchiseStatus', newStatus == 'true' ? false : true)
        if (res?.data?.status == 'approved') {
          toast.success(res?.data?.message, {
            position: "top-center",
            autoClose: 2000,
          });
        }
        if (res?.data?.status == 'disapproved') {
          toast.error(res?.data?.message, {
            position: "top-center",
            autoClose: 2000,
          });
        }
        handleGetFranchiseLists()
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "top-center",
        autoClose: 2000,
      })
      handleGetFranchiseLists()
      const newStatus = localStorage.getItem('franchiseStatus')
      localStorage.setItem('franchiseStatus', newStatus == 'true' ? false : true)
    }
  }

  const handleEditFranchise = async (id) => {
    if (id) {
      await getTokenApi(HIBEE_PRODUCT_URL + FRANCHISE_LIST_API_PATH + `/?frenchiseId=${id}`)
        .then((ele, ind) => {
          if (ele.status == 200) {
            navigate('/franchise', { state: ele?.data?.data });
          }
        })
        .catch((error) => {
          if (error.response) {
            toast.error(error.response?.data?.message, {
              position: "top-center",
              autoClose: 2000,
            });
          } else {
            toast.error('Somthing went wrong', {
              position: "top-center",
              autoClose: 2000,
            });
          }
        });
    }
  }

  /** filter **/
  useEffect(() => {
    localStorage.setItem('franchiseStatus', true)
  }, [])

  useEffect(() => {
    const data = localStorage.getItem('franchiseStatus')
    setUpdateType(data)
  }, [updateType])



  return (
    <MainTemplate
      categoryName="Franchise List"
      categoryList={["Franchise", "Franchise List"]}
    >
      <>
        <div className="row mb-4 rounded p-2 bg-white">
          <div className="row p-0 ">
            <div className="col-sm-2 my-3 col-md-2">
              <select className="t-12 form-select" aria-label="Default select example" onChange={updateSort}>
                <option value="" selected>Sort by</option>
                <option value="name">Alphabatically</option>
                <option value="dateold">Date: Acsending</option>
                <option value="datenew">Date: Descending</option>
              </select>
            </div>
            <div className="col-sm-6 my-3 col-md-2">
              <select className="t-12 form-select" aria-label="Default select example" onChange={e => setItemsPerPage(e.target.value)}>
                <option value="10" selected>10 per page</option>
                <option value="20">20 per page</option>
                <option value="50">50 per page</option>
                <option value="100">100 per page</option>
              </select>
            </div>
            <div className="col-sm-6 my-3 col-md-2">
              <select className="t-12 form-select" aria-label="Default select example"
                onChange={e => {
                  setUpdateType(e.target.value)
                  localStorage.setItem('franchiseStatus', e.target.value)
                }}
              >
                {/* <option value="" selected>type</option> */}
                <option selected value={true}>Active</option>
                <option value={false}>Inactive</option>
              </select>
            </div>
            <div className="my-3 col-md-3">
              <input type="text" name="query" className="t-12 form-control" autoComplete="off" placeholder="Search" value={query} onChange={e => setQuery(e.target.value)} />
            </div>
            <div className="d-none d-md-block col-md-1"></div>
            <div className=" my-3 col-md-2 text-end">
              <Link to="/franchise">
                <button type="" className=" btn btn-outline-dark afrnch">
                  Add Franchise
                </button>
              </Link>
            </div>
          </div>
        </div>

        {/* table */}
        <div className="row flex-column align-items-center">
          <div className="row rounded text-center bg-white">
            {/* header */}
            <div className="row m-0 ">
              <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-1">
                ID
              </div>
              <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
                Photo
              </div>
              <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
                FRANCHISE OWNER
              </div>
              <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
                PHONE NUMBER
              </div>
              <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
                DATE
              </div>
              <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-1">
                Edit
              </div>
              <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
                Active{" "}/{" "}Inactive
              </div>
            </div>

            {/* body */}
            <div className="justify-content-center align-items-center overflow-auto caterogyPageScroll">
              {loader == false &&
                currentFranchiseList?.map((el) => {
                  console.log(el, 'elelelellelel');
                  return <div
                    className="row border rounded m-0 mb-2 d-flex align-items-center"
                    key={el?._id}>
                    <div className="t-12 text-uppercase my-3 col-md-1">{el?.unique_code}</div>
                    <div className="t-12 text-uppercase my-3 col-md-2">
                      <div className="categoryImageDiv shadow-sm  rounded p-1">
                        <Link
                          className="text-dark"
                          to={`/franchise-details/${el?._id}`}
                          style={{ textDecoration: "none" }}
                        >
                          <img
                            src={el?.profile_img ? el?.profile_img : imagenotavailable}
                            className="categoryImage h-100 rounded"
                          />
                        </Link>
                      </div>
                    </div>
                    <div className="t-12 text-uppercase my-3 col-md-2">
                      <Link
                        // className="text-dark"
                        to={`/franchise-details/${el?._id}`}
                        style={{ textDecoration: "none" }}
                      >
                        {el?.owner_name}
                      </Link>
                    </div>
                    <div className="t-12 text-uppercase my-3 col-md-2">
                      {el?.phone_number}
                    </div>
                    <div className="t-12 text-uppercase my-3 col-md-2">
                      {el.createdAt.slice(0, 10)}
                    </div>
                    <div className="t-12 text-uppercase my-3 col-md-1">
                      <Link
                        // to='/franchise'
                        // state={{ data: el }}
                        className='text-decoration-none text-dark'
                      >
                        <svg
                          className="bi bi-pencil border-bottom border-dark"
                          onClick={() => {
                            // handleEditClick(el);
                            handleEditFranchise(el?._id)
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                        </svg>
                      </Link>
                    </div>
                    <div className="t-12 text-uppercase my-3 col-md-2">
                      <div className="m-auto" >
                        <label className="switch">
                          <input className="switch-input" type="checkbox"
                            // checked={[el.active].includes(aprove.status) && aprove.index == el._id ? true : false}
                            checked={el.activated}
                            // onChange={e => e.target.checked ? approveAdmin(e.target.checked, el?._id) : approveAdmin(e.target.checked, el?._id)}
                            onChange={e => e.target.checked ? approveAdmin(e.target.checked, el?._id) : approveAdmin(e.target.checked, el?._id)}
                          />
                          <span className="switch-label"></span>
                          <span className="switch-handle"></span>
                        </label>
                      </div>
                    </div>

                    {/* <div className="t-12 text-uppercase my-3 col-md-1">
                  <div class="dropdown">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      data-menu-target={`drop-down-menu-${el?._id}`}
                      className="bi bi-three-dots dropdown-btn cursorPointer"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                    </svg>
                    <div id={`drop-down-menu-${el?._id}`} className="dropdown-content p-2 mobile-dropdown">
                      <div className="px-3 py-2 d-flex cursorPointer" onClick={() => setActionModal({ ...actionModal, show: true, data: el, type: "DELETE" })}>
                        <span className="mx-2 d-flex align-items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-trash3"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                          </svg>
                        </span>
                        <span className="mx-2">
                          <div className="font-weight-bold">Delete</div>
                          <div className="">Delete this Franchise</div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div> */}
                  </div>
                })}
              {loader == true && (
                <div className="text-center mt-5">
                  <button
                    class="btn mt-5"
                    style={{ backgroundColor: "#FFEDC2" }}
                    type="button"
                  >
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>{" "}
                    Loading...
                  </button>
                </div>
              )}
            </div>
          </div>


        </div>
        <div className="row m-2 d-flex justify-content-center align-items-center">
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            pageClassName="page-item mb-0"
            subContainerClassName="pages "
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            marginPagesDisplayed={2}
            containerClassName="pagination"
            activeClassName="active"
          />
        </div>

        {/* Franchise Delete Modal */}
        <Modal
          isOpen={actionModal?.show}
          onRequestClose={() => setActionModal({})}
          style={customActionModalStyles}
          contentLabel="Action Modal"
          ariaHideApp={false}
        >
          <div className="px-3 py-3">
            <div className="row">
              <span className="d-flex justify-content-end cursorPointer">
                <svg
                  onClick={() => setActionModal({})}
                  className="d-inline"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                </svg>
              </span>
            </div>
            <div className="row">
              <h4 className="text-danger d-flex justify-content-center">
                Do you want to {actionModal?.type} franchise?
              </h4>
            </div>
            <div className="row my-5">
              <h5 className="d-flex justify-content-center">
                {actionModal?.data?.name}
              </h5>
            </div>
            <div className="form-group my-3 d-flex justify-content-center">
              <button
                disabled={actionModal?.loader}
                className="btn btn-dark me-3 mb-2 text-warning px-5"
                onClick={() =>
                  actionModal?.type === "DELETE"
                    ? handleDeleteFranchise(actionModal?.data?._id) : ''
                  // : archiveOffer(actionModal?.data?._id)
                }
              >
                {actionModal?.loader ? (
                  <div class="spinner-border spinner-border-sm" role="status">
                    <span class="sr-only"></span>
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
              <button
                disabled={actionModal?.loader}
                className="btn btn-light me-3 mb-2 shadow-sm text-dark border px-5"
                onClick={() => setActionModal({})}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>

        {/* Franchise Update Modal  */}
        <Modal
          isOpen={modal}
          onRequestClose={() => {
            setModal(false);
            setFranchiseData({});
            setLoader(false);
          }}
          style={customModalStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <div className="px-3 py-3">
            <div className="row">
              <span className="d-flex justify-content-end cursorPointer">
                <svg
                  onClick={handleCloseModal}
                  className="d-inline"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                </svg>
              </span>
            </div>
            <div className="container containerbg overflow-auto rounded bg-white row w-100 ">
              <h5 className="text-center">Update Franchise Details</h5>
              <div className="row col-md-12 rowhight"> </div>
              <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-5">
                  <Form onSubmit={handleSubmitFranchise}>
                    <Row>
                      <Form.Group className="mb-3" controlId="Owner Name">
                        <Form.Label>Owner Name</Form.Label>
                        <Form.Control
                          className="Textbar"
                          type="text"
                          placeholder="Type your name  "
                          name="name"
                          value={franchiseData.name}
                          isInvalid={franchiseDataError?.name}
                          autoComplete="off"
                          onChange={handleFranchisechange}
                        />
                        <span className="text-danger">
                          {franchiseDataError.name}
                        </span>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="Phone Number">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                          className="Textbar"
                          type="number"
                          placeholder="type your Phone number  "
                          name="number"
                          onWheelCapture={(e) => e.target.blur()}

                          value={franchiseData.number}
                          onChange={handleFranchisechange}
                          isInvalid={franchiseDataError?.number}
                          autoComplete="off"
                          maxLength={10}
                          onInput={(e) => {
                            if (e.target.value.length > e.target.maxLength) {
                              e.target.value = e.target.value.slice(
                                0,
                                e.target.maxLength
                              );
                            }
                          }}
                        />
                        <span className="text-danger">
                          {franchiseDataError.number}
                        </span>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="Email ID">
                        <Form.Label>Email ID</Form.Label>
                        <Form.Control
                          className="Textbar"
                          type=""
                          placeholder="type your Email Id  "
                          name="email"
                          value={franchiseData.email}
                          onChange={handleFranchisechange}
                          isInvalid={franchiseDataError?.email}
                          autoComplete="off"
                        />
                        <span className="text-danger">
                          {franchiseDataError.email}
                        </span>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="Society/Community"
                      >
                        <Form.Label>Society/Community</Form.Label>
                        <Form.Control
                          className="Textbar"
                          type="text"
                          placeholder="type your Society/Community  "
                          name="society"
                          value={franchiseData.society}
                          onChange={handleFranchisechange}
                          isInvalid={franchiseDataError?.society}
                          autoComplete="off"
                        />
                        <span className="text-danger">
                          {franchiseDataError.society}
                        </span>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="Address 1">
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          className="Textbar"
                          type="text"
                          placeholder="Type your Address"
                          name="address1"
                          value={franchiseData.address1}
                          isInvalid={franchiseDataError?.address1}
                          autoComplete="off"
                          onChange={handleFranchisechange}
                        />
                        <span className="text-danger">
                          {franchiseDataError.address1}
                        </span>
                      </Form.Group>
                    </Row>
                    <Row className="">
                      {/* <div className="col-md-3"> */}
                      <Form.Group className="mb-3" controlId="State">
                        <Form.Label>State</Form.Label>
                        <Form.Control
                          className="Textbar"
                          type="text"
                          placeholder="type your State  "
                          name="state"
                          value={franchiseData.state}
                          onChange={handleFranchisechange}
                          isInvalid={franchiseDataError?.state}
                          autoComplete="off"
                        />
                        <span className="text-danger">
                          {franchiseDataError.state}
                        </span>
                      </Form.Group>
                      {/* </div> */}
                      {/* <div className="col-md-3" style={{marginLeft:'150px'}}> */}
                      <Form.Group className="mb-3" controlId="City">
                        <Form.Label className="">City</Form.Label>
                        <Form.Control
                          className="Textbar"
                          type="text"
                          placeholder="type your City  "
                          name="city"
                          value={franchiseData.city}
                          onChange={handleFranchisechange}
                          isInvalid={franchiseDataError?.city}
                          autoComplete="off"
                        />
                        <span className="text-danger">
                          {franchiseDataError.city}
                        </span>
                      </Form.Group>
                      {/* </div> */}
                    </Row>

                    <Row>
                      <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="Pin code">
                          <Form.Label>Pin code</Form.Label>
                          <Form.Control
                            className="smtext"
                            type="number"
                            placeholder="type your Pin code  "
                            name="pincode"
                            onWheelCapture={(e) => e.target.blur()}

                            value={franchiseData.pincode}
                            onChange={handleFranchisechange}
                            isInvalid={franchiseDataError?.pincode}
                            autoComplete="off"
                            maxLength={6}
                            onInput={(e) => {
                              if (e.target.value.length > e.target.maxLength) {
                                e.target.value = e.target.value.slice(
                                  0,
                                  e.target.maxLength
                                );
                              }
                            }}
                          />
                          <span className="text-danger"> {franchiseDataError.pincode}</span>
                        </Form.Group>
                      </div>
                      <div></div>
                    </Row>

                    {franchiseData.aadhar == "" ? (
                      <Row>
                        <Form.Group className="mb-3" controlId="Aadhar card">
                          <Form.Label>Aadhar card</Form.Label>
                          <div className="form-group py-2 position-relative mb-3 g-0">
                            <input
                              type="file"
                              name="aadhar"
                              value={franchiseData.aadhar}
                              onChange={handleFranchisechange}
                              className="opacity0 position-absolute w-75 placeholder-light form-control py-2 imgheight px-3"
                            />
                            <div className="adhar-box text-center">
                              <div className="mt-5">
                                <img src={placeholder} />{" "}
                                <span style={{ color: "#A3A3A3" }}>
                                  Upload your Aadhar card  ...
                                </span>
                              </div>
                            </div>
                          </div>
                          <span className="text-danger">
                            {franchiseDataError.aadhar}
                          </span>
                        </Form.Group>
                      </Row>
                    ) : (
                      <Row>
                        <Form.Label>Aadhar Card</Form.Label>
                        <div
                          className="adhar-box mb-3 g-0"
                          style={{ marginLeft: "12px" }}
                          onClick={() => handleReeUploadFile("aadhar")}
                        >
                          <img
                            src={
                              aadharImage == ""
                                ? franchiseData.aadhar
                                : aadharImage
                            }
                            width="100%"
                            height="100%"
                          />
                        </div>
                        <input
                          type="file"
                          name="aadhar"
                          onChange={handleFranchisechange}
                          className="opacity0"
                          id="aadhar"
                        />
                      </Row>
                    )}
                    <Form.Group className="mb-3" controlId="Address 1">
                      <Form.Label>Addhar Card Number</Form.Label>
                      <Form.Control
                        className="Textbar"
                        type="number"
                        onWheelCapture={(e) => e.target.blur()}

                        placeholder="Type your Addar Number"
                        name="aadhar_number"
                        // isInvalid={franchiseDataError?.aadhar_number}
                        value={franchiseData.aadhar_number}
                        autoComplete="off"
                        onChange={handleFranchisechange}
                        maxLength={12}
                        onInput={(e) => {
                          if (e.target.value.length > e.target.maxLength) {
                            e.target.value = e.target.value.slice(
                              0,
                              e.target.maxLength
                            );
                          }
                        }}
                      />
                      {/* <span className="text-danger"> {franchiseDataError.aadhar_number}</span> */}
                    </Form.Group>
                    {franchiseData.pan == "" ? (
                      <Row>
                        <Form.Group className="mb-3" controlId="Pan Card">
                          <Form.Label>Pan Card</Form.Label>
                          <div className="form-group py-2 position-relative mb-3 g-0">
                            <input
                              type="file"
                              name="pan"
                              value={franchiseData.pan}
                              onChange={handleFranchisechange}
                              className="opacity0 position-absolute w-75 placeholder-light form-control py-2 imgheight px-3"
                            />
                            <div className="adhar-box text-center">
                              <div className="mt-5">
                                <img src={placeholder} />{" "}
                                <span style={{ color: "#A3A3A3" }}>
                                  Upload your Pan card  ...
                                </span>
                              </div>
                            </div>
                          </div>
                          <span className="text-danger">
                            {franchiseDataError.pan}
                          </span>
                        </Form.Group>
                      </Row>
                    ) : (
                      <Row>
                        <Form.Label>Pan Card</Form.Label>
                        <div
                          className="adhar-box mb-3 g-0"
                          style={{ marginLeft: "12px" }}
                          onClick={() => handleReeUploadFile("pan")}
                        >
                          <img
                            src={panImage == "" ? franchiseData.pan : panImage}
                            width="100%"
                            height="100%"
                          />
                        </div>
                        <input
                          type="file"
                          name="pan"
                          onChange={handleFranchisechange}
                          className="opacity0"
                          id="pan"
                        />
                      </Row>
                    )}
                    <Form.Group className="mb-3" controlId="Address 1">
                      <Form.Label>Pan Card Number</Form.Label>
                      <Form.Control
                        className="Textbar"
                        type="number"
                        onWheelCapture={(e) => e.target.blur()}

                        placeholder="Type your PAN Number"
                        name="pan_number"
                        // isInvalid={franchiseDataError?.pan_number}
                        value={franchiseData?.pan_number}
                        autoComplete="off"
                        onChange={handleFranchisechange}
                        maxLength={10}
                        onInput={(e) => {
                          if (e.target.value.length > e.target.maxLength) {
                            e.target.value = e.target.value.slice(
                              0,
                              e.target.maxLength
                            );
                          }
                        }}
                      />
                      {/* <span className="text-danger">{franchiseDataError.pan_number}</span> */}
                    </Form.Group>
                    <Form.Label>COD</Form.Label>
                    <div className="col-4">
                      <label className="switch">
                        <input className="switch-input" type="checkbox"
                          name='cod_status'
                          checked={franchiseData?.cod_status}
                          onChange={(e) => setFranchiseData({ ...franchiseData, cod_status: franchiseData?.cod_status == false ? true : false })}
                        />
                        <span className="switch-label"></span>
                        <span className="switch-handle"></span>
                      </label>
                    </div>
                    <Form.Group className="mb-3" controlId="Address 1">
                      <Form.Label>Minimum COD Amount</Form.Label>
                      <Form.Control
                        className="Textbar"
                        type="number"
                        onWheelCapture={(e) => e.target.blur()}

                        placeholder="COD Amount"
                        name="minimum_cod_amount"
                        // isInvalid={franchiseDataError?.minimum_cod_amount}
                        value={franchiseData.minimum_cod_amount}
                        autoComplete="off"
                        onChange={handleFranchisechange}
                        maxLength={10}
                        onInput={(e) => {
                          if (e.target.value.length > e.target.maxLength) {
                            e.target.value = e.target.value.slice(
                              0,
                              e.target.maxLength
                            );
                          }
                        }}
                      />
                      {/* <span className="text-danger">   {franchiseDataError.minimum_cod_amount} </span> */}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="Address 1">
                      <Form.Label>Bank Name</Form.Label>
                      <Form.Control
                        className="Textbar"
                        type="text"
                        placeholder="Enter account holder name"
                        name="bankName"
                        // isInvalid={franchiseDataError?.bankName}
                        value={franchiseData.bankName}
                        autoComplete="off"
                        onChange={handleFranchisechange}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="Address 1">
                      <Form.Label>Account Holder Name</Form.Label>
                      <Form.Control
                        className="Textbar"
                        type="text"
                        placeholder="Enter account holder name"
                        name="accountHolderName"
                        // isInvalid={franchiseDataError?.accountHolderName}
                        value={franchiseData.accountHolderName}
                        autoComplete="off"
                        onChange={handleFranchisechange}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="Address 1">
                      <Form.Label>Account numbers</Form.Label>
                      <Form.Control
                        className="Textbar"
                        type="number"
                        onWheelCapture={(e) => e.target.blur()}

                        placeholder="Enter account number"
                        name="accountNumber"
                        // isInvalid={franchiseDataError?.accountNumber}
                        autoComplete="off"
                        onChange={handleFranchisechange}
                        value={franchiseData.accountNumber}
                        maxLength={15}
                        onInput={(e) => {
                          if (e.target.value.length > e.target.maxLength) {
                            e.target.value = e.target.value.slice(
                              0,
                              e.target.maxLength
                            );
                          }
                        }}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="Address 1">
                      <Form.Label>IFSC Code</Form.Label>
                      <Form.Control
                        className="Textbar"
                        type="text"
                        placeholder="COD Amount"
                        name="ifscCode"
                        // isInvalid={franchiseDataError?.ifscCode}
                        autoComplete="off"
                        onChange={handleFranchisechange}
                        maxLength={15}
                        value={franchiseData.ifscCode}
                        onInput={(e) => {
                          if (e.target.value.length > e.target.maxLength) {
                            e.target.value = e.target.value.slice(
                              0,
                              e.target.maxLength
                            );
                          }
                        }}
                        onWheelCapture={(e) => e.target.blur()}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="Address 1">
                      <Form.Label>Branch</Form.Label>
                      <Form.Control
                        className="Textbar"
                        type="text"
                        placeholder="Enter branch"
                        name="branchName"
                        // isInvalid={franchiseDataError?.branchName}
                        value={franchiseData.branchName}
                        autoComplete="off"
                        onChange={handleFranchisechange}
                      />
                    </Form.Group>

                    <div className="col mb-3">
                      <Form.Label>Account Type</Form.Label>
                      <Form.Select
                        // className="Textbar"
                        controlId="Product category"
                        type="text"
                        placeholder="Enter select product category"
                        name="accountType"
                        // className={`${franchiseDataError.accountType ? "border-danger" : ""} Textbar`}
                        // isInvalid={inventoryDataError?.subcategory}
                        onChange={handleFranchisechange}
                        autoComplete="off"
                      // disabled={isEditing}
                      >
                        {/* <option selected disabled>
                        Select Type
                      </option> */}
                        {
                          accountType?.data?.map((el, index) => (
                            <option
                              key={index}
                              value={franchiseData.accountType}
                            // selected={el?._id === inventoryData?.subcategory}
                            >
                              {el?.accountType}
                            </option>
                          ))
                        }
                      </Form.Select>
                    </div>
                    <Row>
                      {/* <Form.Group
                        className="mb-3 Textbar"
                        controlId="Reffered by"
                      >
                        <Form.Label>Reffered by</Form.Label>
                        <Form.Control
                          className="Textbar"
                          type="text"
                          disabled
                          placeholder="Reffered by"
                          name="referred_by"
                          value={franchiseData.referred_by}
                          onChange={handleFranchisechange}
                          isInvalid={franchiseDataError?.referred_by}
                          autoComplete="off"
                        />
                        <span className="text-danger">
                          {franchiseDataError.referred_by}
                        </span>
                      </Form.Group> */}

                      <div className="row" id="row"></div>

                      <div className="mb-3">
                        <Button
                          variant="primary"
                          type="submit"
                          size="lg"
                          id="btnRegister"
                        >
                          {loader ? (
                            <Spinner
                              animation="border"
                              variant="light"
                              size="sm"
                            />
                          ) : (
                            <p className="login-text">Update</p>
                          )}
                        </Button>
                      </div>
                    </Row>
                  </Form>
                </div>

                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-3"></div>
                    <div className="col-md-6">
                      {franchiseData.profile == "" ? (
                        <Row>
                          <Form.Group className="mb-3" controlId="Pan Card">
                            <div className="form-group py-2 position-relative mb-3">
                              <input
                                type="file"
                                name="profile"
                                value={franchiseData.profile}
                                onChange={handleFranchisechange}
                                isInvalid={franchiseDataError?.profile}
                                className="opacity0 position-absolute w-75 placeholder-light form-control py-2 imgheight px-3"
                              />
                              <div className="profile-box text-center">
                                <div style={{ marginTop: "80px" }}>
                                  <img src={placeholder} />{" "}
                                  <span style={{ color: "#A3A3A3" }}>
                                    Upload Photo
                                  </span>
                                </div>
                              </div>
                            </div>
                            <span className="text-danger">
                              {franchiseDataError.profile}
                            </span>
                          </Form.Group>
                        </Row>
                      ) : (
                        <Row>
                          <div
                            className="profile-box mb-3 g-0"
                            onClick={() => handleReeUploadFile("profile")}
                          >
                            <img
                              src={
                                profileImage == ""
                                  ? franchiseData.profile
                                  : profileImage
                              }
                              width="100%"
                              height="100%"
                            />
                          </div>
                          <input
                            type="file"
                            name="profile"
                            onChange={handleFranchisechange}
                            className="opacity0"
                            id="profile"
                          />
                        </Row>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    </MainTemplate>
  );
}

export default FranchiseListing;
