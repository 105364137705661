import {
  Form,
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";
import "../FranchiseRequestDetails/FranchiseRequestDetails.css";
import MainTemplate from "../../components/MainTemplate";

function FranchiseRequestDetails() {
  return (
    <MainTemplate
      categoryName="Franchise #648439"
      categoryList={["Admin", "Franchise", "#648439"]}
    >
      <div className="container">
        <div className="row mt-5">
          <div className="col-md-1"></div>
          <div className="col-md-2 row justify-content-center">
            <div className="md-3 mt-2">
              <div className="mb-3">
                <div className="locatft">Located at</div>
              </div>

              <div className="md-3 mb-3">
                <div className="txtfnt1">City</div>
                <div className="txtfnt2">Jalandhar</div>
              </div>

              <div className="md-3 mb-3">
                <div className="txtfnt1">State</div>
                <div className="txtfnt2">Panjab</div>
              </div>

              <div className="md-3 mb-3">
                <div className="txtfnt1">Society</div>
                <div className="txtfnt2">Prestige Ferns</div>
              </div>
            </div>
          </div>

          <div className="col-md-1 py-0 mobile-horr">
            <div className="horriLines"></div>
          </div>

          <div className="col-md-5 md-3 px-0">
            <div className=" md-3 mt-3 mb-3">
              <div className="txtfnt1">Name</div>
              <div className="txtfnt2">Shivaji Mehta</div>
            </div>

            <div className=" md-3 mb-3">
              <div className="txtfnt1">Phone Number</div>
              <div className="txtfnt2 underli">+91-987-675-6748</div>
            </div>

            <div className="md-3 mb-3 boxmes ">
              <div className="txtfnt1">Message</div>
              <div className="txtfnt2 lineh mobile-message">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                elit in tempor, enim. Mi, aliquam egestas neque turpis sed.
                Integer commodo penatibus tortor eu tortor ut lobortis. Lacus
                sagittis, amet, ut justo, vehicula.
                <p />
                Sit ut eu semper facilisis tellus felis sed id quis. In erat
                non, fusce in mi. Cras sem sem nulla donec pharetra ultrices ut.
                Malesuada sem commodo.
              </div>
            </div>

            <div className="md-3 heightt"></div>

            <div className="md-3 hrihts">
              <button className="btn btn-danger btnhw btnsize">Delete</button>
              <button
                className="btn btn-success btnhw btnsize btncls" id="btnacc"
              >
                Accept
              </button>
            </div>
          </div>
        </div>
      </div>
     </MainTemplate>
  );
}

export default FranchiseRequestDetails;
