import React, { useEffect, useState } from "react";
import "../ForgetPassword/ForgetPassword.css";
import {
  Row,
  Col,
  Form,
  FormControl,
  Container,
  Spinner,
} from "react-bootstrap";
import { putApi } from "../../api_interface/apis";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { HIBEE_AUTH_URL, REACT_SEND_OTP_API_PATH } from "../../api_interface/apiURLs";

function ForgetPassword() {
  const navigate = useNavigate();

  const type = "changepassword";

  const [phone, setPhone] = useState({
    number: "",
  });
  const [error, setError] = useState({
    number: "",
  });
  const [loader, setLoader] = useState(false);

  const handlechange = (e) => {
    if (e.target.type == 'number') {
      const inputValue = e.target.value;
      if (!isNaN(inputValue) && Number(inputValue) >= 0) {
        setPhone({
          ...phone,
          [e.target.name]: e.target.value,
        });
        setError({
          ...phone,
          [e.target.name]: null,
        });
      }
    } else {
      setPhone({
        ...phone,
        [e.target.name]: e.target.value,
      });
      setError({
        ...phone,
        [e.target.name]: null,
      });
    }

  };

  const validation = () => {
    const newErrors = {};
    if (phone?.number == "") {
      newErrors.number = "Please enter phone number ";
    } else if (phone && phone?.number?.length != 10) {
      newErrors.number = "phone number should be 10 digits";
    }
    return newErrors;
  };

  const submit = async (e) => {
    e.preventDefault();
    const validationObject = validation();
    if (Object.keys(validationObject).length > 0) {
      setError(validationObject);
    } else {
      setLoader(true);
      if (validation()) {
        try {
          if (phone.number) {
            const responceData = await putApi(
              HIBEE_AUTH_URL + REACT_SEND_OTP_API_PATH + `?phone=${phone.number}&type=${type}`
            );
            console.log(responceData, 'responceData');
            toast.success(`OTP : ${responceData.data.otp}  sent successfully`, {
              position: "top-center",
              autoClose: 2000,
            });
            const data = {
              phone: phone.number,
              type: type,
            };
            navigate("/Otp", { state: data });
          }
        } catch (err) {
          const message = err?.response?.data?.message || "Enter Registered Number";
          toast.error(message, { position: "top-center", autoClose: 2000 });
        }
      }
      setLoader(false);
    }
  };

  return (
    <div className="main-div">
      <Container fluid>
        <Row className="pt-5">
          <Col xs lg="3.5" className=""></Col>
          <Col xs lg="5" className="g-0 ">
            <div className="first-box">
              <div className="d-flex justify-content-center">
                <div className="hibee-logo mt-5"></div>
              </div>
              <div className="d-flex justify-content-center">
                <div className="second-boxd mt-4">
                  <div style={{ marginTop: "50px" }}>
                    <div>
                      <p className="first-label">Forgot password ?</p>
                    </div>
                    <div>
                      <p className="second-label">
                        Enter the phone number associate with your aacount
                      </p>
                    </div>
                    <div>
                      <Form.Group>
                        <Form.Control
                          type="number"
                          placeholder="Enter your phone number"
                          className="form-control p-3 remove-spinner"
                          value={phone.number}
                          onWheelCapture={(e)=>e.target.blur()}

                          isInvalid={error.number}
                          name="number"
                          onChange={(e) => handlechange(e)}
                          autoComplete="off"
                          maxLength={10}
                          onInput={(e) => {
                            if (e.target.value.length > e.target.maxLength) {
                              e.target.value = e.target.value.slice(
                                0,
                                e.target.maxLength
                              );
                            }
                          }}
                        />
                        <FormControl.Feedback
                          type="invalid"
                          className="text-start"
                        >
                          {error.number}
                        </FormControl.Feedback>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row mt-5">

                    <div className="d-flex justify-content-center">
                      <button
                        className="login-button"
                        onClick={submit}
                      // disabled={loader}
                      >
                        Next {" "}
                        {
                          loader
                            ?
                            <Spinner
                              animation="border"
                              variant="light"
                              size="sm"
                            />
                            :
                            ''
                        }
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xs lg="3.5" className=""></Col>
        </Row>
      </Container>
    </div>
  );
}

export default ForgetPassword;
