import React, { useEffect, useState } from "react";
import "./Inventory.css";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import { Link } from "react-router-dom";
import { getTokenApi } from "../../api_interface/apis";
import {
  HIBEE_ADMIN_URL,
  INVENTORY_ALL_LIST,
  INVENTORY_DASHBOARD,
  INVENTORY_LIST,
} from "../../api_interface/apiURLs";
import MainTemplate from "../../components/MainTemplate";
import {
  customActionModalStyles,
  customModalStyles,
  debounce,
} from "../../utils/helperFunctin";
import Modal from "react-modal";

function Inventory() {
  const data = [
    { name: "Royal Gala Apple" },
    { name: "Organic Potatoes" },
    { name: "Bok choi" },
    { name: "Hide n Seek" },
    { name: "Cadbury Oreo" },
    { name: "Royal Gala Apple" },
  ];
  const [selectedOption, setSelectedOption] = useState('today');

  const [countData, setCountData] = useState([]);
  const [countLoader, setCountLoader] = useState(false);
  const [inventoryData, setInventoryData] = useState([]);
  const [lowOnStock, setLowOnStock] = useState(false);
  const [expirigSoon, setExpirigSoon] = useState(false);
  const [mostOrder, setMostOrder] = useState(false);
  const [mostReturn, setMostReturn] = useState(false);
  const [overViewList, setOverViewList] = useState([]);
  const [exportOption, setExportOption] = useState(false)
  const [inventory, setInventory] = useState(false);
  const [loader, setLoader] = useState(false);

  const [overview, setOverview] = useState(false)
  const getInventoryData = async () => {
    try {
      const res = await getTokenApi(
        HIBEE_ADMIN_URL + INVENTORY_DASHBOARD + INVENTORY_LIST,
        { today: true }
      );
      console.log('API Response:', res); // Add this console log to check the API response
      if (res?.data) {
        const orders = res.data.data;
        setInventoryData(orders);
        const allProducts = res?.data?.data?.product || [];
        
        // Filter products based on 'activated' property being true
        const activatedProducts = allProducts.filter(product => product.activated === true);
        console.log(activatedProducts)
        // Filter low stock products among activated products
        const lowStockProducts = activatedProducts.filter(product => product.low_stock);
        console.log(lowStockProducts, "final");
        
        // Filter expiring soon products among activated products
        const expiringSoonProducts = activatedProducts.filter(product => product.expiring_soon);
        const overviewList = [...lowStockProducts, ...expiringSoonProducts];
        setOverViewList(overviewList);
      }
    } catch (err) {
      const message = err?.response?.data?.message || "Something went wrong";
      // toast.error(message, { position: "top-center", autoClose: 2000 });
    }
  };


  const handleGetDashBoard = async () => {
    setCountLoader(true);
    const responce = await getTokenApi(HIBEE_ADMIN_URL + INVENTORY_DASHBOARD + `?status=${selectedOption}`);
    if (responce.status == 200) {
      setCountData(responce?.data?.count);
      setCountLoader(false);
    } else {
      setCountLoader(false);
    }
  };
  const getData = async (search) => {
    try {
      setLoader(true);
      const res = await getTokenApi(
        HIBEE_ADMIN_URL +
        INVENTORY_ALL_LIST
      );
      if (res?.data) {
        const list = res.data.data.slice(0, 5); // Get only the first 5 items from the data
        setInventory(list);
      }
      setLoader(false);
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  };


  useEffect(() => {
    handleGetDashBoard();
    getInventoryData();
    getData();
  }, [selectedOption]);

  async function handleExportClick() {
    // Assuming you have the API JSON data stored in a variable called `jsonData`

    // Set the export option to true
    await setExportOption(true);

    // Get the API JSON data
    await getInventoryData();

    // Specify the headers you want to export
    const headersToExport = ['_id', 'product_name', 'sizeList'];

    // Convert JSON to CSV format with the specified headers
    const csvContent = convertToCSV(inventoryData.product?.low_stock, headersToExport);

    // Create a temporary anchor element to initiate the download
    const downloadLink = document.createElement('a');
    downloadLink.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent);
    downloadLink.download = 'data.csv';

    // Trigger the download
    downloadLink.click();

    // Set the export option back to false
    await setExportOption(false);
  }


  function convertToCSV(jsonData, headersToExport) {
    // Filter the JSON data to include only the objects with the desired headers
    const filteredData = jsonData.map(obj => {
      const filteredObj = {};
      headersToExport.forEach(header => {
        filteredObj[header] = obj[header];
      });
      return filteredObj;
    });

    // Extract column headers from the first object in the filtered data
    const headers = Object.keys(filteredData[0]);

    // Convert each object to an array of values
    const rows = filteredData.map(obj => Object.values(obj));

    // Combine headers and rows
    const csvContent = [headers, ...rows].map(row => row.join(',')).join('\n');

    return csvContent;
  }


  return (
    <MainTemplate
      categoryName="Inventory Dashboard"
      categoryList={[ "Inventory", "Inventory Dashboard"]}
    >
      <div className="row mb-4 rounded p-2 me-2 bg-white">
        <div className="col-sm-6 my-3 col-md-2">
          <select
            className="t-12 form-select"
            aria-label="Default select example"
            value={selectedOption}
            onChange={(e) => setSelectedOption(e.target.value)}
          >
            <option value="today">Today</option>
            <option value="yesterday">Yesterday</option>
            <option value="last7days">Last 7 Days</option>
            <option value="last30days">Last 30 Days</option>
          </select>
        </div>

      </div>

      <div className="row mb-4">
        <div className="col-sm-6 col-lg-3 p-2 pe-3 ps-0">
          <div className="bg-white rounde p-3">
            <p className="font-weight-bold" style={{ textTransform: "capitalize" }}>Total number of products</p>
            <h1 className="font-weight-bold">
              {countLoader == false && countData ? (
                countData[0]?.total_product
              ) : (
                <span>
                  <div
                    class="spinner-border text-primary spinner-border-sm"
                    role="status"
                  ></div>
                </span>
              )}{" "}
            </h1>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3 p-2 pe-3">
          <div className="bg-white rounded p-3">
            <p className="font-weight-bold">Number of Orders{" "}
              <span style={{ textTransform: "capitalize" }}>
                {selectedOption}
              </span></p>
            <h1 className="font-weight-bold">
              {countLoader == false && countData ? (
                countData[1]?.todayTotalOrders
              ) : (
                <span>
                  <div
                    class="spinner-border text-primary spinner-border-sm"
                    role="status"
                  ></div>
                </span>
              )}
            </h1>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3 p-2 pe-3">
          <div className="bg-white rounded p-3">
            <p className="font-weight-bold" style={{ textTransform: "capitalize" }}>Product expired</p>
            <h1 className="font-weight-bold">
              {countLoader == false && countData ? (
                countData[0]?.expired_count
              ) : (
                <span>
                  <div
                    class="spinner-border text-primary spinner-border-sm"
                    role="status"
                  ></div>
                </span>
              )}
            </h1>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3 p-2 pe-3">
          <div className="bg-white rounded p-3">
            <p className="font-weight-bold" style={{ textTransform: "capitalize" }}>Number of Orders returned</p>
            <h1 className="font-weight-bold">
              {countLoader == false && countData ? (
                countData[1]?.return_count
              ) : (
                <span>
                  <div
                    class="spinner-border text-primary spinner-border-sm"
                    role="status"
                  ></div>
                </span>
              )}
            </h1>
          </div>
        </div>
      </div>

      <div className="row mb-3 p-0 t-14">
        <div className="col-lg-6 p-0 pe-3">
          <div className="pe-3 text-uppercase py-2 font-weight-bold">
            Low on stock
          </div>
          <div className="m-3 p-4 mx-0 mt-0 rounded bg-white">
            <div className="row">
              <div className="col-3 text-uppercase py-2 font-weight-bold border-b-dashed">
                Name
              </div>
              <div className="col-3 text-uppercase py-2 font-weight-bold border-b-dashed">
                Size
              </div>
              <div className="col-3 text-uppercase py-2 font-weight-bold border-b-dashed">
                Qty Left
              </div>
              <div className="col-3 text-uppercase py-2 font-weight-bold border-b-dashed d-flex justify-content-end">
                order More
              </div>
            </div>
            {inventoryData?.product?.low_stock
              ?.filter((el, i) => i < 6)
              ?.map((el) => (
                <div className="row">
                  <div className="col-3 text-uppercase py-2 border-b-dashed">
                    <Link
                      to={`/product/${el?._id}`}
                      state={{ data: el, edit: true }}
                      style={{ textDecoration: "none" }}
                    >
                      {el?.product_name}
                    </Link>
                  </div>
                  <div className="col-3 text-uppercase py-2 border-b-dashed">
                    {`${el?.sizeList?.size} ${el?.sizeList?.unit}`}
                  </div>
                  <div className="col-3 text-uppercase py-2 border-b-dashed">
                    {el?.sizeList?.quantity}
                  </div>
                  <div className="col-3 text-uppercase py-2 border-b-dashed d-flex justify-content-end">
                    <Link
                      to="/custominventory"
                      state={{ data: el, edit: true }}
                      style={{ textDecoration: "none" }}
                    >
                      <span className="cursorPointer">
                        <u>Add Inventory</u>
                      </span>
                    </Link>
                  </div>
                </div>
              ))}
            <div className="row pt-4">
              <span className=" d-flex justify-content-end cursorPointer">
                <u
                  onClick={() => {
                    setLowOnStock(true);
                  }}
                >
                  View All
                </u>
              </span>
            </div>
          </div>
        </div>
        <Modal
          isOpen={lowOnStock}
          onRequestClose={() => setLowOnStock(false)}
          style={customModalStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <div className=" p-0 pe-3 overflow-auto caterogyPageScroll">
            <div className="d-flex justify-content-between">
              <div className="model-header">Low on stock</div>
              <button
                className="t-12 ms-2 px-4 btn btn-dark text-warning "
                onClick={handleExportClick}
              >
                Export List
              </button>
            </div>

            <div className="m-3 p-4 mx-0 mt-0 rounded bg-white">
              <div className="row">
                <div className="col-2 model-header-heading border-b-dashed text-justify">
                  Product ID
                </div>
                <div className="col-3 model-header-heading border-b-dashed text-justify">
                  Name
                </div>
                <div className="col-2 model-header-heading border-b-dashed text-justify">
                  Size
                </div>
                <div className="col-2 model-header-heading border-b-dashed text-justify">
                  Qty Left
                </div>
                <div className="col-3 model-header-heading border-b-dashed d-flex justify-content-end text-justify">
                  Order More
                </div>
              </div>
              {inventoryData?.product?.low_stock?.map((el) => (
                <div className="row ">
                  <div className="col-2 model-header-info border-b-dashed text-justify">
                    {el?._id.slice(0, 5)}
                  </div>
                  <div className="col-3 model-header-info border-b-dashed text-justify ">
                    <Link
                      className="text-dark"
                      to={`/product/${el?._id}`}
                      state={{ data: el, edit: true }}
                      style={{ textDecoration: "none" }}
                    >
                      {el?.product_name}
                    </Link>
                  </div>
                  <div className="col-2 model-header-info border-b-dashed text-justify">
                    {`${el?.sizeList?.size} ${el?.sizeList?.unit}`}
                  </div>
                  <div className="col-2 model-header-info border-b-dashed text-justify">
                    {el?.sizeList?.quantity}
                  </div>
                  <div className="col-3 model-header-info border-b-dashed d-flex justify-content-end text-dark text-justify">
                    <Link
                      className="text-dark"
                      to="/custominventory"
                      state={{ data: el, edit: true }}
                      style={{ textDecoration: "none" }}
                    >
                      <span className="cursorPointer">
                        <u>Order Now</u>
                      </span>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={expirigSoon}
          onRequestClose={() => setExpirigSoon(false)}
          style={customModalStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <div className="p-0 pe-3 overflow-auto caterogyPageScroll">
            <div className="pe-3 text-uppercase py-2 font-weight-bold">
              Expiring soon
            </div>
            <div className="m-3 p-4 mx-0 mt-0 rounded bg-white">
              <div className="row">
                <div className="col-6 text-uppercase py-2 font-weight-bold border-b-dashed">
                  Name
                </div>
                <div className="col-6 text-uppercase py-2 font-weight-bold border-b-dashed d-flex justify-content-end">
                  Expiry
                </div>
              </div>
              {inventoryData?.product?.expiring_soon?.map((el) => (
                <div className="row">
                  <div className="col-6 text-uppercase py-2 border-b-dashed">
                    <Link
                      to={`/product/${el?._id}`}
                      style={{ textDecoration: "none" }}
                    >
                      {el?.product_name}
                    </Link>
                  </div>
                  <div className="col-6 text-uppercase py-2 border-b-dashed d-flex justify-content-end">
                    Expires in{" "}
                    {moment(el?.expiration_date).diff(
                      moment(new Date()),
                      "days"
                    )}{" "}
                    days
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={mostReturn}
          onRequestClose={() => setMostReturn(false)}
          style={customModalStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <div className="p-0 pe-3 overflow-auto caterogyPageScroll">
            <div className="pe-3 text-uppercase py-2 font-weight-bold">
              Most Returned
            </div>
            <div className="m-3 p-4 mx-0 mt-0 rounded bg-white">
              <div className="row">
                <div className="col-6 text-uppercase py-2 font-weight-bold border-b-dashed">
                  Name
                </div>
                <div className="col-6 text-uppercase py-2 font-weight-bold border-b-dashed d-flex justify-content-end">
                  FREQ(per month)
                </div>
              </div>
              {Array.isArray(inventoryData?.order?.mostReturn) &&
                inventoryData?.order?.mostReturn?.map((el) => (
                  <div className="row">
                    <div className="col-6 text-uppercase py-2 border-b-dashed">
                      {el?.name}
                    </div>
                    <div className="col-6 text-uppercase py-2 border-b-dashed d-flex justify-content-end">
                      {el.count}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={mostOrder}
          onRequestClose={() => setMostOrder(false)}
          style={customModalStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
          <div className="p-0 pe-3 overflow-auto caterogyPageScroll">
            <div className="pe-3 text-uppercase py-2 font-weight-bold">
              Most Ordered
            </div>
            <div className="m-3 p-4 mx-0 mt-0 rounded bg-white">
              <div className="row">
                <div className="col-6 text-uppercase py-2 font-weight-bold border-b-dashed">
                  Name
                </div>
                <div className="col-6 text-uppercase py-2 font-weight-bold border-b-dashed d-flex justify-content-end">
                  Count
                </div>
              </div>
              {Array.isArray(inventoryData?.order?.mostOrder) &&
                inventoryData?.order?.mostOrder?.map((el) => (
                  <div className="row">
                    <div className="col-6 text-uppercase py-2 border-b-dashed">
                      {el?.name}
                    </div>
                    <div className="col-6 text-uppercase py-2 border-b-dashed  d-flex justify-content-end">
                      {el.count}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </Modal>
        <div className="col-lg-6 p-0 pe-3">
          <div className="pe-3 text-uppercase py-2 font-weight-bold">
            Expiring soon
          </div>
          <div className="m-3 p-4 mx-0 mt-0 rounded bg-white">
            <div className="row">
              <div className="col-6 text-uppercase py-2 font-weight-bold border-b-dashed">
                Name
              </div>
              <div className="col-6 text-uppercase py-2 font-weight-bold border-b-dashed d-flex justify-content-end">
                Expiry
              </div>
            </div>
            {inventoryData?.product?.expiring_soon
              ?.filter((el, i) => i < 6)
              ?.map((el) => (
                <div className="row">
                  <div className="col-6 text-uppercase py-2 border-b-dashed">
                    <Link
                      to={`/product/${el?._id}`}
                      style={{ textDecoration: "none" }}
                    >
                      {el?.product_name}
                    </Link>
                  </div>
                  <div className="col-6 text-uppercase py-2 border-b-dashed d-flex justify-content-end">
                    Expires in{" "}
                    {moment(el?.expiration_date).diff(
                      moment(new Date()),
                      "days"
                    )}{" "}
                    days
                  </div>
                </div>
              ))}
            <div className="row pt-4">
              <span
                className=" d-flex justify-content-end cursorPointer"
                onClick={() => setExpirigSoon(true)}
              >
                <u>View All</u>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-3 p-0 t-14">
        <div className="col-lg-6 p-0 pe-3">
          <div className="pe-3 text-uppercase py-2 font-weight-bold">
            Most Returned
          </div>
          <div className="m-3 p-4 mx-0 mt-0 rounded bg-white">
            <div className="row">
              <div className="col-6 text-uppercase py-2 font-weight-bold border-b-dashed">
                Name
              </div>
              <div className="col-6 text-uppercase py-2 font-weight-bold border-b-dashed d-flex justify-content-end">
                FREQ(per month)
              </div>
            </div>
            {Array.isArray(inventoryData?.order?.mostReturn) &&
              inventoryData?.order?.mostReturn
                ?.filter((el, i) => i < 6)
                ?.map((el) => (
                  <div className="row">
                    <div className="col-6 text-uppercase py-2 border-b-dashed">
                      {el?.name}
                    </div>
                    <div className="col-6 text-uppercase py-2 border-b-dashed d-flex justify-content-end">
                      {el.count}
                    </div>
                  </div>
                ))}
            <div className="row pt-4">
              <span
                className=" d-flex justify-content-end cursorPointer"
                onClick={() => setMostReturn(true)}
              >
                <u>View All</u>
              </span>
            </div>
          </div>
        </div>

        <div className="col-lg-6 p-0 pe-3">
          <div className="pe-3 text-uppercase py-2 font-weight-bold">
            Most Ordered
          </div>
          <div className="m-3 p-4 mx-0 mt-0 rounded bg-white">
            <div className="row">
              <div className="col-6 text-uppercase py-2 font-weight-bold border-b-dashed">
                Name
              </div>
              <div className="col-6 text-uppercase py-2 font-weight-bold border-b-dashed d-flex justify-content-end">
                Count
              </div>
            </div>
            {Array.isArray(inventoryData?.order?.mostOrder) &&
              inventoryData?.order?.mostOrder
                ?.filter((el, i) => i < 6)
                ?.map((el) => (
                  <div className="row">
                    <div className="col-6 text-uppercase py-2 border-b-dashed">
                      {el?.name}
                    </div>
                    <div className="col-6 text-uppercase py-2 border-b-dashed  d-flex justify-content-end">
                      {el.count}
                    </div>
                  </div>
                ))}
            <div className="row pt-4">
              <span
                className=" d-flex justify-content-end cursorPointer"
                onClick={() => setMostOrder(true)}
              >
                <u>View All</u>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <div style={{ color: "#3D3D3D", fontFamily: "Inter", fontSize: 12, fontWeight: "700", textTransform: "uppercase" }}>
          Custom inventory History
        </div>
        <Link to="/custominventory" style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 6, border: "0.959px solid #12352F", maxWidth: 160, color: "#12352F", fontSize: 13, fontWeight: 500, padding: 8, textDecorationLine: "none" }}>
          Add Custom Inventory
        </Link>
      </div>
      <div style={{ height: "auto", borderRadius: 6, background: "#FFF", marginTop: 5, width: "100%", padding: 8, marginBottom: 12, }}>
        <table className='w-100'>
          <thead className=''>
            <tr className="">
              <td className='custominventoryhistoryheading py-2 px-2'>
                Date
              </td>
              <td className='custominventoryhistoryheading py-2 px-2' style={{ width: 720 }}>
                invoice number
              </td>
              <td className='custominventoryhistoryheading py-2 px-2'>
                Total Amount
              </td>
              <td className='custominventoryhistoryheading py-2 px-2'>

              </td>
            </tr>
          </thead>
          <tbody className='my-5'>
            {inventory ? (
              inventory.map((item, index) => (
                <>
                  <tr
                    className="border rounded"
                    style={{ borderWidth: 0, borderStyle: "hidden", borderSpacing: "separate", borderRadius: 12 }}
                  >
                    <td className="custominventoryhistorydata py-2 px-2">
                      {item.purchase_date}
                    </td>
                    <td className="custominventoryhistorydata py-2 px-2">
                      {item.invoice_number}
                    </td>
                    <td className="custominventoryhistorydata py-2 px-2">
                      ₹ {item.final_bill_amount}
                    </td>
                  </tr>
                  <div style={{ height: 8, }}>

                  </div>
                </>
              ))
            ) : (
              <tr>
                <td colSpan="4">
                  <p>No inventory data available.</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Link to='/custominventoryhistory' className="" style={{ height: 8, color: "#12352F", fontSize: 14, fontWeight: 400, textDecorationLine: "underline", padding: 24, display: "flex", justifyContent: "end", width: "100%" }}>
          View All
        </Link>
      </div>

      <Modal
        isOpen={overview}
        onRequestClose={() => setOverview(false)}
        style={customModalStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className="inventory-overview">
          OverView
          <div className="row rounded px-3 bg-white">
            {/* header */}
            <div className="row m-0 ">
              <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2 ">
                Photo
              </div>
              <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-3 text-justify">
                Name
              </div>
              <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2 text-justify">
                Size
              </div>
              <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-1  text-justify">
                Qty
              </div>
              <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2  text-justify">
                Expiry
              </div>
              <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2 text-justify">
                Order
              </div>

            </div>

            {/* body */}
            <div className="m-0 p-0 pe-3 overflow-auto caterogyPageScroll">

              {overViewList?.map((el) =>
              (<div className="row border rounded m-0 px-2  mb-2 d-flex align-items-center">
                <div className="t-12 text-uppercase my-3 col-md-2">
                  <div className="  rounded p-1">
                    <Link
                      className="text-dark"
                      to={`/product/${el?._id}`}
                      state={{ data: el, edit: true }}
                      style={{ textDecoration: "none" }}
                    >
                      {el?.images && el.images.length > 0 ? (
                        <img src={el.images[0].image} alt="loading" className="categoryImage h-100 rounded" />
                      ) : (
                        <img src={el?.image} alt="loading" className="categoryImage h-100 rounded" />
                      )}
                    </Link>
                  </div>
                </div>
                <div className="t-12 text-uppercase my-3 col-md-3 model-header-info text-justify">
                  <Link
                    className="text-dark"
                    to={`/product/${el?._id}`}
                    style={{ textDecoration: "none" }}
                  >
                    {el.product_name}
                  </Link>
                </div>
                <div className="t-12 text-uppercase my-3 col-md-2 model-header-info text-justify">
                  {`${el?.sizeList?.size} ${el?.sizeList?.unit}`}
                </div>
                <div className="t-12 text-uppercase my-3 col-md-1 model-header-info text-justify">
                  {el?.sizeList?.quantity}
                </div>
                <div className="t-12 text-uppercase my-3 col-md-2 model-header-info text-justify">
                  Expires in{" "}
                  {moment(el?.expiration_date).diff(
                    moment(new Date()),
                    "days"
                  )}{" "}
                  days
                </div>
                <Link to="/custominventory"
                  state={{ data: el, edit: true }} className="t-12 text-uppercase my-3 col-md-2 model-header-info text-justify">
                  Order more
                </Link>
                {/* <div className="t-12 text-uppercase my-3 col-md-1">
                <svg
                  className="bi bi-pencil border-bottom border-dark"
                  // onClick={() => {
                  //   setModal(true);
                  //   setcategory(el);
                  // }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                </svg>
              </div> */}
                {/* <div className="t-12 text-uppercase my-3 col-md-1">
                  <div class="dropdown">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      // data-menu-target={`drop-down-menu-${el?._id}`}
                      className="bi bi-three-dots dropdown-btn cursorPointer"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                    </svg>
                    <div
                      // id={`drop-down-menu-${el?._id}`}
                      className="dropdown-content p-2 mobile-dropdown"
                    >
                      <div
                        className="px-3 py-2 d-flex cursorPointer"
                      // onClick={() =>
                      //   setActionModal({
                      //     ...actionModal,
                      //     show: true,
                      //     data: el,
                      //     type: "DELETE",
                      //   })
                      // }
                      >
                        <span className="mx-2 d-flex align-items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-trash3"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                          </svg>
                        </span>
                        <span className="mx-2">
                          <div className="font-weight-bold">Delete</div>
                          <div className="">Delete this product</div>
                        </span>
                      </div>
                      <div className="px-3 py-2 d-flex cursorPointer" onClick={() => setActionModal({...actionModal, show:true, data: el, type: "ARCHIEVE"})}>
												<span className="mx-2 d-flex align-items-center">
													<svg width="16" height="16" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M2.44444 22C1.77222 22 1.19656 21.7609 0.717444 21.2826C0.239148 20.8034 0 20.2278 0 19.5556V4.27778C0 3.97222 0.0509259 3.69722 0.152778 3.45278C0.25463 3.20833 0.387037 2.97407 0.55 2.75L2.26111 0.672222C2.42407 0.448148 2.62778 0.279889 2.87222 0.167445C3.11667 0.0558149 3.38148 0 3.66667 0H18.3333C18.6185 0 18.8833 0.0558149 19.1278 0.167445C19.3722 0.279889 19.5759 0.448148 19.7389 0.672222L21.45 2.75C21.613 2.97407 21.7454 3.20833 21.8472 3.45278C21.9491 3.69722 22 3.97222 22 4.27778V19.5556C22 20.2278 21.7609 20.8034 21.2826 21.2826C20.8034 21.7609 20.2278 22 19.5556 22H2.44444ZM2.93333 3.66667H19.0667L18.0278 2.44444H3.97222L2.93333 3.66667ZM2.44444 6.11111V19.5556H19.5556V6.11111H2.44444ZM11 18.3333L15.8889 13.4444L14.1778 11.7333L12.2222 13.6889V8.55556H9.77778V13.6889L7.82222 11.7333L6.11111 13.4444L11 18.3333ZM2.44444 19.5556H19.5556H2.44444Z" fill="#666666"/>
													</svg>
												</span>
												<span className="mx-2">
													<div className="font-weight-bold">Archive</div>
													<div className="">Archive this product</div>
												</span>
											</div>
                      <a href="#home">Home</a>
											<a href="#about">About</a>
											<a href="#contact">Contact</a>
                    </div>
                  </div>
                </div> */}
              </div>))}

              {/* {loader == true && (
            <div className="text-center mt-5">
              <button
                class="btn mt-5"
                style={{ backgroundColor: "#FFEDC2" }}
                type="button"
              >
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>{" "}
                Loading...
              </button>
            </div>
          )} */}
            </div>

          </div>
        </div>
      </Modal>
      <div className="inventory-overview">
        OverView
        <div className="row rounded px-3 bg-white">
          {/* header */}
          <div className="row m-0 ">
            <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
              Photo
            </div>
            <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-3 text-justify">
              Name
            </div>
            <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2 text-justify">
              Size
            </div>
            <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-1 text-justify">
              Qty
            </div>
            <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2 text-justify">
              Expiry
            </div>
            <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2 text-justify">
              Order
            </div>

          </div>

          {/* body */}
          <div className="m-0 p-0 pe-3 overflow-auto caterogyPageScroll">

            {overViewList?.filter((el, i) => i < 4).map((el) =>
            (<div className="row border rounded m-0 px-2  mb-2 d-flex align-items-center">
              <div className="t-12 text-uppercase my-3 col-md-2">
                <div className=" rounded p-1">
                  <Link
                    className="text-dark"
                    to={`/product/${el?._id}`}
                    state={{ data: el, edit: true }}
                    style={{ textDecoration: "none" }}
                  >
                    {el?.images && el.images.length > 0 ? (
                      <img src={el.images[0].image} alt="loading" className="categoryImage h-100 rounded" />
                    ) : (
                      <img src={el?.image} alt="loading" className="categoryImage h-100 rounded" />
                    )}
                  </Link>
                </div>
              </div>
              <div className="t-12 text-uppercase my-3 col-md-3 model-header-info text-justify">
                <Link
                  className="text-dark"
                  to={`/product/${el?._id}`}
                  style={{ textDecoration: "none" }}
                >
                  {el.product_name}
                </Link>
              </div>
              <div className="t-12 text-uppercase my-3 col-md-2 model-header-info text-justify">
                {`${el?.sizeList?.size} ${el?.sizeList?.unit}`}
              </div>
              <div className="t-12 text-uppercase my-3 col-md-1 model-header-info text-justify">
                {el?.sizeList?.quantity}
              </div>
              <div className="t-12 text-uppercase my-3 col-md-2 model-header-info text-justify">
                Expires in{" "}
                {moment(el?.expiration_date).diff(
                  moment(new Date()),
                  "days"
                )}{" "}
                days
              </div>
              <Link to="/custominventory"
                state={{ data: el, edit: true }} className="t-12 text-uppercase my-3 col-md-2 model-header-info text-justify">
                Order more
              </Link>
              {/* <div className="t-12 text-uppercase my-3 col-md-1">
                <svg
                  className="bi bi-pencil border-bottom border-dark"
                  // onClick={() => {
                  //   setModal(true);
                  //   setcategory(el);
                  // }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                </svg>
              </div> */}
              {/* <div className="t-12 text-uppercase my-3 col-md-1">
                <div class="dropdown">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    // data-menu-target={`drop-down-menu-${el?._id}`}
                    className="bi bi-three-dots dropdown-btn cursorPointer"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                  </svg>
                  <div
                    // id={`drop-down-menu-${el?._id}`}
                    className="dropdown-content p-2 mobile-dropdown"
                  >
                    <div
                      className="px-3 py-2 d-flex cursorPointer"
                    // onClick={() =>
                    //   setActionModal({
                    //     ...actionModal,
                    //     show: true,
                    //     data: el,
                    //     type: "DELETE",
                    //   })
                    // }
                    >
                      <span className="mx-2 d-flex align-items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-trash3"
                          viewBox="0 0 16 16"
                        >
                          <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                        </svg>
                      </span>
                      <span className="mx-2">
                        <div className="font-weight-bold">Delete</div>
                        <div className="">Delete this product</div>
                      </span>
                    </div>
                    <div className="px-3 py-2 d-flex cursorPointer" onClick={() => setActionModal({...actionModal, show:true, data: el, type: "ARCHIEVE"})}>
												<span className="mx-2 d-flex align-items-center">
													<svg width="16" height="16" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M2.44444 22C1.77222 22 1.19656 21.7609 0.717444 21.2826C0.239148 20.8034 0 20.2278 0 19.5556V4.27778C0 3.97222 0.0509259 3.69722 0.152778 3.45278C0.25463 3.20833 0.387037 2.97407 0.55 2.75L2.26111 0.672222C2.42407 0.448148 2.62778 0.279889 2.87222 0.167445C3.11667 0.0558149 3.38148 0 3.66667 0H18.3333C18.6185 0 18.8833 0.0558149 19.1278 0.167445C19.3722 0.279889 19.5759 0.448148 19.7389 0.672222L21.45 2.75C21.613 2.97407 21.7454 3.20833 21.8472 3.45278C21.9491 3.69722 22 3.97222 22 4.27778V19.5556C22 20.2278 21.7609 20.8034 21.2826 21.2826C20.8034 21.7609 20.2278 22 19.5556 22H2.44444ZM2.93333 3.66667H19.0667L18.0278 2.44444H3.97222L2.93333 3.66667ZM2.44444 6.11111V19.5556H19.5556V6.11111H2.44444ZM11 18.3333L15.8889 13.4444L14.1778 11.7333L12.2222 13.6889V8.55556H9.77778V13.6889L7.82222 11.7333L6.11111 13.4444L11 18.3333ZM2.44444 19.5556H19.5556H2.44444Z" fill="#666666"/>
													</svg>
												</span>
												<span className="mx-2">
													<div className="font-weight-bold">Archive</div>
													<div className="">Archive this product</div>
												</span>
											</div>
                    <a href="#home">Home</a>
											<a href="#about">About</a>
											<a href="#contact">Contact</a>
                  </div>
                </div>
              </div> */}
            </div>))}

            {/* {loader == true && (
            <div className="text-center mt-5">
              <button
                class="btn mt-5"
                style={{ backgroundColor: "#FFEDC2" }}
                type="button"
              >
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>{" "}
                Loading...
              </button>
            </div>
          )} */}
          </div>
          <div className="row pt-4">
            <span className=" d-flex justify-content-end cursorPointer">
              <u onClick={() => setOverview(true)}>View All</u>
            </span>
          </div>
        </div>
      </div>
    </MainTemplate>
  );
}

export default Inventory;
