import { createSlice } from '@reduxjs/toolkit'

export const sidebardModuleSlice = createSlice({
    name: 'user',
    initialState: {},
    reducers: {
        addSidebarModules: (state, action) => {
            return action?.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { addSidebarModules } = sidebardModuleSlice.actions

export default sidebardModuleSlice.reducer