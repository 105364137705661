import {
  Form,
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { useEffect, useState } from "react";
// import { fontSize } from "@mui/system";
import "../OfferInternal/OfferInternal.css";
import Edit from "../../assets/images/Edit.png";
import Delete from "../../assets/images/Delete.png";
import MainTemplate from "../../components/MainTemplate";
import Mobliepic from "../../assets/images/mock 1.png";
import bxsoff from "../../assets/images/bxs_offer.png";
import { useNavigate, useParams } from "react-router-dom";
import { HIBEE_ADMIN_URL, REACT_OFFERS_API_PATH } from "../../api_interface/apiURLs";
import { deleteTokenApi, getTokenApi, postTokenApi } from "../../api_interface/apis";
import { customActionModalStyles, customModalStyles } from "../../utils/helperFunctin";
import Modal from 'react-modal'

function OfferInternal() {
  const [modal, setModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [img1, setImg1] = useState('')
  const [offerDetail, setOfferDetail] = useState([])
  const [offerDeatilLoader, setOfferDeatilLoader] = useState(false)
  const [actionModal, setActionModal] = useState({ loader: false, show: false, type: "", data: {}, });
  const navigate = useNavigate()
  const params = useParams()
  const handleOfferDetails = async () => {
    setOfferDeatilLoader(true)
    const res = await getTokenApi(HIBEE_ADMIN_URL + REACT_OFFERS_API_PATH + `/${params.offerId}`);
    console.log(res, 'res');
    if (res.status == 200) {
      setOfferDeatilLoader(false)
      setOfferDetail(res?.data?.data);
    }
  }

  const deleteOffer = async (id) => {
    const res = await deleteTokenApi(HIBEE_ADMIN_URL + REACT_OFFERS_API_PATH + `/${id}`);
    if (res.status == 200) {
      setActionModal({});
      handleOfferDetails()
      navigate('/offer-listing')
    }
  };

  const Submit = async (e) => {
    e.preventDefault();
    setLoader(true);
    const { offer_code, min_discount, _id, min_order_value, title, image } = offerDetail;
    const fd = new FormData();
    if (_id) {
      fd.append("_id", _id);
    }
    fd.append("min_order_value", min_order_value);
    fd.append("min_discount", min_discount);
    fd.append("title", title);
    fd.append("offer_code", offer_code);
    fd.append("image", image);
    const res = await postTokenApi(HIBEE_ADMIN_URL + REACT_OFFERS_API_PATH, fd, { "Content-Type": "multipart/form-data" });
    if (res.status == 200) {
      setModal(false);
      setLoader(false);
      handleOfferDetails();
    }
  };

  useEffect(() => {
    if (params.offerId) {
      handleOfferDetails()
    }
  }, [])

  return (
    <MainTemplate categoryName="HIBEE4U" categoryList={["Offers", 'HIBEE4U']}>
      <div className="container">
        <div className="row mt-5">
          <div className="col-md-6">
            <div className="d-flex align-items-center justify-content-center h-100 p-3">
              <img className="rounded shadow-sm" src={offerDetail && offerDetail?.image} width='100%' height='100%' style={{objectFit: "cover"}}/>
              {/* <div className="tempheit"></div>
              <div className="txtcnt">
                <div className="mt-1">Screen View</div>
              </div>
              <div className="row inbox mt-4">
                <div className="col-md-2 mrgtop">
                  <div>
                    <img src={bxsoff} />
                  </div>
                </div>
                <div className="col-md-10 mrgtop">
                  <div className="fntbig">Upto ₹50 OFF</div>
                  <div className="lnhgt">On a minimum order value of ₹ 499</div>
                </div>
              </div>
              <div className="mt-3">
                <img src={Mobliepic} className="mblside" />
              </div> */}

            </div>
          </div>
          <div className="col-md-5">
            <div className="row">
              <div className="row mt-5"></div>
              <div>
                <div className="row">
                  <div className="hibfntcl">
                    <div className="hibfntb">{offerDetail?.title}</div>
                    {/* <div className="togbtn">
                    <input type="checkbox" id="toggle-btn-1" />
                    <label for="toggle-btn-1"></label>
                  </div> */}
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="lablesall">
                  <div>Minimum order value:</div>
                  <div>#{offerDetail?.min_order_value}</div>
                </div>
                <hr></hr>
                <div className="lablesall">
                  <div>Maximum Discount:</div>
                  <div>₹{offerDetail?.min_discount}</div>
                </div>
                <hr></hr>
                <div className="lablesall">
                  <div>Cutout Price:</div>
                  <div>₹23</div>
                </div>
                <hr></hr>
                <div className="lablesall">
                  <div>Size:</div>
                  <div>500 g</div>
                </div>
                <hr></hr>
                <div className="lablesall">
                  <div>Shelf Life:</div>
                  <div>7 Days</div>
                </div>
                <hr></hr>
                <div className="lablesall">
                  <div>Code Title:</div>
                  <div>{offerDetail?.title}</div>
                </div>
                <hr></hr>
                <div className="lablesall">
                  <div>Image:</div>
                  <div>googlepay.png</div>
                </div>
                <hr></hr>
                <div className="lablesall">
                  <div>Code Description:</div>
                  <div className="alinend">
                    On a minimum order
                    <br />
                    value of ₹ 499{" "}
                  </div>
                </div>
              </div>
              <div className="spaccesl"></div>
              <div className="row-md-3">
                <div className=" lablesall">
                  <div className="col-7"></div>
                  <div onClick={() => setModal(true)}>
                    <img src={Edit} />
                    Edit
                  </div>
                  <div onClick={() => setActionModal({ ...actionModal, show: true, data: offerDetail._id, type: "DELETE" })}>
                    <img src={Delete} /> Delete
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Delete Offer */}
      <Modal
        isOpen={actionModal?.show}
        onRequestClose={() => setActionModal({})}
        style={customActionModalStyles}
        contentLabel="Action Modal"
        ariaHideApp={false}
      >
        <div className="px-3 py-3">
          <div className="row">
            <span className="d-flex justify-content-end cursorPointer">
              <svg
                onClick={() => setActionModal({})}
                className="d-inline"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </span>
          </div>
          <div className="row">
            <h4 className="text-danger d-flex justify-content-center">
              Do you want to {actionModal?.type} Offer?
            </h4>
          </div>
          <div className="row my-5">
            <h5 className="d-flex justify-content-center">
              {actionModal?.data?.name}
            </h5>
          </div>
          <div className="form-group my-3 d-flex justify-content-center">
            <button
              disabled={actionModal?.loader}
              className="btn btn-dark me-3 mb-2 text-warning px-5"
              onClick={() =>
                actionModal?.type === "DELETE"
                && deleteOffer(actionModal?.data)
                // : archiveOffer(actionModal?.data?._id)
              }
            >
              {actionModal?.loader ? (
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="sr-only"></span>
                </div>
              ) : (
                "Submit"
              )}
            </button>
            <button
              disabled={actionModal?.loader}
              className="btn btn-light me-3 mb-2 shadow-sm text-dark border px-5"
              onClick={() => setActionModal({})}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={modal}
        onRequestClose={() => {
          setModal(false);
          setOfferDetail({});
          setOfferDeatilLoader(false);
        }}
        style={customModalStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className="container containerbg overflow-auto rounded bg-white row w-100 ">
          <div className="px-3 py-3">
            <div className="row">
              <span className="d-flex justify-content-end cursorPointer">
                <svg
                  onClick={() => {
                    setModal(false);
                    setOfferDetail({});
                    offerDeatilLoader(false);
                  }}
                  className="d-inline"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                </svg>
              </span>
            </div>
            <h5 className=" mt-4 text-center">Update Offer</h5>
            <div className="row">
              <div className="form-group my-3">
                <label className="my-2">Minimum Order Value</label>
                <input
                  type="text"
                  value={offerDetail?.min_order_value}
                  onChange={(e) => setOfferDetail({ ...offerDetail, min_order_value: e.target.value })}
                  className="placeholder-light form-control my-2 py-2 px-3"
                  placeholder="eg: 20"
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group my-3">
                <label className="my-2">Offer Name</label>
                <input
                  value={offerDetail?.title}
                  onChange={(e) => setOfferDetail({ ...offerDetail, title: e.target.value })}
                  type="text"
                  className="placeholder-light form-control my-2 py-2 px-3"
                  placeholder="eg: OFFER25"
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group my-3">
                <label className="my-2">Offer Code</label>
                <input
                  type="text"

                  value={offerDetail?.offer_code}
                  onChange={(e) => setOfferDetail({ ...offerDetail, offer_code: e.target.value })}
                  className="placeholder-light form-control my-2 py-2 px-3"
                  placeholder="eg: 20"
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group my-3">
                <label className="my-2">Minimum discount</label>
                <input
                  type="text"
                  value={offerDetail?.min_discount}
                  onChange={(e) => setOfferDetail({ ...offerDetail, min_discount: e.target.value })}
                  className="placeholder-light form-control my-2 py-2 px-3"
                  placeholder="eg: 20"
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group py-2  mb-4 position-relative">
                <label>Upload Image</label>
                <input
                  type="file"
                  name="image"
                  className="homepageModifierInput opacity0 position-absolute w-75 placeholder-light form-control py-2 px-3"
                  placeholder="eg: https://www.apple.com/in/watch"
                  onChange={(e) => {
                    setOfferDetail({ ...offerDetail, image: e?.target?.files?.[0] })
                    setImg1(e?.target?.files?.[0])
                  }}
                />
                <div className={`homepageModifierInput select-text-light d-flex align-items-center justify-content-center border rounded w-75 }`}  >
                  {offerDetail?.image ? (
                    <img src={img1 == '' ? offerDetail && offerDetail?.image : URL.createObjectURL(img1)} className="rounded h-100 w-100 shadow" alt="img" />
                  ) : (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-cloud-arrow-up"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z"
                        />
                        <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                      </svg>
                      <span className="px-2">Upload Photo</span>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="form-group my-3">
              <button
                disabled={loader}
                className="btn btn-dark text-warning px-5"
                onClick={Submit}
              >
                {loader ? (
                  <div class="spinner-border spinner-border-sm" role="status">
                    <span class="sr-only"></span>
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </div>
      </Modal>

    </MainTemplate>
  );
}

export default OfferInternal;
