import React, { useState } from 'react';
import MainTemplate from '../../components/MainTemplate';
import { HIBEE_ADMIN_URL } from '../../api_interface/apiURLs';
import { postTokenApi } from '../../api_interface/apis';
import { formToJSON } from 'axios';

const Marketingform = () => {
    const initialFormData = {
        title: '',
        body: '',
        image: null,
      };
    
      const initialErrors = {
        title: '',
        body: '',
        image: '',
      };
    
      const [formData, setFormData] = useState(initialFormData);
      const [couponDataErrors, setCouponDataErrors] = useState(initialErrors);
    
      const handleChange = (e) => {
        const { name, value, type, files } = e.target;
      
        if (type === 'file') {
          console.log(name,type,files,"Data")
          setFormData({ ...formData, [name]: files[0] });
        } else {
          setFormData({ ...formData, [name]: value });
        }
        console.log(formData)
      
        setCouponDataErrors((prevErrors) => ({
          ...prevErrors,
          [name]: '',
        }));
      };
    
      const handleClear = () => {
        setFormData(initialFormData);
        setCouponDataErrors(initialErrors);
      };
    
      const validateForm = () => {
        const errors = {};
    
        if (!formData.title.trim()) {
          errors.title = 'Title is required';
        }
    
        if (!formData.body.trim()) {
          errors.body = 'Body is required';
        }
    
        if (!formData.image) {
          errors.image = 'Image is required';
        }
    
        setCouponDataErrors(errors);
    
        return Object.keys(errors).length === 0;
      };
    
      const submit = async (e) => {
        e.preventDefault();
      
        if (validateForm()) {
          try {
            const formDataToSend = new FormData();
            formDataToSend.append('title', formData.title);
            formDataToSend.append('body', formData.body);
            formDataToSend.append('image',{
              uri: formData.image.uri,
              type: formData.image.type,
              name:formData.image.name});

            console.log(formData.image,"Send Data")  
            const requestUrl = HIBEE_ADMIN_URL + '/admin/notification/marketing';
            
            const headers = {
              'Content-Type': 'multipart/form-data',
              // Include any additional headers if needed, such as 'Authorization': 'Bearer YOUR_ACCESS_TOKEN'
            };
      
            const response = await postTokenApi(requestUrl, formDataToSend, { headers });
      
            console.log('Post success:', response);
      
            handleClear();
          } catch (error) {
            console.error('Post error:', error);
          }
        }
      };
      
    return (
        <MainTemplate
            categoryName="Marketing Form"
            categoryList={["Marketing Form", "Create Notification"]}
        >
            <form onSubmit={submit}>
                <div className="col-9 form-group py-2">
                    <label>Notification Title <span className="text-danger">*</span></label>
                    <input
                        type="text"
                        className='w-75 placeholder-light form-control py-2 px-3 form-control'
                        placeholder="Enter the Title"
                        onChange={handleChange}
                        value={formData.title}
                        name="title"
                        isInvalid={couponDataErrors?.title}
                        autoComplete="off"
                    />
                    {couponDataErrors.title && (
                        <div className="text-danger">{couponDataErrors.title}</div>
                    )}
                </div>

                <div className="col-9 form-group py-2">
                    <label>Notification Body <span className="text-danger">*</span></label>
                    <textarea
                        className={`w-75 placeholder-light form-control py-2 px-3 form-control ${couponDataErrors?.body && "border-danger"}`}
                        placeholder="Enter the body"
                        onChange={handleChange}
                        value={formData.body}
                        isInvalid={couponDataErrors?.body}
                        name="body"
                        autoComplete="off"
                    />
                    {couponDataErrors.body && (
                        <div className="text-danger">{couponDataErrors.body}</div>
                    )}
                </div>

                <div className="col-9 form-group py-2">
                    <label>Notification Image <span className="text-danger">*</span></label>
                    <input
                        type="file"
                        className='w-75 placeholder-light form-control py-2 px-3 form-control'
                        onChange={handleChange}
                        name="image"
                    />
                    {couponDataErrors.image && (
                        <div className="text-danger">{couponDataErrors.image}</div>
                    )}
                </div>

                <div className="d-flex form-group py-2">
                    <div className="col-3">
                        <button
                            type="submit"
                            className="btn text-warning px-5"
                            style={{ backgroundColor: "#12352f" }}
                        >
                            Create Notification
                        </button>
                    </div>
                    <div className="col-3">
                        <button
                            type="reset"
                            onClick={handleClear}
                            className="btn btn-outline-dark text-dark px-5"
                        >
                            Clear
                        </button>
                    </div>
                </div>
            </form>
        </MainTemplate>
    );
};

export default Marketingform;
