import React, { useState, useEffect, useMemo } from "react";
import "./HomepageModifier.css";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import MainTemplate from "../../components/MainTemplate";
import { postTokenApi } from "../../api_interface/apis";
import { ADVERTISEMENT_CREATE_PATH, HIBEE_ADMIN_URL } from "../../api_interface/apiURLs";


function HomepageModifierAdvertisement() {
	const [advertisement, setAdvertisement] = useState({ banner_name: "", link: "", image: "", editImage: "", id: "" });
	const [advertisementErrors, setAdvertisementErrors] = useState({ banner_name: "", link: "", image: "", editImage: "", id: "" });
	const [loader, setLoader] = useState(false);
	const location = useLocation()
	const { state } = location || {}

	const navigate = useNavigate();
	const handleChange = (e) => {
		if (e.target.name !== "image") {
			setAdvertisement({ ...advertisement, [e.target.name]: e.target.value });
			setAdvertisementErrors({ ...advertisementErrors, [e.target.name]: null });
		}
		if (e.target.name == "image") {
			var url = URL.createObjectURL(e.target.files[0]);
			setAdvertisement({ ...advertisement, [e.target.name]: e.target.files[0], editImage: "" });
			setAdvertisementErrors({ ...advertisementErrors, [e.target.name]: null });
		}
	};


	const validation = () => {
		const errorObj = {};
		if (!advertisement?.banner_name) {
			errorObj.banner_name = "Enter banner name";
		}
		if (!advertisement?.image && !advertisement?.editImage) {
			errorObj.image = "Image is required";
		}
		if (!advertisement?.link) {
			errorObj.link = "Link is required";
		}
		return errorObj;
	};
	const submit = async (e) => {
		e.preventDefault();
		const validationObject = validation();
		if (Object.keys(validationObject)?.length > 0) {
			setAdvertisementErrors(validationObject);
		} else {
			setLoader(true);
			if (validation()) {
				try {
					const formData = new FormData();
					formData.append("title", advertisement.banner_name);
					formData.append("link", advertisement.link);
					if (advertisement?.image) {
						formData.append("image", advertisement.image);
					}
					if (advertisement?.id) {
						formData.append("staticBannerId", advertisement?.id);
					}
					const data = await postTokenApi(HIBEE_ADMIN_URL + ADVERTISEMENT_CREATE_PATH, formData, { "Content-Type": "multipart/form-data" });
					if (data.status == 200) {
						setLoader(false)
						toast.success("Success..", {
							position: "top-center",
							autoClose: 2000,
						});
						navigate("/advertisment-list")
					}
				} catch (err) {
					navigate("/advertisment-list")
					setLoader(false)
					const message =
						err?.response?.data?.message || "Something went wrong";
					toast.error(message, { position: "top-center", autoClose: 2000 });
				}
			}

		}
	};

	useEffect(() => {
		if (state?.data?._id) {
			setAdvertisement({
				...advertisement,
				banner_name: state?.data?.title,
				link: state?.data?.link,
				editImage: state?.data?.Image,
				id: state?.data?._id
			}
			)
		}
	}, [])

	const handleClearState = () => {
		setAdvertisement({
			...advertisement,
			banner_name: "", link: "", image: "", editImage: "", id: ""
		}
		)
	}

	return (
		<MainTemplate categoryName="Static Banners" categoryList={["Homepage Modifier", "Static Banners"]}>
			<div className="row mb-4 rounded bg-white homepageModifierPadding">
				<form onSubmit={submit}>
					<div className="form-group py-2">
						<label>Banner Name <span className="text-danger" >*</span></label>
						<input
							type="text"
							className={`w-75 placeholder-light form-control py-2 px-3 ${advertisementErrors?.banner_name && 'border-danger'} `}
							value={advertisement.banner_name}
							name="banner_name"
							autoComplete="off"
							onChange={handleChange}
							placeholder="eg: New Launch"
						/>
						{advertisementErrors?.banner_name && <p className="text-danger">{advertisementErrors.banner_name}</p>}
					</div>

					<div className="form-group py-2  mb-4 position-relative">
						<label>Select Image  <span className="text-danger" >*</span></label>
						<input
							type="file"
							name="image"
							className="homepageModifierInput opacity0 position-absolute w-75 placeholder-light form-control py-2 px-3 "
							placeholder="eg: https://www.apple.com/in/watch"
							onChange={handleChange}
						// 
						/>

						<div className={`homepageModifierInput select-text-light d-flex align-items-center justify-content-center border rounded w-75 ${advertisementErrors?.image && "border-danger"}`}
						>
							{
								advertisement?.image || advertisement?.editImage ? (
									<div
										style={{
											width: "100%",
											height: "100%",
											objectFit: "cover",
											borderRadius: '5%'
										}}
									>
										{
											advertisement?.id && advertisement?.editImage
												?
												<img
													src={advertisement?.editImage ? advertisement?.editImage : ""}
													className="rounded h-100 w-100 shadow"
													style={{
														width: "100%",
														height: "100%",
														objectFit: "cover",
														borderRadius: '5%'
													}}
													alt="img"
												/>
												:
												<img
													src={advertisement?.image ? URL.createObjectURL(advertisement?.image) : ""}
													className="rounded h-100 w-100 shadow"
													style={{
														width: "100%",
														height: "200px",
														objectFit: "cover",
														borderRadius: '5%'
													}}
													alt="img"
												/>
										}
									</div>
								) : (
									<>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="16"
											height="16"
											fill="currentColor"
											class="bi bi-cloud-arrow-up"
											viewBox="0 0 16 16"
										>
											<path
												fill-rule="evenodd"
												d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z"
											/>
											<path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
										</svg>
										<span className="px-2">Upload Photo</span>
									</>
								)}
						</div>
						<span className="text-danger">{advertisementErrors?.image}</span>
					</div>

					<div className="form-group py-2">
						<label>Deep link  <span className="text-danger" >*</span></label>
						<input
							type="text"
							className={`w-75 placeholder-light form-control py-2 px-3 ${advertisementErrors?.link && 'border-danger'} `}
							value={advertisement.link}
							name="link"
							autoComplete="off"
							onChange={handleChange}
							placeholder="Https//: irfruiniru4i4rifminff% fkjrn fj"
						/>
						{advertisementErrors?.link && <p className="text-danger">{advertisementErrors.link}</p>}
					</div>

					<div className="form-group py-2">
						<button className="btn  text-warning px-5"
							style={{ backgroundColor: "#12352f" }}
						>Save {
								loader == true &&
								<Spinner
									animation="border"
									variant="light"
									size="sm"
								/>
							}</button>
					</div>
				</form>
			</div>
		</MainTemplate>
	);
}

export default HomepageModifierAdvertisement;