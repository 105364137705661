console.log(process.env.NODE_ENV, "hkjhjkhjkhkjhkjhkjh");

export const HIBEE_AUTH_URL = process.env.REACT_APP_HIBEE_AUTH_URL;
export const HIBEE_PRODUCT_URL = process.env.REACT_APP_HIBEE_PRODUCT_URL;
export const HIBEE_ADMIN_URL = process.env.REACT_APP_HIBEE_ADMIN_URL;
export const HIBEE_ORDER_URL = process.env.REACT_APP_HIBEE_ORDER_URL;
export const HIBEE_BULK_IMAGE = process.env.REACT_APP_HIBEE_BULK_IMAGE;
export const REACT_LOGIN_API_PATH = process.env.REACT_APP_REACT_LOGIN_API_PATH;
export const REACT_SEND_OTP_API_PATH = process.env.REACT_APP_REACT_SEND_OTP_API_PATH;
export const REACT_VERIFY_OTP_API_PATH = process.env.REACT_APP_REACT_VERIFY_OTP_API_PATH;
export const REACT_CHANGE_PASSWORD_API_PATH = process.env.REACT_APP_REACT_CHANGE_PASSWORD_API_PATH;
export const CATEGORY_PATH = process.env.REACT_APP_CATEGORY_PATH;
export const WITHOUT_IMAGE_LIST = process.env.REACT_APP_WITHOUT_IMAGE_LIST;
export const WITHOUT_SUBCATEGORY_IMAGE_LIST = process.env.REACT_APP_WITHOUT_SUBCATEGORY_IMAGE_LIST;
export const BULK_CATEGORY_PATH = process.env.REACT_APP_BULK_CATEGORY_PATH;
export const CATEGORY_IMAGE = process.env.REACT_APP_CATEGORY_IMAGE;
export const SUB_CATEGORY_IMAGE = process.env.REACT_APP_SUB_CATEGORY_IMAGE;
export const CATEGORY_SUB_PATH = process.env.REACT_APP_CATEGORY_SUB_PATH;
export const BULK_SUB_CATEGORY_PATH = process.env.REACT_APP_BULK_SUB_CATEGORY_PATH;
export const CATEGORY_HOMEPAGE = process.env.REACT_APP_CATEGORY_HOMEPAGE;
export const CATEGORY_SUB_HOMEPAGE = process.env.REACT_APP_CATEGORY_SUB_HOMEPAGE;
export const CATEGORY_SUB_LIST_PATH = process.env.REACT_APP_CATEGORY_SUB_LIST_PATH;
export const CATEGORY = process.env.REACT_APP_CATEGORY;
export const HOMEPAGE_SUB_VISIBILITY = process.env.REACT_APP_HOMEPAGE_SUB_VISIBILITY;
export const HOMEPAGE_SIDEBAR_NOTIFICATION = process.env.REACT_APP_HOMEPAGE_SIDEBAR_NOTIFICATION;
export const FRANCHISE_LIST = process.env.REACT_APP_FRANCHISE_LIST;
export const INVENTORY = process.env.REACT_APP_INVENTORY;
export const Nativity = process.env.REACT_APP_NATIVITY;
export const REACT_OFFERS_API_PATH = process.env.REACT_APP_REACT_OFFERS_API_PATH;
export const REACT_NOTIFICATIONS_PATH = process.env.REACT_APP_REACT_NOTIFICATIONS_PATH;
export const FRANCHISE_LIST_API_PATH = process.env.REACT_APP_FRANCHISE_LIST_API_PATH;
export const CREATE_FRANCHISE_API_PATH = process.env.REACT_APP_CREATE_FRANCHISE_API_PATH;
export const UPDATE_FRANCHISE_API_PATH = process.env.REACT_APP_UPDATE_FRANCHISE_API_PATH;
export const COD_AMOUNT = process.env.REACT_APP_COD_AMOUNT;
export const SOCIETY_LIST = process.env.REACT_APP_SOCIETY_LIST;
export const SOCIETY_TOWER_CREATION = process.env.REACT_APP_SOCIETY_TOWER_CREATION;
export const FLOORS_LIST = process.env.REACT_APP_FLOORS_LIST;
export const DELETE_TOWERS = process.env.REACT_APP_DELETE_TOWERS;
export const ADMIN = process.env.REACT_APP_ADMIN;
export const ADMIN_PATH = process.env.REACT_APP_ADMIN_PATH;
export const ADMIN_USER = process.env.REACT_APP_ADMIN_USER;
export const CHANGE_PASSWORD = process.env.REACT_APP_CHANGE_PASSWORD;
export const USER_UPDATE_STATUS = process.env.REACT_APP_USER_UPDATE_STATUS;
export const PRODUCT_PATH = process.env.REACT_APP_PRODUCT_PATH;
export const PRODUCT_SUBCATEGORY = process.env.REACT_APP_PRODUCT_SUBCATEGORY;
export const PRODUCT_ARCHIVE = process.env.REACT_APP_PRODUCT_ARCHIVE;
export const PRODUCT_WITHOUT_URL = process.env.REACT_APP_PRODUCT_WITHOUT_URL;
export const PRODUCT_IMAGE_UPDATE = process.env.REACT_APP_PRODUCT_IMAGE_UPDATE;
export const FRANCHISE_ORDER = process.env.REACT_APP_FRANCHISE_ORDER;
export const FRANCHISE_DELIVERY_STATUS = process.env.REACT_APP_FRANCHISE_DELIVERY_STATUS;
export const BANNER_PATH = process.env.REACT_APP_BANNER_PATH;
export const INVENTORY_DASHBOARD = process.env.REACT_APP_INVENTORY_DASHBOARD;
export const INVENTORY_LIST = process.env.REACT_APP_INVENTORY_LIST;
export const INVENTORY_ALL_LIST = process.env.REACT_APP_INVENTORY_ALL_LIST;
export const USER_LISTING = process.env.REACT_APP_USER_LISTING;
export const FEDBACK_GET_API = process.env.REACT_APP_FEDBACK_GET_API;
export const ADVERTISEMENT_CREATE_PATH = process.env.REACT_APP_ADVERTISEMENT_CREATE_PATH;
export const ADVERTISEMENT_LIST_PATH = process.env.REACT_APP_ADVERTISEMENT_LIST_PATH;
export const STATIC_BANNER_STATUS = process.env.REACT_APP_STATIC_BANNER_STATUS;
export const HOMEPAGE_SECTION = process.env.REACT_APP_HOMEPAGE_SECTION;
export const HOMEPAGE_VISIBILITY = process.env.REACT_APP_HOMEPAGE_VISIBILITY;
export const HOME_CAROUSEL = process.env.REACT_APP_HOME_CAROUSEL;
export const HOME_PRODUCT = process.env.REACT_APP_HOME_PRODUCT;
export const HOME_GROUP = process.env.REACT_APP_HOME_GROUP;
export const GROUPS_LIST = process.env.REACT_APP_GROUPS_LIST;
export const HOME_CAROUSEL_BANNER_VISIBILITY = process.env.REACT_APP_HOME_CAROUSEL_BANNER_VISIBILITY;
