import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { toast } from "react-toastify";
import BulkCategoryCsv from "../../assets/BulkCategory.csv";
import Papa from "papaparse";
import excell from "../../assets/images/excell.jpg";
import axios from "axios";
import '../UploadImageList/UploadImageList.css'
import { BsUpload } from "react-icons/bs";
import Row from "react-bootstrap/Row";

import placeholder from "../../assets/images/placeholder.png";
import { deleteTokenApi, getTokenApi, postTokenApi, getApi, putTokenApi } from "../../api_interface/apis";
import { BULK_CATEGORY_PATH, CATEGORY_PATH, HIBEE_PRODUCT_URL, HIBEE_BULK_IMAGE, WITHOUT_IMAGE_LIST, CATEGORY_IMAGE, PRODUCT_PATH, PRODUCT_WITHOUT_URL } from "../../api_interface/apiURLs";
import { customActionModalStyles, custombulkModalStyles, customModalStyles, debounce } from "../../utils/helperFunctin";
import MainTemplate from "../../components/MainTemplate";
import { Spinner } from "react-bootstrap";
const allowedExtensions = ["csv"];

function ProductImageUpload() {
    const inputRef = useRef(null)
    const [itemOffset, setItemOffset] = useState(0);
    const [modal, setModal] = useState(false);
    const [modal1, setModal1] = useState(false);
    const reader = new FileReader();
    const [actionModal, setActionModal] = useState({
        loader: false,
        show: false,
        type: "",
        data: {},
    });
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState({ name: "", image: "", discount: "", commission: "", imageFile: "", imagePreview: "" });
    const [categoryError, setcategoryError] = useState({ name: "", image: "", discount: "", commission: "", imageFile: "" });
    const [error, setError] = useState({});
    const [loader, setLoader] = useState(false);
    const [sort, setSort] = useState("");
    const [query, setQuery] = useState("");
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [bulkCategories, setBulkCategories] = useState({
        bulkData: [],
        show: false,
        error: "",
    });
    const [imageData, setImageData] = useState({
        image: '',
        id: '',
        loader: false
    });

    const [aprove, setAprove] = useState({
        status: "",
        index: ""
    })
    const [updateType, setUpdateType] = useState()

    const endOffset = itemOffset + itemsPerPage;
    const pageCount = Math.ceil(categories.length / itemsPerPage);
    const currentItems = categories.slice(itemOffset, endOffset);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % categories.length;
        setItemOffset(newOffset);
    };

    const getproducts = async (search) => {
        setLoader(true);
        const res = await getTokenApi(HIBEE_PRODUCT_URL + PRODUCT_PATH + PRODUCT_WITHOUT_URL + `?sort=${sort}&search=${search || query || ""}&status=${updateType ? updateType : ''}`);
        if (res.status == 200) {
            setCategories(res?.data?.data);
            setLoader(false);
        }
    };

    const validation = () => {
        const { name, image, discount, commission, imageFile, _id } = category;
        let error = {};
        if (!name) {
            error.name = "Name Required";
        }
        // if (!discount) {
        //   error.discount = "Discount Required";
        // }
        // if (!commission) {
        //   error.commission = "Commission Required";
        // }

        if (!(image || imageFile)) {
            error.image = "Image Required";
        }
        // if (Object.keys(error)?.length > 0) {
        //   setError(error);
        //   return false;
        // } else {
        //   setError({});
        //   return true;
        // }
        return error;
    };


    const closeCategoryModal = () => {
        setModal(false);
        setCategory({});
        setLoader(false);
        // setError({});
        hendleCrearCategory();
    };

    const updateSort = (e) => {
        setSort(e.target.value);
    };

    const getSearchedData = useMemo(
        () =>
            debounce(async (search) => {
                getproducts(search);
            }, 500),
        []
    );

    useEffect(() => {
        getproducts();
    }, [sort]);

    useEffect(() => {
        getproducts();
    }, [updateType]);

    useEffect(() => {
        getSearchedData(query);
    }, [query]);

    useEffect(() => {
        if (Object.keys(error)?.length) {
            validation();
        }
    }, [error]);


    const hendleCrearCategory = () => {
        setcategoryError({
            ...categoryError,
            commission: "",
            discount: "",
            image: "",
            name: "",
        });
    };

    useEffect(() => {
        if (bulkCategories.bulkData.length != 0) {
            // setError({})
            hendleCrearCategory();
        }
    }, [bulkCategories]);

    const [productImage, setProductImage] = useState([]);
    console.log(productImage, "productImage");

    const handleSubmitImage = async () => {
        // Check if there are no images selected
        if (productImage.length === 0) {
            toast.error("Please Add at least one image.", {
                position: "top-center",
                autoClose: 2000,
            });
            return;  // Stop further execution
        }

        const formData = new FormData();
        productImage.forEach((image, index) => {
            formData.append(`images`, image, image.name); // Append the image as a file with its original name
        });
        formData.append('_id', imageData.id._id);

        try {
            setImageData({ ...imageData, loader: true });
            const res = await putTokenApi(HIBEE_PRODUCT_URL + PRODUCT_PATH, formData, { "Content-Type": "multipart/form-data" });
            if (res.status === 200) {
                toast.success(res?.data?.message, {
                    position: "top-center",
                    autoClose: 2000,
                });
                setModal(false);
                setProductImage([]); // Clear the selected images after successful submission
                setImageData({ ...imageData, image: "", loader: false });
                getproducts();
            }
        } catch (err) {
            toast.error(err?.response?.data?.message, {
                position: "top-center",
                autoClose: 2000,
            });
            setModal(false);
            getproducts();
            setImageData({ ...imageData, image: "", loader: false });
        }
    };



    const handleUpload = () => {
        document.getElementById('upload-image').click()
    }

    useEffect(() => {
        getproducts()
    }, [])

    const handleInventorychange = (event, type) => {
        const files = Array.from(event.target.files);
        const urls = files.map((file) => URL.createObjectURL(file));
        setProductImage([...productImage, ...files]); // Remove the array wrapping
    };

    const handleImageDelete = (index) => {
        const newImages = [...productImage];
        newImages.splice(index, 1);
        setProductImage(newImages);
    
        // Simulate a click on the file input to trigger the file selection dialog
        const fileInput = inputRef.current;
        if (fileInput) {
            fileInput.value = ''; // Clear the value to allow re-uploading the same image
        }
    };
    





    return (
        <MainTemplate
            categoryName="Product Image Upload"
            categoryList={["Product", "Product Image Upload"]}
        >
            <div className="row mb-4 rounded p-2 bg-white">
                <div className="col-sm-6 my-3 col-md-2">
                    <select
                        className="t-12 form-select"
                        aria-label="Default select example"
                        onChange={updateSort}
                    >
                        <option value="" selected>
                            Sort by
                        </option>
                        <option value="name">Alphabatically</option>
                        <option value="dateold">Date: Acsending</option>
                        <option value="datenew">Date: Descending</option>
                    </select>
                </div>
                <div className="col-sm-6 my-3 col-md-2">
                    <select
                        className="t-12 form-select"
                        aria-label="Default select example"
                        onChange={(e) => setItemsPerPage(e.target.value)}
                    >
                        <option value="10" selected>
                            10 per page
                        </option>
                        <option value="20">20 per page</option>
                        <option value="50">50 per page</option>
                        <option value="100">100 per page</option>
                    </select>
                </div>
                <div className="col-sm-6 my-3 col-md-3">
                    <input
                        type="text"
                        name="query"
                        className="t-12 form-control"
                        placeholder="Search"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                    />
                </div>
                <div className="d-none d-md-block col-md-1"></div>
                {/* <div className="col-sm-6 my-3 col-md-2">
                    <button
                        type="button"
                        className="t-12 w-100 btn btn-outline-dark"
                        onClick={() => {
                            setModal(true);
                            setCategory({});
                        }}
                    >
                        Add Category
                    </button>
                </div> */}
                {/* <div className="col-sm-6 my-3 col-md-2">
          <button
            type="button"
            className="t-12 w-100 btn btn-outline-dark"
            onClick={() => {
              setModal1(true);
              setcategory({});
            }}
          >
            Add Images
          </button>
        </div> */}
            </div>

            <div className="row rounded px-3 bg-white">
                {/* header */}
                <div className="row m-0 ">
                    {/* <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-1">
                        Photo
                    </div> */}
                    <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-3">
                        Product Name
                    </div>
                    <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-3">
                        Discount
                    </div>
                    <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-3">
                        Commission
                    </div>
                    <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-3">
                        Updates
                    </div>
                    {/* <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-3">
                        Sub category count
                    </div>
                    <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-1">
                        Edit
                    </div>
                    <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-1">
                        Active{" "}/{" "}Inactive
                    </div> */}
                </div>

                {/* body */}
                <div className="m-0 p-0 pe-3 overflow-auto caterogyPageScroll">
                    {currentItems.length > 0 ? currentItems?.map((el) => (
                        <div className="row border rounded m-0 px-2  mb-2 d-flex align-items-center" key={el?._id} >
                            {/* <div className="t-12 text-uppercase my-3 col-md-1">
                                <div className="categoryImageDiv shadow-sm  rounded p-1">
                                    <Link
                                        className="text-dark"
                                        to={`/category/subcategory/${el?._id}`}
                                        style={{ textDecoration: "none" }}
                                    >
                                        <img
                                            src={el?.image}
                                            className="categoryImage h-100 rounded"
                                        />
                                    </Link>
                                </div>
                            </div> */}

                            <div className="t-12 text-uppercase my-3 col-md-3">
                                <Link
                                    className="text-dark"
                                    to={`/category/subcategory/${el?._id}`}
                                    style={{ textDecoration: "none" }}
                                >
                                    {el?.product_name}
                                </Link>
                            </div>
                            <div className="t-12 text-uppercase my-3 col-md-3">
                                {el?.discount}
                            </div>
                            <div className="t-12 text-uppercase my-3 col-md-3">
                                {el?.commission}
                            </div>
                            <div className="t-12 text-uppercase my-3 col-md-3">
                                <div>
                                    <button className="t-12 w-100 btn btn-unupload-green-color" onClick={() => { setImageData({ ...imageData, id: el }); setModal(true) }}>Upload image</button>
                                    {/* <button className="t-12 w-100 btn btn-upload-green-color">Upload image</button> */}
                                </div>
                            </div>
                        </div>
                    )) : <div>
                        No Product without Image
                    </div>}

                    {loader == true && (
                        <div className="text-center mt-5">
                            <button
                                class="btn mt-5"
                                style={{ backgroundColor: "#FFEDC2" }}
                                type="button"
                            >
                                <span
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>{" "}
                                Loading...
                            </button>
                        </div>
                    )}
                </div>
            </div>

            <div className="row m-2 d-flex justify-content-center align-items-center">
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    pageCount={pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    pageClassName="page-item mb-0"
                    subContainerClassName="pages "
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    marginPagesDisplayed={2}
                    containerClassName="pagination"
                    activeClassName="active"
                />
            </div>
            {/* Catgory model */}
            <Modal
                isOpen={modal}
                onRequestClose={closeCategoryModal}
                style={custombulkModalStyles}
                contentLabel="Example Modal"
                ariaHideApp={false}
            >
                <div>
                    <div className="d-flex justify-content-between">
                        <div>
                            <p className="bulkuploadmodelheader">
                                {imageData?.id?.name}
                            </p>
                            <p className="bulkuploadmodelsubheader">
                                Upload a file
                            </p>
                        </div>
                        <p className="bulkuploadchoosefile">Browse files</p>
                    </div>
                    <div className="py-2">
                        <label>Product Image</label>
                        <Row>
                            <div className="form-group py-2 position-relative">
                                <input
                                    type="file"
                                    name="image"
                                    multiple
                                    onChange={(e) => handleInventorychange(e, "image")}
                                    className="opacity0 position-absolute placeholder-light form-control py-2 w-100 h-100  px-3"
                                />
                                <div
                                    className={`d-flex align-items-center justify-content-center flex-column border rounded text-center }`}
                                    style={{ height: "200px" }}
                                >
                                    <div>
                                        <img src={placeholder} alt="" />
                                        <span style={{ color: "#A3A3A3" }}>Upload Photos</span>
                                    </div>
                                </div>
                            </div>
                        </Row>
                        <Row>
                            <div className="mb-3 g-0 d-flex justify-content-center position-relative align-items-center">
                                <input
                                    ref={inputRef}
                                    type="file"
                                    name="image"
                                    multiple
                                    className="opacity0 position-absolute placeholder-light form-control py-2 w-100 h-100 px-3"
                                />

                            </div>
                            <div>
                                <div className="card overflow-auto multiple-image-height">
                                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                                        {productImage.map((file, index) => (
                                            <div key={index} style={{ margin: "10px" }}>
                                                <img
                                                    src={URL.createObjectURL(file)}
                                                    alt={`Product image ${index}`}
                                                    className="rounded shadow image"
                                                    style={{
                                                        width: "100px",
                                                        height: "100px",
                                                        objectFit: "cover",
                                                    }}
                                                />
                                                <div
                                                    className="image-container"
                                                    onClick={() => handleImageDelete(index)}
                                                ></div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </div>


                    <div className="d-flex justify-content-center align-items-center m-4">
                        <div>
                            <button type="button" class="btn-unupload-green-color ms-2" onClick={handleSubmitImage}>
                                Submit{" "}
                                {
                                    imageData?.loader
                                        ?
                                        <Spinner
                                            animation="border"
                                            variant="light"
                                            size="sm"
                                        />
                                        :
                                        ''
                                }
                            </button>
                        </div>
                        <div style={{ marginLeft: "15px" }} >
                            <button type="button" onClick={() => { setModal(false); setImageData({ ...imageData, image: '', id: '', loader: false }) }} class="bulkuploadbutton-cancel">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </MainTemplate >
    );
}

export default ProductImageUpload;
