import React, { useEffect, useState, useMemo } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import MainTemplate from "../../components/MainTemplate";
import { AiOutlineEdit } from 'react-icons/ai'

function HomepageModifierNativity() {

    return (
        <MainTemplate
            categoryName="Nativity" categoryList={["Homepage Modifier", " Nativity Banner"]}
        >

            <div className="row rounded px-3 bg-white py-2">
                <div>
                    <div>
                        <p className="homepagemodifieraddedproductslable p-0 m-0 py-1">
                            Link (Not editable)
                        </p>
                        <input
                            type="text"
                            className="homepagemodifieraddedproductsinput "
                            placeholder="https;/fkjnkjrfjkjkknknee940nfrrr%fnfikjn593nfj"
                        />
                    </div>

                    <div className="mt-3" style={{ width: "579px" }}>
                        <div className="d-flex justify-content-between">
                            <p className="homepagemodifieraddedproductslable p-1 m-0 py-2">
                                Banner
                            </p>
                            <AiOutlineEdit className="text-xl" />
                        </div>

                        <div className="homepagemodifiernativitybannerinput px-2 py-4">
                            <input
                                type="file"
                                className=""
                                style={{ opacity: "0", width: "100%", height: "100%" }}
                            />
                        </div>
                        <div className="d-flex gap-4 mt-4">
                            <Link to='/homepagemodifieraddedproducts' type="reset"
                                // onClick={handleCleare}
                                // style={{ marginLeft: '10px' }}
                                className="btn btn-primary button-green px-5">
                                Add Banner
                            </Link>

                        </div>
                    </div>
                </div>

            </div>

        </MainTemplate>
    );
}

export default HomepageModifierNativity;
