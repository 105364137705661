import React, { useEffect, useState } from 'react'
import MainTemplate from '../../components/MainTemplate'
import { HIBEE_ORDER_URL } from '../../api_interface/apiURLs'
import { getTokenApi, patchTokenApi, postTokenApi } from '../../api_interface/apis'
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import moment from 'moment';
import '../../pages/ReturnOrder/returnorder.css'


const Refundorder = () => {
    const [query, setQuery] = useState("")
    const [returndata, SetReturndata] = useState("")
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [filterStatus, setFilterStatus] = useState(false);
    const [selectedValues, setSelectedValues] = useState("");
    const [activeTab, setActiveTab] = useState("requested");
    const [showTextarea, setShowTextarea] = useState(false);
    const [rejectedreason,SetRejectedreason] = useState()







    const data = async () => {
        try {
            const res = await getTokenApi(
                `${HIBEE_ORDER_URL}/order/refundRequestList?status=${activeTab}`)
            SetReturndata(res?.data.data)
        } catch (error) {
            console.log(error);
        }
    }
    console.log(returndata, "sfhdifhsdfigyh")

    const handleStatusChange = async () => {
        try {
            if (selectedStatus === 'rejected_superadmin') {
                setShowTextarea(true);
            }
            if (!selectedOrder || !selectedStatus) {
                console.error("Order ID or status is missing.");
                return;
            }


            const response = await patchTokenApi(
                HIBEE_ORDER_URL + "/order/refundRequestStatusChange",
                {
                    _id: selectedOrder,
                    status: selectedStatus,
                    rejected_reason:rejectedreason
                }
            );

            if (response.status === 200) {
                closeModal();
                data();
            } else {
                console.error("Unexpected response status:", response.status);
            }
        } catch (error) {
            console.error("Error updating status:", error);
        }
    };
    const openModal = (orderId) => {
        setSelectedOrder(orderId);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setSelectedOrder(null);
        setSelectedStatus('');
        setModalIsOpen(false);
    };
    const updateStatus = (event) => {
        const selectedValue = event.target.value;
        const isChecked = event.target.checked;

        if (isChecked) {
            setSelectedValues((prevValues) =>
                prevValues ? `${prevValues},${selectedValue}` : selectedValue
            );
        } else {
            setSelectedValues((prevValues) =>
                prevValues
                    .split(",")
                    .filter((value) => value !== selectedValue)
                    .join(",")
            );
        }
    };
    const handleOkClick = () => {
        setFilterStatus(false);
    };

    useEffect(() => {
        data();
    }, [selectedValues, activeTab]);
    return (
        <MainTemplate
            categoryName="Return Requested List"
            categoryList={["Return Requested List"]}>
            <>
                <div className="row mb-4 rounded p-2 bg-white">
                    <div className="col-sm-6 my-3 col-md-2">

                    </div>
                    <div className="col-sm-6 my-3 col-md-2">

                    </div>

                    <div className="my-3 col-md-3">
                        <input type="text" autoComplete="off" name="query" className="t-12 form-control" placeholder="Search" value={query} onChange={e => setQuery(e.target.value)} />
                    </div>
                    <div className="tabs">
                        <div
                            className={`tab ${activeTab === "requested" ? "active-tab" : ""}`}
                            onClick={() => setActiveTab("requested")}
                        >
                            Requested
                        </div>
                        <div
                            className={`tab ${activeTab === "initiated" ? "active-tab" : ""
                                }`}
                            onClick={() => setActiveTab("initiated")}
                        >
                            Initiated
                        </div>
                        <div
                            className={`tab ${activeTab === "rejected_superadmin" ? "active-tab" : ""
                                }`}
                            onClick={() => setActiveTab("rejected_superadmin")}
                        >
                            Rejected
                        </div>
                    </div>

                </div>

                <div className="row flex-column align-items-center">
                    <div className="row rounded text-center bg-white">
                        {/* header */}
                        <div className="row m-0 ">
                            <div className="font-weight-bold t-12 text-uppercase  p-0 my-3 px-3 col-md-1">
                                OrderID
                            </div>
                            <div className="font-weight-bold t-12 text-uppercase   p-0 my-3 px-3 col-md-2">
                                No. of Product              </div>
                            <div className="font-weight-bold t-12 text-uppercase   p-0 my-3 px-3 col-md-2">
                                Images         </div>
                            <div className="font-weight-bold t-12 text-uppercase  p-0 my-3 px-3 col-md-2">
                                Reason
                            </div>
                            <div className="font-weight-bold t-12 text-uppercase   p-0 my-3 px-3 col-md-2">
                                DATE
                            </div>
                            <div onClick={() => setFilterStatus(!filterStatus)}
                                className="font-weight-bold t-12 text-uppercase   p-0 my-3 px-3 col-md-1">
                                Status

                            </div>

                        </div>

                        {/* body */}
                        <div className="justify-content-center align-items-center overflow-auto caterogyPageScroll">
                            {Array.isArray(returndata) ? (
                                returndata?.map((el) => (

                                    <div
                                        className="row border rounded m-0 mb-2 d-flex align-items-center"
                                        key={el?._id}
                                    >
                                        <div className="t-12 text-uppercase my-3 col-md-1">
                                            <Link className="orderidlink" to={`/ReturnDetails/${el?._id}`}>
                                                {el?._id.slice(0, 5)}
                                            </Link>
                                        </div>
                                        <div className="t-12 text-uppercase my-4 col-md-2">
                                            {el?.products.length}
                                        </div>
                                        <div className="t-12 text-uppercase my-4 col-md-2">
                                            {el?.images.map((imageObj, index) => (
                                                <img
                                                    key={index}
                                                    src={imageObj?.image}
                                                    alt={`Image ${index + 1}`}
                                                    className="m-1"
                                                    style={{ maxWidth: '50px', maxHeight: '50px' }}
                                                />
                                            ))}
                                        </div>
                                        <div className="t-12 text-uppercase my-2 col-md-2">
                                            {el?.refund_reason}
                                        </div>
                                        <div className="t-12 text-uppercase my-2 col-md-2">
                                            {moment(el?.createdAt).format("D MMM, YYYY")}
                                        </div>
                                        <div
                                            className="t-12 text-uppercase my-2 col-md-1 text-white"
                                            onClick={() => openModal(el?._id)}
                                            style={{
                                                backgroundColor:
                                                    el?.status === 'requested'
                                                        ? '#febf22'
                                                        : el?.status === 'initiated'
                                                            ? '#29ae13'
                                                            : el?.status === 'rejected'
                                                                ? 'red'
                                                                : 'transparent',
                                                padding: "5px",
                                                borderRadius: "5px",
                                                cursor: "pointer"

                                            }}
                                        >
                                            {el?.status}
                                        </div>

                                    </div>

                                ))) : (
                                <div>Loading...</div>
                            )}
                            <Modal
                                isOpen={modalIsOpen}
                                onRequestClose={closeModal}
                                contentLabel="Change Status Modal"
                                style={{
                                    overlay: {
                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    },
                                    content: {
                                        width: '500px',
                                        height: '400px',
                                        margin: 'auto',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                    },
                                }}
                            >
                                <h2 className='fs-2 mb-4 text-center'>Change Status</h2>
                                <label className='fs-4 text-center' htmlFor="statusDropdown ">Select Status</label>
                                <select
                                    className='p-2 m-4'
                                    style={{ borderRadius: "5px" }}
                                    id="statusDropdown"
                                    value={selectedStatus}
                                    onChange={(e) => setSelectedStatus(e.target.value)}
                                >
                                    <option value="">Select status</option>
                                    <option value="initiated">Initiated</option>
                                    <option value="rejected_superadmin">Rejected</option>
                                    
                                </select>
                                {selectedStatus == "rejected_superadmin" && (
                                        <textarea 
                                            className=''
                                            style={{ height: "100px", width: "300px", border: "4px solid black" }}
                                            placeholder='Enter rejection reason...'
                                        onChange={(e)=>{SetRejectedreason(e.target.value)}}
                                        />
                                    )}
                                <button style={{ marginTop: "20px", width: "300px", alignSelf: "center", backgroundColor: "black", color: "yellow", borderRadius: "5px", padding: "5px" }} onClick={handleStatusChange} disabled={selectedStatus == "rejected_superadmin" && rejectedreason== null}>Submit</button>
                                <button style={{ marginTop: "20px", width: "300px", alignSelf: "center", backgroundColor: "black", color: "yellow", borderRadius: "5px", padding: "5px" }} onClick={closeModal}>Close</button>
                            </Modal>

                        </div>
                    </div>
                </div>



            </>



        </MainTemplate>

    )
}

export default Refundorder