import { createSlice } from '@reduxjs/toolkit'

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: {},
  reducers: {
    addCategory: (state, action) => {
        return action?.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { addCategory } = sidebarSlice.actions

export default sidebarSlice.reducer