import React, { useState, useEffect, useMemo } from "react";
import MainTemplate from "../../components/MainTemplate";
import "../Society/societylisting.css";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { Link, useLocation } from "react-router-dom";
import { customActionModalStyles, customModalStyles, debounce } from "../../utils/helperFunctin";
import { FRANCHISE_LIST_API_PATH, HIBEE_PRODUCT_URL, SOCIETY_LIST, GROUPS_LIST } from "../../api_interface/apiURLs";
import { deleteTokenApi, getApi, getTokenApi, postTokenApi, putTokenApi } from "../../api_interface/apis";


export default function HomepageModiferGroupsList() {
    const location = useLocation()
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [itemOffset, setItemOffset] = useState(0);
    const [societyList, setSocietyList] = useState([])
    const [updateType, setUpdateType] = useState(true)
    const [aprove, setAprove] = useState({
        status: "",
        index: ""
    })
    const [query, setQuery] = useState("");
    const [sort, setSort] = useState("");


    const endOffset = itemOffset + itemsPerPage;
    const pageCount = Math.ceil(societyList.length / itemsPerPage);
    const currentItems = societyList.slice(itemOffset, endOffset);
    const [loader, setLoader] = useState(false);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % societyList.length;
        setItemOffset(newOffset);
    };


    const approveAdmin = async (ele, id) => {
        setAprove({ ...aprove, status: ele, index: id })
        // Path Params
        const adminId = id
        const status = ele
        try {
            const res = await putTokenApi(HIBEE_PRODUCT_URL + SOCIETY_LIST + `/ ${adminId} / ${status}`)
            if (res.status == 200) {
                if (res?.data?.status == 'approved') {
                    toast.success(res?.data?.message, {
                        position: "top-center",
                        autoClose: 2000,
                    });
                }
                if (res?.data?.status == 'disapproved') {
                    toast.error(res?.data?.message, {
                        position: "top-center",
                        autoClose: 2000,
                    });
                }
                handleGetGroupsLists()
            }
        } catch (err) {
            toast.error(err?.response?.data?.message, {
                position: "top-center",
                autoClose: 2000,
            })
            handleGetGroupsLists()
        }
    }

    /** Groups Listing  API **/
    const handleGetGroupsLists = async (search, updateType, sort) => {
        setLoader(true);
        // await getTokenApi(HIBEE_PRODUCT_URL + SOCIETY_LIST + `?sort=${sort}&search=${search || query || ""}&status=${updateType}`)
        await getTokenApi(HIBEE_PRODUCT_URL + GROUPS_LIST + `?sort=${sort}&search=${search || query || ""}&status=${updateType}`)
            .then((ele, ind) => {
                if (ele.status == 200) {
                    const data = ele.data.data
                    const revData = data.reverse()
                    setSocietyList(revData);
                    setLoader(false);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const updateSort = (e) => {
        setSort(e.target.value);
    };

    const getSearchedData = useMemo(() => debounce(async (search, updateType, sort) => {
        handleGetGroupsLists(search, updateType, sort);
    }, 500), []);

    useEffect(() => {
        getSearchedData(query, updateType, sort);
    }, [query, updateType, sort]);


    // useEffect(() => {
    //     handleGetGroupsLists()
    // }, [])


    return (
        <MainTemplate
            categoryName="Groups Listing"
            categoryList={["Homepage Modifier", "Groups"]}
        >
            <div className="row mb-4 rounded p-2 bg-white">
                <div className="col-sm-6 my-3 col-md-2">
                    <select
                        className="t-12 form-select"
                        aria-label="Default select example"
                        onChange={updateSort}
                    >
                        <option value="" selected>
                            Sort by
                        </option>
                        <option value="name">Alphabatically</option>
                        <option value="dateold">Date: Acsending</option>
                        <option value="datenew">Date: Descending</option>+
                    </select>
                </div>
                <div className="col-sm-6 my-3 col-md-2">
                    <select
                        className="t-12 form-select"
                        aria-label="Default select example"
                    // onChange={(e) => setItemsPerPage(e.target.value)}
                    >
                        <option value="10" selected>
                            10 per page
                        </option>
                        <option value="20">20 per page</option>
                        <option value="50">50 per page</option>
                        <option value="100">100 per page</option>
                    </select>
                </div>
                {/* <div className="col-sm-2">
                        <select className="t-12 form-select" aria-label="Default select example"
                            onChange={e => setUpdateType(e.target.value === "true" ? true : false)}
                        >
                            <option value={true} selected>Active</option>
                            <option value={false}>Inactive</option>
                        </select>
                    </div> */}
                <div className="col-sm-6 my-3 col-md-2">
                    <input
                        type="text"
                        name="query"
                        className="t-12 form-control"
                        placeholder="Search"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        autoComplete='off'
                    />
                </div>
                <div className="col-sm-6 my-3 col-md-6 row justify-content-md-center">
                    <div className="col-3" >
                        <Link
                            // to="/homepage-modifier/product-list"
                            className="t-12 w-100 btn text-warning"
                            // to="/homepage-modifier/add/product"
                            // state={{ page: 'GROUP' }}
                            // to='/homepage-modifier/product-list'
                            to='/Homepage-modifier-group-products-list'
                            style={{ backgroundColor: "#12352f" }}
                        >
                            New Group
                        </Link>
                    </div>
                </div>
            </div>

            {/* table */}
            <div className="row flex-column align-items-center">
                <div className="row rounded text-center bg-white">
                    {/* header */}
                    <div className="row m-0 ">
                        <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
                            Title
                        </div>
                        <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-3">
                            Description
                        </div>
                        <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-3">
                            Generated Link
                        </div>
                        <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
                            Product count
                        </div>
                        <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
                            Edit
                        </div>
                        {/* <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
                            Active / Inactive
                        </div> */}
                    </div>

                    {/* body */}
                    <div className="justify-content-center align-items-center overflow-auto caterogyPageScroll">
                        {loader == false && currentItems.length != 0 ?
                            currentItems.map((ele, ind) => {
                                return (
                                    <div
                                        className="row border rounded m-0 mb-2 d-flex align-items-center"
                                    >
                                        <div className="t-12 text-uppercase my-3 col-md-2">
                                            <Link
                                                // to='/homepage-modifier/product-list'
                                                className="text-dark"
                                                style={{ textDecoration: "none" }}
                                            >
                                                {ele?.title}
                                            </Link>
                                        </div>
                                        <div className="t-12 text-uppercase my-3 col-md-3">
                                            {ele?.description ? ele?.description : "null"}
                                        </div>
                                        <div className="t-12  my-3 col-md-3">
                                            {ele?._id ? ele?._id : ""}
                                        </div>
                                        <div className="t-12 text-uppercase my-3 col-md-2">{ele?.productCount}</div>
                                        <div className="t-12 text-uppercase my-3 col-md-2">
                                            <Link
                                                to='/Homepage-modifier-group-products-list'
                                                state={{ page: 'PRODUCTLIST', eidtData: ele }}
                                            >

                                                <svg
                                                    className="bi bi-pencil border-bottom border-dark"
                                                    // onClick={() => {
                                                    //     setModal(true);
                                                    //     setCategory(el);
                                                    // }}
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="currentColor"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                                </svg>
                                            </Link>
                                        </div>
                                        {/* <div className="t-12 text-uppercase my-3 col-md-2">
                                        <label className="switch">
                                            <input className="switch-input" type="checkbox"
                                                checked={ele.activated}
                                            onChange={e => e.target.checked ? approveAdmin(e.target.checked, ele?._id) : approveAdmin(e.target.checked, ele?._id)}

                                            />
                                            <span className="switch-label"></span>
                                            <span className="switch-handle"></span>
                                        </label>
                                    </div> */}
                                        {/* <div className="t-12 text-uppercase my-3 col-md-2">
                                        <Link to="" className="t-12 text-uppercase my-3 col-md-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots" viewBox="0 0 16 16">
                                                <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                                            </svg>
                                        </Link>
                                    </div> */}
                                    </div>
                                )
                            }) :
                            <div className="text-center p-5 homepageModifierCategoryScroll">
                                <div className="" style={{ marginTop: "150px" }} >
                                    <b className="empty-product-list" >Groups are not available</b>
                                </div>
                            </div>
                        }

                        {loader == true && (
                            <div className="text-center mt-5">
                                <button
                                    class="btn mt-5"
                                    style={{ backgroundColor: "#FFEDC2" }}
                                    type="button"
                                >
                                    <span
                                        class="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>{" "}
                                    Loading...
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="row m-2 d-flex justify-content-center align-items-center">
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    pageCount={pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    pageClassName="page-item mb-0"
                    subContainerClassName="pages "
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    marginPagesDisplayed={2}
                    containerClassName="pagination"
                    activeClassName="active"
                />
            </div>
        </MainTemplate >
    );
}
