
import Star from "./Star";
function StarRating({ rating }) {
    const maxRating = 5;
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating - fullStars >= 0.5;
    const emptyStars = maxRating - fullStars - (hasHalfStar ? 1 : 0);

    return (
        <div>
            {[...Array(fullStars)].map((_, i) => (
                <Star key={i} filled />
            ))}
            {hasHalfStar && <Star filled={false} />}
            {[...Array(emptyStars)].map((_, i) => (
                <Star key={i + fullStars + 1} filled={false} />
            ))}
        </div>
    );
}
export default StarRating