import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import {
    HIBEE_ADMIN_URL,
    REACT_NOTIFICATIONS_PATH,
} from "../api_interface/apiURLs";
import { getTokenApi, patchTokenApi } from "../api_interface/apis";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useWebSocket from 'react-use-websocket';


const Breadcrumb = ({ name, list }) => {
    const [notification, showNotification] = useState(false);
    const [notificationTab, setNotificationTab] = useState("Orders");
    const token = localStorage.getItem("x-access-token")
    const [orders, setOrders] = useState([]);
    const [inventory, setInventory] = useState([]);
    const [loader, setLoader] = useState(false);
    const [newOrderCount, setNewOrderCount] = useState(0);
    const [newInventoryCount, setNewInventoryCount] = useState(0);


    const [isPaused, setPause] = useState(false);
    const [count, setcount] = useState()

    const ws = useRef(null);
    const [notifications, setNotifications] = useState([]);
    useEffect(() => {
        ws.current = new WebSocket("wss://hibee-adminapi.moshimoshi.cloud/admin/notification/list", token)
        ws.current.onopen = () => console.log("ws opened");
        ws.current.onclose = () => console.log("ws closed");
        const wsCurrent = ws.current;
        console.log(wsCurrent, "wsCurrentwsCurrentwsCurrentwsCurrentwsCurrent")

        return () => {
            wsCurrent.close();
        };
    }, [])

    useEffect(() => {
        if (!ws.current) return;

        ws.current.onmessage = e => {
            if (isPaused) return;
            const message = JSON.parse(e.data);
            console.log(message, "{el?.order?.userDetails?.floor}{el?.order?.userDetails?.floor}{el?.order?.userDetails?.floor}")
            if (message?.notifications) {
                console.log("HEree as well")
                setOrders(message?.notifications)

            }
            else {
                console.log(orders, "ORdrezs")
                let data = orders.reverse()
                data.push(message?.notification)
                setOrders(data?.reverse())
                console.log("HErer", message?.notification)



            }
            setcount(message);
        };
    }, [isPaused]);

    

    // console.log([...orders].reverse())
    // useEffect(()=>{
    //     setOrders(count.notifications)
    // },[orders])
    // console.log(count.notifications,"setcountsetcountsetcount")

    // console.log(ordercount,"asdijbfhisd")
    // const getData = async (search) => {
    //         const token = localStorage.getItem("x-access-token")
    //         console.log(typeof token,"tokentokentokentokentoken")

    //     try {
    //         // const protocol =  token; // actual token
    //         // Establish WebSocket connection with token in query parameter
    //         // const socket = new WebSocket('wss://hibee-order.moshimoshi.cloud/order/orderCount',{
    //         //     protocols: ["x-access-token", `${token}`]
    //         //   });
    //         //   console.log(socket)
    //         // Alternatively, if the server expects the token in the headers, you can set it like this:
    //         // const socket = new WebSocket('wss://hibee-order.moshimoshi.cloud/order/orderCount');
    //         // socket.setRequestHeader('x-access-token', `${token}`);


    //         // Listen for messages from the server
    //         socket.onmessage = function(event) {
    //             const res = JSON.parse(event.data);
    //             if (res.data) {
    //                 const datas = res.data;
    //                 // setApiData(datas);
    //                 console.log(datas, "datasdatasdatasdatasdatas");
    //             }
    //         };
    //         // Handle WebSocket errors
    //         socket.onerror = function(error) {
    //             console.error('WebSocket error:', error);
    //             // Optionally handle errors here
    //         };

    //         // Handle WebSocket close event
    //         socket.onclose = function(event) {
    //             console.log('WebSocket connection closed:', event);
    //             // Optionally handle close event
    //         };
    //     } catch (err) {
    //         console.error('Error:', err);
    //         // Handle error if necessary
    //     }
    // };
    // console.log(apiData,"apiDataapiDataapiDataapiDataapiData")
    // useEffect(() => {
    //     console.log(orderCountUpdate,"apiDataapiDataapiDataapiDataapiData")
    // }, [ordercount,orderCountUpdate]);
    // useEffect(() => {
    //     // Call getData initially
    //     getData();
    // }, []); // Empty dependency array ensures this effect runs only once, on component mount

    // useEffect(() => {
    //     if (notification) {
    //         localStorage.setItem("oldorderscount", orders?.length.toString());
    //         localStorage.setItem("oldinventorycount", inventory?.length.toString());
    //     }

    // }, [notification])
    // const [showNotificationIcon, setshowNotificationIcon] = useState()
    // const user = useSelector((state) => state.userReducer);
    // useEffect(() => {
    //     const hasOrdersAccess = user?.access && user.access.includes("Orders");
    //     setshowNotificationIcon(hasOrdersAccess);
    // }, [user]);


    // console.log(showNotificationIcon, 'hkk');
    const customStyles = {
        content: {
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            position: "absolute",
            height: "100vh",
            background: "rgba(0,0,0,0.1)",
            outline: "none",
            border: "none",
        },
        overlay: {
            position: "absolute",
            heigth: "100vh",
            background: "rgba(0,0,0,0.6)",
            zIndex: "99999",
        },
    };

    return (
        <>
            <div className="row">
                <div className="col-10 p-0 mb-4">
                    <div>
                        <h5 className="mb-0 font-weight-bold">{name}</h5>
                        {list?.map((el, index) => (
                            <span className={`t-12 ${index + 1 === list?.length && "font-weight-bold"}`}>
                                {el}
                                {index + 1 !== list?.length && " > "}
                            </span>
                        ))}
                    </div>
                </div>
                <div className="col-2 d-flex justify-content-end position-relative" style={{ cursor: "pointer" }}>
                    {count?.notificationCount || count?.updatedCount != 0 ? <span className="badge badge-pill badge-warning position-absolute">
                        {count?.notificationCount || count?.updatedCount}
                    </span> : ""}
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        onClick={() => showNotification(!notification)}
                        height="16"
                        stroke="black"
                        stroke-width=".1"
                        fill="currentColor"
                        className="font-weight-bold bi bi-bell"

                        viewBox="0 0 16 16"
                    >
                        <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
                    </svg>
                </div>
            </div>

            <Modal
                isOpen={notification}
                onRequestClose={() => showNotification(false)}
                style={customStyles}
            >
                <div
                    className={`px-3 border-right position-absolute d-block bg-white rounded shadow-lg`}
                    style={{
                        width: "350px",
                        top: 0,
                        right: 0,
                        zIndex: "999",
                        height: "100vh",
                    }}
                >
                    <div className="my-4">
                        <div className="h4 d-flex justify-content-between align-items-center">
                            <span>Notifications</span>
                            <svg
                                onClick={() => showNotification(false)}
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-x-lg"
                                viewBox="0 0 16 16"
                            >
                                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                            </svg>
                        </div>
                    </div>

                    <div className="my-4">
                        <div className="row d-flex justify-content-between align-items-center">
                            <div className="col-6 pe-2">
                                <button
                                    className={`t-12 w-100 btn ${notificationTab === "Orders"
                                        ? "btn-dark text-warning"
                                        : "btn-outline-dark"
                                        }`}
                                    onClick={() => setNotificationTab("Orders")}
                                >
                                    Orders
                                </button>
                            </div>
                            <div className="col-6 pe-2">
                                <button
                                    className={`t-12 w-100 btn ${notificationTab === "inventory"
                                        ? "btn-dark text-warning"
                                        : "btn-outline-dark"
                                        }`}
                                    onClick={() => setNotificationTab("inventory")}
                                >
                                    Inventory
                                </button>
                            </div>
                        </div>
                    </div>

                    <hr className="my-4" />

                    {notificationTab === "inventory" && (
                        <>
                            {/* <div className="my-5">
                                <div className="row px-2 d-flex justify-content-between align-items-center">
                                    <select
                                        className="t-12 form-select"
                                        aria-label="Default select example"
                                        outline="none"
                                    >
                                        <option selected>All Notifications</option>
                                        <option value="1">Expering Soon</option>
                                        <option value="2">Low on Stock</option>
                                    </select>
                                </div>
                            </div> */}

                            <div className="notificationScroll ps-2 pe-3 mb-3">
                                {loader ? (
                                    <div className="text-center mt-5">
                                        <button
                                            class="btn mt-5"
                                            style={{ backgroundColor: "#FFEDC2" }}
                                            type="button"
                                        >
                                            <span
                                                class="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>{" "}
                                            Loading...
                                        </button>
                                    </div>
                                ) : (
                                    inventory?.map((el) => (<>
                                        <div>
                                            <div className="t-12 row px-2 font-weight-bold mb-0 text-capitalize">
                                                {el?.product?.product_name} Running low on quantity
                                            </div>
                                            <div className="row px-2 t-12">
                                                <div className="col-7 p-0 mb-3 text-capitalize">Only {el?.product?.sizeList?.quantity} apples left</div>
                                                <div className="col-5 p-0 mb-3 cursorPointer d-flex justify-content-end">
                                                    {/* <Link to="/custominventory" state={{ data: el, edit: true }}
                                                    >Take action</Link> */}
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="mb-3 mt-0 w-100" />
                                    </>)

                                    ))}
                            </div>


                        </>
                    )}
                    {notificationTab == "Orders" && (
                        <>


                            <div className="notificationScroll ps-2 pe-3 mb-3">
                                {loader ? (
                                    <div className="text-center mt-5">
                                        <button
                                            class="btn mt-5"
                                            style={{ backgroundColor: "#FFEDC2" }}
                                            type="button"
                                        >
                                            <span
                                                class="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>{" "}
                                            Loading...
                                        </button>
                                    </div>
                                ) : (
                                    orders?.map((el) => (
                                        <>
                                            <div>
                                                <div className="t-12 row px-2 font-weight-bold mb-0">
                                                    Order no. #{el?.order?._id}
                                                </div>
                                                <div className="row px-2 t-12">
                                                    <div className="col-7 p-0 mb-0">
                                                        {el?.order?.products?.length} Items
                                                    </div>
                                                    <div className="col-7 p-0 ">{`${el?.order?.society?.societyName},${el?.order?.society?.city}`}</div>
                                                    <div className="col-7 p-0 mb-3">{`${el?.order?.userDetails?.floor },${el?.order?.userDetails?.villa || el?.order?.userDetails?.flat_no}`}</div>
                                                    <div className="col-5 p-0 mb-3 cursorPointer d-flex justify-content-end">
                                                        {el?.order?._id && (  // Add a conditional check here
                                                            <Link className="" to={`/order-details/${el.order._id}`}>
                                                                View Order
                                                            </Link>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <hr className="mb-3 mt-0 w-100" />
                                        </>
                                    ))

                                )}
                            </div>



                        </>
                    )}

                    {/*     <div className="mb-3">
                            <div className="sidebar_logo">
                                <Link to="/categories">
                                    <img src={Logo} className="w-100 categoryImage" />
                                </Link>
                            </div>
                            <div className="text-uppercase">Super Admin</div>
                        </div>
                        
                        <div className="mb-3 bg-light rounded row py-3">
                            <div className="col-md-3 d-flex align-items-center"><img src={User} className="userLogo w-100" /></div>
                            <div className="col-md-9 d-flex flex-row align-items-center">
                                <div className="p-1 text-wrap">
                                    <div className="font-weight-bold">Super Administrator</div>
                                    <div className="t-12">superadmin@hibee.com</div>
                                </div>
                            </div>
                        </div>

                        <div className="mb-5 overflow-auto sidebarCategoryHeight pe-2">
                            {
                                categories?.map(el => (
                                    el?.type !== "DROPDOWN"
                                    ?
                                    <div onClick={() => togglePage(el)} key={el?.id}>
                                        <div className={`py-2 px-3 rounded cursorPointer ${selectedCategory?.id === el?.id && "selectedCategory font-weight-bold shadow-sm"}`} onClick={() => togglePage(el)}>
                                            {el?.name}
                                        </div>
                                    </div>
                                    :
                                    <div className="position-relative" data-bs-toggle="collapse" data-bs-target={`#accordian-${el?.id}`} key={el?.id} onClick={() => togglePage(el)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={`${selectedCategory?.id !== el?.id && 'grey'}`} style={{top: "13px", right: "12px"}} className="position-absolute" viewBox="0 0 16 16">
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                        </svg>
                                        <div className={`py-2 px-3 rounded cursorPointer ${selectedCategory?.id === el?.id && "selectedCategory font-weight-bold shadow-sm"}`} onClick={() => togglePage(el)}>
                                            {el?.name}
                                        </div>
                                        <div id={`accordian-${el?.id}`} className={`${selectedCategory?.id===el?.id && "show"}  px-3 bg-light text-sidebar-dropdown py-2 rounded t-12 accordion-collapse collapse`}>
                                            {
                                                el?.subCategory?.map(ele => (
                                                    <div className={`py-1 px-3 rounded cursorPointer`} onClick={e => {e.stopPropagation(); togglePage(el, ele, "subCategory") }}>
                                                        {ele?.name}
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </div>

                        <div className="m-0 p-0">
                            <span className="mx-3">Help</span>
                            <span className="mx-3">About</span>
                            <span className="mx-3">Setting</span>
                        </div> */}
                </div>
            </Modal>
        </>
    );
};

export default Breadcrumb;
