import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getTokenApi , patchTokenApi, postTokenApi } from '../../api_interface/apis';
import MainTemplate from '../../components/MainTemplate';
import { HIBEE_ORDER_URL } from '../../api_interface/apiURLs';
import moment from 'moment';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';

const RetrunOrderDetails = () => {
    const { _id } = useParams();
    const [returnOrderData, setReturnOrderData] = useState(null);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isDisclaimerVisible, setIsDisclaimerVisible] = useState(false);

    console.log(_id, "ajousydfhuijashuifdikj")
    useEffect(() => {
        const fetchData = async () => {

            try {
                const response = await getTokenApi(
                    `${HIBEE_ORDER_URL}/order/returnRequestList/?_id=${_id}`
                );
                setReturnOrderData(response?.data?.data);

            } catch (error) {
                console.error('Error fetching return order details:', error);
            }
        };
        fetchData();
    }, [_id]);
    const handleStatusChange = async () => {
        try {
          if (!selectedOrder || !selectedStatus) {
            console.error('Order ID or status is missing.');
            return;
          }
      
          const response = await patchTokenApi(
            HIBEE_ORDER_URL+'/order/returnRequestStatusChange',            
            {
                _id: selectedOrder,
                status: selectedStatus,
          
            }
          );
      
          if (response.status === 200) {
            closeModal();
            // fetchData();
          } else {
            console.error('Unexpected response status:', response.status);
          }
        } catch (error) {
          console.error('Error updating status:', error);
        }finally{
            setLoading(false);
        }
      };
    const openModal = (orderId) => {
        setSelectedOrder(orderId);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setSelectedOrder(null);
        setSelectedStatus('');
        setModalIsOpen(false);
    };
    console.log(returnOrderData, "apiUrlapiUrlapiUrlapiUrl")

    return (
        <div>
            <MainTemplate
                categoryName="Return Order Details"
                categoryList={["Return Order Details"]}
            >
                <div className='d-flex justify-content-between'>
                    <h1 className='mb-5'>Return Order Details</h1>
                  
                </div>
                <div className="container">
                    <div className="row">
                        {/* First Column */}
                        <div className="col-md-6">
                            <div style={{ border: '2px solid #ddd', padding: '15px', marginBottom: '15px', borderRadius: "5px" }}>
                                <h2 className='m-2'>Order Sent</h2>
                                {returnOrderData && returnOrderData.products && (

                                    <table className="table">
                                        <tbody>
                                            {returnOrderData.products.map((product, index) => (
                                                <React.Fragment key={index}>
                                                    <h5 className='m-3 fs-3 '>Product {index + 1}</h5>
                                                    <tr>
                                                        <td>Product Name:</td>
                                                        <td>{product.product_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Product Code:</td>
                                                        <td>{product.product_code}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Product Quantity:</td>
                                                        <td>{product.qty}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Product Price:</td>
                                                        <td>{product.price}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Size Quantity:</td>
                                                        <td>{product.sizeList.quantity}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Size:</td>
                                                        <td>{product.sizeList.size} {product.sizeList.unit}</td>
                                                    </tr>
                                                    <tr>

                                                        <td>
                                                            {product.image && product.image.length > 0 && (
                                                                <ul>
                                                                    {product.image.map((image, imageIndex) => (
                                                                        <li key={imageIndex}>
                                                                            <img src={image.image} style={{ width: "200px" }} alt={`Product Image ${imageIndex + 1}`} />
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            )}
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            ))}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>

                        {/* Second Column */}
                        <div className="col-md-6">
                            <div style={{ border: '2px solid #ddd', padding: '15px', marginBottom: '15px', borderRadius:"5px" }}>
                                <h1>Order Received</h1>
                                {returnOrderData && (
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <td>Change Status:</td>
                                                <td  className="t-12 text-uppercase d-flex justify-content-center text-white"
                              onClick={() => openModal(_id)}
                              style={{
                                backgroundColor:
                                returnOrderData.status === 'requested'
                                    ? '#febf22'
                                    : returnOrderData.status === 'accepted'
                                    ? '#29ae13'
                                    : returnOrderData.status === 'rejected'
                                    ? 'red'
                                    : 'transparent',
                                padding: "5px",
                                borderRadius: "5px",
                                cursor: "pointer"
                              }}> 
                              {returnOrderData.status}
                            </td>
                                            </tr>
                                            <tr>
                                                <td>Received On:</td>
                                                <td>{moment(returnOrderData.createdAt).format("D MMM, YYYY")}</td>
                                            </tr>
                                            <tr>
                                                <td>Return Reason</td>
                                                <td>{returnOrderData.return_reason}</td>
                                            </tr>
                                            <tr>

                                                <td>
                                                    {returnOrderData.images && returnOrderData.images.length > 0 && (
                                                        <ul>
                                                            {returnOrderData.images.map((image, imageIndex) => (
                                                                <li key={imageIndex}>
                                                                    <img src={image.image} style={{ width: "400px" }} alt={`Product Image ${imageIndex + 1}`} />
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}

                            </div>
                        </div>
                    </div>
                    <Modal
                                isOpen={modalIsOpen}
                                onRequestClose={closeModal}
                                contentLabel="Change Status Modal"
                                style={{
                                    overlay: {
                                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    },
                                    content: {
                                        width: '500px',
                                        height: '400px',
                                        margin: 'auto',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                    },
                                }}
                            >
                                <h2 className='fs-2 mb-4 text-center'>Change Status</h2>
                                {isDisclaimerVisible && (
                <div className="disclaimer">
                    <p className="text-danger">
                        Once you submit this, you will not be able to change the status.
                    </p>
                </div>
            )}

                                <label className='fs-4 text-center' htmlFor="statusDropdown ">Select Status</label>
                                <select
                                className='p-2 m-4'
                                style={{borderRadius:"5px"}}
                                    id="statusDropdown"
                                    value={selectedStatus}
                                    onChange={(e) => {
                                        setSelectedStatus(e.target.value);
                                        setIsDisclaimerVisible(true); // Hide disclaimer when the dropdown value changes
                                    }}
                                    
                                >
                                    <option value="">Select status</option>
                                    <option value="accepted">Accepted</option>
                                    <option value="rejected">Rejected</option>
                                </select>
                                
                                <button style={{ marginTop: "20px", width: "300px", alignSelf: "center", backgroundColor: "black", color: "yellow", borderRadius: "5px", padding: "5px" }} onClick={handleStatusChange}>Submit</button>
                                <button style={{ marginTop: "20px", width: "300px", alignSelf: "center", backgroundColor: "black", color: "yellow", borderRadius: "5px", padding: "5px" }} onClick={closeModal}>Close</button>
                            </Modal>
                </div>
            </MainTemplate>

        </div>
    )
}

export default RetrunOrderDetails