import React, { useEffect, useState, useMemo } from "react";
import "./AdminList.css";
import ReactPaginate from 'react-paginate';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Modal from "react-modal";
import { deleteTokenApi, getApi, putTokenApi } from "../../api_interface/apis";
import { ADMIN_PATH, ADMIN_USER, HIBEE_ADMIN_URL, HIBEE_AUTH_URL } from "../../api_interface/apiURLs";
import MainTemplate from "../../components/MainTemplate";
import { customActionModalStyles, debounce } from "../../utils/helperFunctin";
import imagenotavailable from '../../assets/images/imagenotavailable.png'


function AdminList() {
	const [itemOffset, setItemOffset] = useState(0);
	const [modal, setModal] = useState(false)
	const [actionModal, setActionModal] = useState({ loader: false, show: false, type: "", data: {} })
	const [admins, setAdmins] = useState([])
	const [category, setcategory] = useState({ name: "", image: "", imageFile: "" })
	const [error, setError] = useState({})
	const [loader, setLoader] = useState(false)
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [updateType, setUpdateType] = useState(true)

	const [aprove, setAprove] = useState({
		status: "",
		index: ""
	})
	const [sort, setSort] = useState("")
	const [query, setQuery] = useState("")

	const endOffset = itemOffset + itemsPerPage;
	const pageCount = Math.ceil(admins.length / itemsPerPage);
	const currentItems = admins.slice(itemOffset, endOffset);

	const handlePageClick = (event) => {
		const newOffset = (event.selected * itemsPerPage) % admins.length;
		setItemOffset(newOffset);
	};

	const getAdmins = async (search, updateType, sort) => {
		setLoader(true)
		const one = localStorage.getItem('adminStatus')
		const two = localStorage.getItem('adminSort')
		const res = await getApi(HIBEE_AUTH_URL + ADMIN_USER + `/admin` + `?sort=${two ? two : 'name'}&search=${search || query || ""}&status=${one}`)
		if (res.status == 200) {
			setAdmins(res?.data?.data?.reverse())
			setLoader(false)
		}
	}

	const updateSort = e => {
		localStorage.setItem('adminSort', e.target.value)
		setSort(e.target.value)
	}

	useEffect(() => {
		getSearchedData(query, updateType, sort)
	}, [query, updateType, sort])

	const getSearchedData = useMemo(() => debounce(async (search, updateType, sort) => {
		getAdmins(search, updateType, sort)
	}, 500), [])



	const approveAdmin = async (ele, id) => {
		setAprove({ ...aprove, status: ele, index: id })
		// Path Params
		const adminId = id
		const status = ele
		localStorage.setItem('adminStatus', status)
		try {
			const res = await putTokenApi(HIBEE_ADMIN_URL + ADMIN_PATH + `/${adminId}/${status}`)
			if (res.status == 200) {
				const newStatus = localStorage.getItem('adminStatus')
				localStorage.setItem('adminStatus',  newStatus == 'true' ? false : true)
				if (res?.data?.status == 'true') {
					toast.success(res?.data?.message, {
						position: "top-center",
						autoClose: 2000,
					});
				}
				if (res?.data?.status == 'false') {
					toast.error(res?.data?.message, {
						position: "top-center",
						autoClose: 2000,
					});
				}
				getAdmins()
			}
		} catch (err) {
			toast.error(err?.response?.data?.message, {
				position: "top-center",
				autoClose: 2000,
			})
			getAdmins()
			const newStatus = localStorage.getItem('adminStatus')
			localStorage.setItem('adminStatus',  newStatus == 'true' ? false : true)
		}
	}

	const handleDeleteAdmin = async (id) => {
		const adminId = id
		const res = await deleteTokenApi(HIBEE_ADMIN_URL + ADMIN_PATH + `/${adminId}`);
		if (res.status == 200) {
			getAdmins();
			setActionModal({})
		} else {
			getAdmins();
			setActionModal({})
		}
	};

	useEffect(() => {
		getAdmins()
	}, [])

	/** filter **/
	useEffect(() => {
		localStorage.setItem('adminStatus', true)
	}, [])

	useEffect(() => {
		const data = localStorage.getItem('adminStatus')
		setUpdateType(data)
	}, [updateType])

	return (
		<MainTemplate categoryName="Admin List" categoryList={[ "Admin",'Admin List']}>
			<div className="row mb-4 rounded p-2 bg-white">
				<div className="row p-0 ">
					{/* <div className="col-sm-2 my-3 col-md-2">
						<select className="t-12 form-select" aria-label="Default select example" onChange={updateSort}>
							<option value="" selected>Sort by</option>
							<option value="name">Alphabatically</option>
							<option value="dateold">Date: Acsending</option>
							<option value="datenew">Date: Descending</option>
						</select>
					</div> */}
					{/* <div className="col-sm-6 my-3 col-md-2">
						<select className="t-12 form-select" aria-label="Default select example" onChange={e => setItemsPerPage(e.target.value)}>
							<option value="10" selected>10 per page</option>
							<option value="20">20 per page</option>
							<option value="50">50 per page</option>
							<option value="100">100 per page</option>
						</select>
					</div> */}
					<div className="col-sm-6 my-3 col-md-2">
						<select className="t-12 form-select" aria-label="Default select example"
							onChange={e => {
								setUpdateType(e.target.value === "true" ? true : false)
								localStorage.setItem('adminStatus', e.target.value === "true" ? true : false)
							}}
							value={updateType}
						>
							<option value={true} selected>Active</option>
							<option value={false}>Inactive</option>
						</select>
					</div>
					<div className="my-3 col-md-2">
						<input type="text" name="query" className="t-12 form-control" placeholder="Search" value={query} onChange={e => setQuery(e.target.value)} />
					</div>
					<div className="d-none d-md-block col-md-2"></div>
					<div className=" my-3 col-md-2 text-end">
						<Link to="/admin-form">
							<button type="" className=" btn text-warning afrnch"
								style={{ backgroundColor: "#12352f" }}
							>
								Add Admin
							</button>
						</Link>
					</div>
				</div>
			</div>

			{/* table */}
			<div className="row flex-column align-items-center">
				<div className="row rounded text-center bg-white">
					{/* header */}
					<div className="row m-0 ">
						<div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-1">
							Photo
						</div>
						<div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-3">
							Email ID
						</div>
						<div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-3">
							Full Name
						</div>
						{/* <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-3">
						Roles
					</div> */}
						<div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-3">
							Phone
						</div>
						<div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2" >
							Active{" "}/{" "}Inactive
						</div>
						{/* <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-1">
					</div> */}
					</div>

					{/* body */}
					<div className="justify-content-center align-items-center overflow-auto caterogyPageScroll">
						{
							currentItems?.map(el => <div
								className="row border rounded m-0 mb-2 d-flex align-items-center"
								key={el?._id}
							>
								<div className="t-12 text-uppercase my-3 col-md-1">
									<div className="categoryImageDiv shadow-sm rounded p-1">
										<Link className="text-dark" to={`/admin/${el?._id}`} style={{ textDecoration: "none" }}>
											<img src={el?.image ? el?.image : imagenotavailable} className="categoryImage h-100 rounded" />
										</Link>
									</div>
								</div>
								<div className="t-12 text-uppercase my-3 col-md-3">
									<Link
										// className="text-dark"
										to={`/admin/${el?._id}`} style={{ textDecoration: "none" }}>
										{el?.name}
									</Link>
								</div>
								<div className="t-12 text-uppercase my-3 col-md-3 overflow-hidden">
									<Link
										className="text-dark"
										to={`/admin/${el?._id}`} style={{ textDecoration: "none" }}>
										{/* {el?._id.slice(0,7)} */}
										{el.email}
									</Link>
								</div>
								{/* <div className="t-12 text-uppercase my-3 col-md-3">
								HR
							</div> */}
								<div className="t-12 text-uppercase my-3 col-md-3">
									{el?.phone}
								</div>
								<div className="t-12 text-uppercase my-3 col-md-2" >
									<div className="m-auto" >
										<label className="switch">
											<input className="switch-input" type="checkbox"
												// checked={[el.active].includes(aprove.status) && aprove.index == el._id ? true : false}
												checked={el.active}
												onChange={e => e.target.checked ? approveAdmin(e.target.checked, el?._id) : approveAdmin(e.target.checked, el?._id)} />
											<span className="switch-label"></span>
											<span className="switch-handle"></span>
										</label>
									</div>
								</div>
								{/* <div className="t-12 text-uppercase my-3 col-md-1">
								<div class="dropdown">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" data-menu-target={`drop-down-menu-${el?._id}`} className="bi bi-three-dots dropdown-btn cursorPointer" viewBox="0 0 16 16">
										<path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
									</svg>
									<div id={`drop-down-menu-${el?._id}`} className="dropdown-content p-2 mobile-dropdown">
										<div className="px-3 py-2 d-flex cursorPointer" onClick={() => setActionModal({ ...actionModal, show: true, data: el, type: "DELETE" })}>
											<span className="mx-2 d-flex align-items-center">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
													<path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
												</svg>
											</span>
											<span className="mx-2">
												<div className="font-weight-bold">Delete</div>
												<div className="">Delete this admin</div>
											</span>
										</div>
										<div className="px-3 py-2 d-flex cursorPointer" onClick={() => setActionModal({ ...actionModal, show: true, data: el, type: "ARCHIEVE" })}>
											<span className="mx-2 d-flex align-items-center">
												<svg width="16" height="16" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M2.44444 22C1.77222 22 1.19656 21.7609 0.717444 21.2826C0.239148 20.8034 0 20.2278 0 19.5556V4.27778C0 3.97222 0.0509259 3.69722 0.152778 3.45278C0.25463 3.20833 0.387037 2.97407 0.55 2.75L2.26111 0.672222C2.42407 0.448148 2.62778 0.279889 2.87222 0.167445C3.11667 0.0558149 3.38148 0 3.66667 0H18.3333C18.6185 0 18.8833 0.0558149 19.1278 0.167445C19.3722 0.279889 19.5759 0.448148 19.7389 0.672222L21.45 2.75C21.613 2.97407 21.7454 3.20833 21.8472 3.45278C21.9491 3.69722 22 3.97222 22 4.27778V19.5556C22 20.2278 21.7609 20.8034 21.2826 21.2826C20.8034 21.7609 20.2278 22 19.5556 22H2.44444ZM2.93333 3.66667H19.0667L18.0278 2.44444H3.97222L2.93333 3.66667ZM2.44444 6.11111V19.5556H19.5556V6.11111H2.44444ZM11 18.3333L15.8889 13.4444L14.1778 11.7333L12.2222 13.6889V8.55556H9.77778V13.6889L7.82222 11.7333L6.11111 13.4444L11 18.3333ZM2.44444 19.5556H19.5556H2.44444Z" fill="#666666" />
												</svg>
											</span>
											<span className="mx-2">
												<div className="font-weight-bold">Archive</div>
												<div className="">Archive this product</div>
											</span>
										</div>
									</div>
								</div>
							</div> */}

							</div>
							)
						}

						{loader == true &&
							<div className="text-center mt-5" >
								<button class="btn mt-5" style={{ backgroundColor: '#FFEDC2' }} type="button">
									<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{" "}
									Loading...
								</button>
							</div>
						}
					</div>
				</div>
			</div>
			<div className="row m-2 d-flex justify-content-center align-items-center">
				<ReactPaginate
					breakLabel="..."
					nextLabel=">"
					onPageChange={handlePageClick}
					pageRangeDisplayed={3}
					pageCount={pageCount}
					previousLabel="<"
					renderOnZeroPageCount={null}
					pageClassName="page-item mb-0"
					subContainerClassName="pages "
					pageLinkClassName="page-link"
					previousClassName="page-item"
					previousLinkClassName="page-link"
					nextClassName="page-item"
					nextLinkClassName="page-link"
					breakClassName="page-item"
					breakLinkClassName="page-link"
					marginPagesDisplayed={2}
					containerClassName="pagination"
					activeClassName="active"
				/>
			</div>

			{/* Admin Delete */}
			<Modal
				isOpen={actionModal?.show}
				onRequestClose={() => setActionModal({})}
				style={customActionModalStyles}
				contentLabel="Action Modal"
				ariaHideApp={false}
			>
				<div className="px-3 py-3">
					<div className="row">
						<span className="d-flex justify-content-end cursorPointer">
							<svg
								onClick={() => setActionModal({})}
								className="d-inline"
								xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="16"
								fill="currentColor"
								viewBox="0 0 16 16"
							>
								<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
							</svg>
						</span>
					</div>
					<div className="row">
						<h4 className="text-danger d-flex justify-content-center">
							Do you want to {actionModal?.type} Admin Role?
						</h4>
					</div>
					<div className="row my-5">
						<h5 className="d-flex justify-content-center">
							{/* {actionModal?.data?.name} */}
						</h5>
					</div>
					<div className="form-group my-3 d-flex justify-content-center">
						<button
							disabled={actionModal?.loader}
							className="btn btn-dark me-3 mb-2 text-warning px-5"
							onClick={() =>
								actionModal?.type === "DELETE"
									? handleDeleteAdmin(actionModal?.data?._id) : ''
								// : archiveOffer(actionModal?.data?._id)
							}
						>
							{actionModal?.loader ? (
								<div class="spinner-border spinner-border-sm" role="status">
									<span class="sr-only"></span>
								</div>
							) : (
								"Submit"
							)}
						</button>
						<button
							disabled={actionModal?.loader}
							className="btn btn-light me-3 mb-2 shadow-sm text-dark border px-5"
							onClick={() => setActionModal({})}
						>
							Cancel
						</button>
					</div>
				</div>
			</Modal>
		</MainTemplate>

	);
}

export default AdminList;

