import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage'
import { combineReducers } from "redux";
import { persistReducer } from 'redux-persist'
import userReducer from "./reducers/userSlice"
import sidebarReducer from "./reducers/sidebarSlice"
import sidebardModuleSliceReducer from './reducers/sidebardModuleSlice';


const appReducer = combineReducers({
  userReducer, sidebarReducer, sidebardModuleSliceReducer
})

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    storage.removeItem('persist:root')
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

const persistConfig = {
  key: 'root',
  storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);


const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  // middleware: []
});

export default store;