import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MainTemplate from "../../components/MainTemplate";
import "../Society/societyinternal.css";
import { toast } from "react-toastify";
import { deleteTokenApi, putTokenApi } from "../../api_interface/apis";
import { DELETE_TOWERS, HIBEE_PRODUCT_URL, SOCIETY_LIST, SOCIETY_TOWER_CREATION } from "../../api_interface/apiURLs";
import { Spinner } from "react-bootstrap";
import Modal from "react-modal";
import { customActionModalStyles } from "../../utils/helperFunctin";

function SocietyInternal() {
    const location = useLocation()
    const navidate = useNavigate()

    const [buildingType, setBuildingType] = useState("tower");
    const [towersCount, setTowersCount] = useState([1])
    const [VillaCount, setVillaCount] = useState([1])
    const [societyData, setSocietyData] = useState({
        societyName: "",
        addline1: "",
        addline2: "",
        landMark: "",
        city: "",
        state: "",
        pincode: "",
        latitude: "",
        longitude: "",
        tower: "",
        villa: [],
        _id: '',
        loader: false
    })
    const [societyDataError, setSocietyDataError] = useState({
        societyName: "",
        addline1: "",
        addline2: "",
        landMark: "",
        city: "",
        state: "",
        pincode: "",
        latitude: "",
        longitude: "",
        tower: [],
        villa: "",
        _id: '',
        loader: 'false'
    })
    const [floors, setFloors] = useState(["GL"]);
    const [name, setVillaName] = useState({})
    const [villas, setVillas] = useState([{ name: "s" }]);
    const [count, setCount] = useState(societyData?.villa?.length);
    const [selectPosButton, setSelectPosButton] = useState(true)
    // const [duplicateVilla, setDuplicateVilla] = useState([])

    // New Logic
    const [towersList, setTowersList] = useState([
        {
            name: "",
            floors: [
                { floorName: "GL", active: true },
                { floorName: "1", active: true },
                { floorName: "2", active: true }
            ]
        }
    ]);

    const [selectedTowerId, setSelectedTowerId] = useState()
    const [towerModal, setTowerModal] = useState({
        data: "",
        show: false,
        loader: false
    })
    const [villaModal, setVillaModal] = useState({
        data: "",
        show: false,
        loader: false
    })

    /* Tower */
    const handleAddTower = () => {
        const newTower = {
            // name: `Tower ${String.fromCharCode(65 + towers.length)}`,
            towerId: Number(new Date()),
            name: '',
            floors: [
                { floorName: "GL", floorId: Number(new Date()), active: true },
                { floorName: "1", floorId: Number(new Date()), active: true },
                { floorName: "2", floorId: Number(new Date()), active: true }
            ]
        };
        setTowersList([...towersList, newTower]);
    };

    const handleRemoveTower = async (tower, towerIndex) => {
        if (tower?._id) {
            try {
                setTowerModal({
                    ...towerModal,
                    show: true,
                    societyId: societyData?._id,
                    towerId: tower?._id,
                    data: tower,
                })
            } catch (error) {
                const message = error?.response?.data?.message || "Something went wrong";
                toast.error(message, { position: "top-center", autoClose: 2000 });
            }
        } else {
            if (towersList.length != 1) {
                // setTowersList(towersList.slice(0, -1));
                const filterTower = towersList.filter((ele, ind) => {
                    return ind != towerIndex
                })
                setTowersList(filterTower)
            }
        }
    }

    const handleDeleteTower = async () => {
        setTowerModal({
            ...towerModal,
            show: true,
            loader: true
        })
        const responce = await deleteTokenApi(HIBEE_PRODUCT_URL + DELETE_TOWERS + `?societyId=${towerModal?.societyId}&towerId=${towerModal?.towerId}`)
        if (responce.status == 200) {
            setTowerModal({
                ...towerModal,
                show: false,
                loader: false
            })
            toast.success(responce?.data?.message, { position: "top-center", autoClose: 2000 });
            navidate('/society-listing')
        }
    }

    const handleRemoveVilla = async (ele, index) => {
        if (ele?._id) {
            try {
                setVillaModal({
                    ...villaModal,
                    show: true,
                    societyId: societyData?._id,
                    towerId: ele?._id,
                    data: ele,
                })
            } catch (error) {
                const message = error?.response?.data?.message || "Something went wrong";
                toast.error(message, { position: "top-center", autoClose: 2000 });
            }
        } else {
            if (villas.length != 1) {
                // setTowersList(towersList.slice(0, -1));
                const filterVillas = villas.filter((ele, ind) => {
                    return ind != index
                })
                setVillas(filterVillas)
            }
        }
    }

    const handleDeleteVillla = async () => {
        setVillaModal({
            ...villaModal,
            show: true,
            loader: true
        })
        const responce = await deleteTokenApi(HIBEE_PRODUCT_URL + DELETE_TOWERS + `?societyId=${villaModal?.societyId}&villaId=${villaModal?.towerId}`)
        if (responce.status == 200) {
            setVillaModal({
                ...villaModal,
                show: false,
                loader: false
            })
            toast.success(responce?.data?.message, { position: "top-center", autoClose: 2000 });
            navidate('/society-listing')
        }
    }


    const handleTowerNameChange = (towerIndex, newName) => {
        const updatedTowers = [...towersList];
        updatedTowers[towerIndex].name = newName;
        setTowersList(updatedTowers);
    };

    const handleAddFloor = (towerIndex) => {
        const tower = towersList[towerIndex];
        const lastFloor = tower.floors[tower.floors.length - 1];
        const lastFloorNumber = parseInt(lastFloor?.floorName, 10);
        const checkNumber = Number.isNaN(lastFloorNumber)
        if (tower.floors.length <= 50 && checkNumber == false) {
            if (!isNaN(lastFloorNumber)) {
                const newFloorNumber = lastFloorNumber + 1;
                const newFloor = {
                    floorId: Number(new Date()),
                    floorName: `${newFloorNumber}`,
                    active: true
                };
                const updatedTowers = [...towersList];
                updatedTowers[towerIndex].floors.push(newFloor);
                setTowersList(updatedTowers);
            }
        } else {
            toast.error('last floor should not text', { position: "top-center", autoClose: 2000 });
        }
    };

    const handleRemoveFloor = (towerIndex) => {
        setSelectPosButton(false)
        setSelectedTowerId(towerIndex)
        const updatedTowers = [...towersList];
        if (updatedTowers[towerIndex].floors.length > 1) {
            updatedTowers[towerIndex].floors.pop()
            setTowersList(updatedTowers);
        } else {

        }
    }
    const handleIncrementFloor = (towerIndex, floorIndex) => {
        const updatedTowers = [...towersList];
        updatedTowers[towerIndex].floors[floorIndex].floorName++;
        setTowersList(updatedTowers);
    };

    const handleDecrementFloor = (towerIndex, floorIndex) => {
        const updatedTowers = [...towersList];
        updatedTowers[towerIndex].floors[floorIndex].floorName--;
        setTowersList(updatedTowers);
    };

    const handleUpdateFloor = (towerIndex, floorIndex) => {
        const editedFloorName = prompt("Enter the floor Number:");
        const updatedTowers = [...towersList];
        const duplicateArray = []
        updatedTowers[towerIndex].floors.map((ele, ind) => {
            if (ele.floorName) {
                duplicateArray.push(ele.floorName)
            }
        })
        if (!duplicateArray.includes(editedFloorName)) {
            if (editedFloorName !== null) {
                const updatedTowers = [...towersList];
                updatedTowers[towerIndex].floors[floorIndex].floorName = editedFloorName.toLocaleUpperCase()
                setTowersList(updatedTowers);
            }
        } else {
            toast.error('duplicate floor name is not allowed', { position: "top-center", autoClose: 2000 });
        }

    }

    const handleBuildingTypeChange = (event) => {
        setBuildingType(event.target.value);
        setSelectPosButton(true)
    };



    /* Villa */
    const handleAddInput = () => {
        setSelectPosButton(true)
        const one = /^[0-9]+$/.test(villas.slice(-1)[0].name);
        if (one) {
            var num = +villas.slice(-1)[0].name + 1
        } else {
            var num = ''
        }
        const newVilla = {
            // villa: villas.length + 1,
            name: num
            // villaId: Number(new Date())
        };
        setVillas([...villas, newVilla]);
    };

    const handleRemoveInput = () => {
        setSelectPosButton(false)
        if (villas.length > 1) {
            const newVillas = [...villas];
            newVillas.pop();
            setVillas(newVillas);
        }
    };

    const handleInputChange = (e, index) => {
        // const checkNumber = Number.isNaN(e.target.value)
        // if (checkNumber == false) {
        //     const newVillas = [...villas];
        //     newVillas[index].name = e.target.value;
        //     setVillas(newVillas);
        // } else {
        const newVillas = [...villas];
        newVillas[index].name = e.target.value;
        setVillas(newVillas);
        // }
    };

    const isTowerListValid = () => {
        return towersList.every((tower) => tower.name.trim() !== "");
    };
    const isVillaListValid = () => {
        return villas.every(villa => villa.name !== '');
    }

    const handleSubmitVilla = async (ele) => {
        if (ele == 'tower') {
            if (isTowerListValid()) {
                setSocietyData({ societyData, loader: true })
                const payload =
                {
                    societyName: societyData?.societyName,
                    addressLine1: societyData?.addline1,
                    addressLine2: societyData?.addline2,
                    city: societyData?.city,
                    state: societyData?.state,
                    pinCode: societyData?.pincode,
                    // active: true,
                    lat: societyData.latitude,
                    long: societyData.longitude,
                    landmark: societyData.landMark,
                    // _id: societyData?._id,
                    towers: towersList,
                    // villas: villas
                }
                try {
                    setSocietyData({ ...societyData, loader: true })
                    const responce = await putTokenApi(HIBEE_PRODUCT_URL + SOCIETY_TOWER_CREATION + `?societyId=${societyData?._id}`, payload)
                    if (responce.status == 200) {
                        // toast.success(responce?.data?.message, { position: "top-center", autoClose: 2000 });
                        setSocietyData({ societyData, loader: false })
                        navidate('/society-listing')
                        toast.success("Your Tower added successfully", { position: "top-center", autoClose: 2000 })
                       
                    }
                } catch (error) {
                    const message = error?.response?.data?.message || "Something went wrong";
                    toast.error(message, { position: "top-center", autoClose: 2000 });
                    navidate('/society-listing')
                    setSocietyData({ societyData, loader: false })
                }
            } else {
                toast.error('Please eneter tower name', { position: "top-center", autoClose: 2000 });
            }

        } else {
            if (isVillaListValid()) {
                // if (!hasDuplicates(duplicateVilla)) {
                setSocietyData({ societyData, loader: true })
                const payload =
                {
                    societyName: societyData?.societyName,
                    addressLine1: societyData?.addline1,
                    addressLine2: societyData?.addline2,
                    city: societyData?.city,
                    state: societyData?.state,
                    pinCode: societyData?.pincode,
                    // active: true,
                    lat: societyData.latitude,
                    long: societyData.longitude,
                    landmark: societyData.landMark,
                    _id: societyData?._id,
                    // towers: towersList,
                    villas: villas
                }
                try {
                    setSocietyData({ ...societyData, loader: true })
                    const responce = await putTokenApi(HIBEE_PRODUCT_URL + SOCIETY_TOWER_CREATION + `?societyId=${societyData?._id}`, payload)
                    if (responce.status == 200) {
                        // toast.success(responce?.data?.message, { position: "top-center", autoClose: 2000 });
                        setSocietyData({ societyData, loader: false })
                        navidate('/society-listing')
                        toast.success("Your Villa added successfully", { position: "top-center", autoClose: 2000 })
                        
                    }
                } catch (error) {
                    const message = error?.response?.data?.message || "Something went wrong";
                    toast.error(message, { position: "top-center", autoClose: 2000 });
                    navidate('/society-listing')
                    setSocietyData({ societyData, loader: false })
                }
                // } else {
                //     toast.error('dupplicate villa names not allowed', { position: "top-center", autoClose: 2000 });
                // }
            } else {
                toast.error('Please eneter villa name', { position: "top-center", autoClose: 2000 });
            }
        }
    }
    const findtext = /^[a-z A-Z]$/
    useEffect(() => {
        if (location?.state) {
            setSocietyData({
                ...societyData,
                societyName: location.state?.societyName,
                addline1: location?.state?.addline1,
                addline2: location?.state?.addline2,
                landMark: location?.state?.landMark,
                city: location?.state?.city,
                state: location?.state?.state,
                pincode: location?.state?.pincode,
                latitude: location?.state?.latitude,
                longitude: location?.state?.longitude,
                landMark: location.state?.landMark,
                tower: location?.state?.tower,
                // tower: [],
                villa: location?.state?.villa,
                // villa: [],
                _id: location?.state?._id
            })
            const towerObject = [{
                name: "",
                towerId: Number(new Date()),
                floors: [
                    { floorName: "GL", floorId: Number(new Date()), active: true },
                    { floorName: "1", floorId: Number(new Date()), active: true },
                    { floorName: "2", floorId: Number(new Date()), active: true }
                ]
            }]
            setTowersList(location?.state?.tower?.length == 0 ? towerObject : location?.state?.tower)
            setVillas(location?.state?.villa.length == 0 ? [{
                // villa: 1,
                name: "",
                // villaId: Number(new Date())
            }] : location?.state?.villa)
        }
    }, [])

    // const hasDuplicates = (array) => {
    //     const uniqueValues = new Set(array);
    //     return array.length !== uniqueValues.size;
    // }
    // useEffect((ele, ind) => {
    //     const duplicateVilla = []
    //     villas.map((ele, ind) => {
    //         duplicateVilla.push(ele.name)
    //     })
    //     setDuplicateVilla(duplicateVilla)
    // }, [villas])



    return (
        <MainTemplate
            categoryName="Societies"
            categoryList={["Admin", "Societies"]}
        >
            <div className="societyListing-filterTab align-items-center">
                <div className="col -5 d-flex gap-4 align-items-center">
                    <div>
                        <p className="societyinternalheadinginfo m-0">
                            Select Building Type
                        </p>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="buildingType"
                            id="towerRadio"
                            value="tower"
                            checked={buildingType === "tower"}
                            onChange={handleBuildingTypeChange}
                        />
                        <label className="form-check-label" htmlFor="towerRadio">
                            Tower
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="buildingType"
                            id="villaRadio"
                            value="villa"
                            checked={buildingType === "villa"}
                            onChange={handleBuildingTypeChange}
                        />
                        <label className="form-check-label" htmlFor="villaRadio">
                            Villa
                        </label>
                    </div>
                </div>
                {buildingType == "tower"
                    ?
                    <div className="col-5 d-flex gap-4">
                        <button type="button" onClick={handleAddTower}
                            // className="t-12 w-100 btn btn-outline-dark font-weight-bold"
                            className="t-12 w-100 btn btn-outline-dark"
                        >
                            + Add Tower
                        </button>
                        {/* <button type="button" onClick={handleRemoveTower} className="t-12 w-100 btn btn-outline-dark">
                            - Remove Tower
                        </button> */}
                        <button type="button"
                            // className={"bulkuploadbutton1 active rounded font-weight-bold"}
                            className="t-12 w-100 btn text-warning"
                            onClick={() => handleSubmitVilla('tower')}
                            style={{ backgroundColor: "#12352f" }}
                        >
                            Save {" "}
                            {
                                societyData.loader
                                    ?
                                    <Spinner
                                        animation="border"
                                        variant="light"
                                        size="sm"
                                    />
                                    :
                                    ''
                            }
                        </button>
                    </div>
                    :
                    <div className="d-flex gap-4">
                        {/* <button type="button" className="t-12 w-100 btn btn-outline-dark font-weight-bold ">
                            +  Add Villa
                        </button> */}
                        <button type="button" onClick={() => handleSubmitVilla('vill')} className="t-12 w-100 btn text-warning"
                            style={{ backgroundColor: "#12352f" }}
                        >
                            Save {" "}
                            {
                                societyData.loader
                                    ?
                                    <Spinner
                                        animation="border"
                                        variant="light"
                                        size="sm"
                                    />
                                    :
                                    ''
                            }

                        </button>
                    </div>
                }
            </div>

            {/* <div className="m-0 p-0 pe-3 overflow-auto caterogyPageScroll-internal">
                {buildingType == "tower" ? (
                    <div className="bg-white mt-5 societyinternalfloor">
                        <div className="societyinternalheadinginfo">
                            Total Tower Count : 07
                        </div>

                        {
                            towersCount.map((ele, ind) => {
                                return <div>
                                    <div className="mt-4 d-flex gap-3">
                                        <div className="form-group ">
                                            <p className="societyinternalp">Tower/ Villa Name</p>
                                            <input
                                                name="name"
                                                type="text"
                                                autoComplete="off"
                                                className={`placeholder-light form-control my-2 py-2 px-3`}
                                                placeholder="Enter category name here..."
                                            />
                                        </div>
                                        <div className="d-flex">
                                            <div>
                                                <p className="societyinternalp">Add Floors</p>
                                                <div className="">
                                                    <button
                                                        className="societyinternalfloorbuttonactive"
                                                        onClick={handleAddFloor}
                                                    >
                                                        +
                                                    </button>
                                                    <button
                                                        className="societyinternalfloorbutton mt-2"
                                                        onClick={handleDeleteFloor}
                                                    >
                                                        -
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="">
                                                <p className="societyinternalp">.</p>
                                                <div className="societyinternalfloordeco">
                                                    {floors.map((floor, index) => (
                                                        <p
                                                            key={index}
                                                            className="societyinternalfloorbutton"
                                                            onDoubleClick={() => handleEditFloor(index)}
                                                        >
                                                            {floor}
                                                        </p>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })
                        }

                    </div>
                ) : (
                    <div className="bg-white mt-5 societyinternalfloor ">
                        <div className="societyinternalheadinginfo">
                            Total Villa Count : 07
                        </div>
                        <div className="mt-5">
                            <p className="societyinternalp">Tower/ Villa Name</p>
                            <div className="d-flex align-items-center gap-2" >
                                <input
                                    name="name"
                                    type="text"
                                    autoComplete="off"
                                    className={`placeholder-light form-control my-2 py-2 px-3 w-25`}
                                    placeholder="101"
                                />
                                <button className="societyinternalfloorbuttonactive m-0" onClick={handleAddFloor} >
                                    +
                                </button>
                                <button
                                    className="societyinternalfloorbutton"
                                    onClick={handleDeleteFloor}
                                >
                                    -
                                </button>
                            </div>
                            <input
                                name="name"
                                type="text"
                                autoComplete="off"
                                className={`placeholder-light form-control my-2 py-2 px-3 w-25`}
                                placeholder="Enter Tower Name"
                            />
                        </div>
                    </div>
                )}
            </div> */}



            <div className="row rounded px-3 m-2 bg-white">
                {/* header */}
                {
                    buildingType == "tower"
                        ?
                        < div className="row my-3 mx-3">
                            <div className="societyinternalheadinginfo">
                                Total Tower Count : {towersList?.length ? towersList.length : 0}
                            </div>
                        </div>
                        :
                        < div className="row my-3 mx-3">
                            <div className="societyinternalheadinginfo">
                                Total Villa Count : {villas?.length ? villas.length : 0}
                            </div>
                        </div>
                }

                {/* body */}
                <div className="m-0 p-0 pe-3 overflow-auto caterogyPageScroll-internal">
                    {buildingType == "tower" ? (
                        <div className="bg-white mt-5">
                            {
                                towersList && towersList?.map((tower, towerIndex) => {
                                    return <div>
                                        <div className="mt-4 d-flex gap-3">
                                            <div className="form-group">
                                                <div className="d-flex" >
                                                    <p className="societyinternalp">Tower Name <span className="text-danger" >*</span></p>
                                                    <div className="" onClick={() => handleRemoveTower(tower, towerIndex)} style={{ marginLeft: '20px' }} >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-trash" viewBox="0 0 16 16">
                                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                                <input
                                                    name="name"
                                                    value={tower.name}
                                                    onChange={(event) =>
                                                        handleTowerNameChange(towerIndex, event.target.value)
                                                    }
                                                    autoComplete="off"
                                                    className={`placeholder-light form-control my-2 py-2 px-3`}
                                                    placeholder="Enter tower name here..."

                                                />
                                            </div>

                                            <div>
                                                <p className="societyinternalp">Add Floors</p>
                                                <div className="row align-items-start">
                                                    <div className="col" >
                                                        <p className={`${selectPosButton == false && selectedTowerId == towerIndex ? 'societyinternalfloorbutton ' : 'societyinternalfloorbuttonactive'}`} onClick={() => handleAddFloor(towerIndex)}>+</p>
                                                        <p className={`${selectPosButton == false && selectedTowerId == towerIndex ? 'societyinternalfloorbuttonactive' : 'societyinternalfloorbutton'}`} onClick={() => handleRemoveFloor(towerIndex)}>-</p>
                                                    </div>
                                                    <div className="col">
                                                        {/* <p className="">.</p> */}
                                                        <div
                                                        
                                                        className="societyinternalfloordeco">

                                                            {tower.floors.map((floor, floorIndex) => (
                                                                <div className="">
                                                                    <p
                                                                    
                                                                        // key={index}
                                                                        className="societyinternalfloorbutton"
                                                                        onClick={() => handleUpdateFloor(towerIndex, floorIndex)}
                                                                    >
                                                                        {floor.floorName}
                                                                    </p>
                                                                    {/* <p onClick={() => handleRemoveFloor(towerIndex, floorIndex)} >Remove Floor</p> */}
                                                                    {/*
                                                                <div className="">
                                                                    <button
                                                                        className="societyinternalfloorbuttonactive"
                                                                        onClick={() => handleIncrementFloor(towerIndex, floorIndex)}
                                                                    >
                                                                        +
                                                                    </button>
                                                                    <button
                                                                        className="societyinternalfloorbutton mt-2"
                                                                        onClick={() => handleDecrementFloor(towerIndex, floorIndex)}
                                                                    >
                                                                        -
                                                                    </button>
                                                                </div> */}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                })
                            }

                        </div>
                    ) : (
                        <div className="mt-5">
                            <p className="societyinternalp">Villa Name <span className="text-danger" >*</span></p>
                            <div className="d-flex" >
                                <div className="col-3" >
                                    {
                                        villas?.map((ele, index) => {
                                            // console.log(findtext?.test(ele?.name?.slice(0, 1)), '11');
                                            // console.log(typeof (+ele.name) === 'number','222');
                                            return <div>
                                                <div className="d-flex align-items-center gap-2" >
                                                    {/* <input
                                                name="name"
                                                type='text'
                                                autoComplete="off"
                                                className={`placeholder-light form-control my-2 py-2 px-3 w-25`}
                                                placeholder="enter villa"
                                                onChange={(event) => handleInputChange(event, ind)}
                                                key={ele.key}
                                                value={ele.name}
                                            />
                                            <button className="societyinternalfloorbuttonactive m-0" onClick={handleAddVilla} >
                                                +
                                            </button>
                                            <button className="societyinternalfloorbutton" onClick={handleRemoveVilla}  >
                                                -
                                            </button> */}
                                                    <input
                                                        name="name"
                                                        type={(/^[0-9]+$/.test(villas?.slice(-1)[0]?.name)) ? 'number' : 'text'}
                                                        value={ele.name}
                                                        onChange={(e) => handleInputChange(e, index)}
                                                        autoComplete="off"
                                                        className={`placeholder-light form-control my-2 py-2 px-3 w-100`}
                                                        placeholder="Enter villa"
                                                    />
                                                    <div className="" onClick={() => handleRemoveVilla(ele, index)} style={{ marginLeft: '20px' }} >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-trash" viewBox="0 0 16 16">
                                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                                <div className=" d-flex col-2" style={{ marginLeft: '10px', marginTop: '8px' }} >
                                    <button className={`${selectPosButton == false ? 'societyinternalfloorbutton' : 'societyinternalfloorbuttonactive'}`} onClick={handleAddInput}>+</button>
                                    <button className={`${selectPosButton == false ? 'societyinternalfloorbuttonactive' : 'societyinternalfloorbutton'}`} onClick={handleRemoveInput} style={{ marginLeft: '5px' }} >-</button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {/* Tower Modal */}
                <div>
                    <Modal
                        isOpen={towerModal.show}
                        onRequestClose={() => setTowerModal({
                            ...towerModal,
                            show: false
                        })}
                        style={customActionModalStyles}
                        contentLabel="Action Modal"
                        ariaHideApp={false}
                    >
                        <div className="px-3 py-3 modal_width">
                            <div className="row">
                                <span className="d-flex justify-content-end cursorPointer">
                                    <svg
                                        onClick={() => setTowerModal({
                                            ...towerModal,
                                            show: false
                                        })}
                                        className="d-inline"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                                    </svg>
                                </span>
                            </div>
                            <div className="row">
                                <h4 className="text-danger d-flex justify-content-center">
                                    Do you want to Delete Tower ?
                                </h4>
                            </div>
                            <div className="row my-5">
                                <h5 className="d-flex justify-content-center">
                                    {towerModal?.data?.name}
                                </h5>
                            </div>
                            <div className="form-group my-3 d-flex justify-content-center">
                                <button
                                    disabled={towerModal?.loader}
                                    className="btn btn-dark me-3 mb-2 text-warning px-5"
                                    onClick={handleDeleteTower}
                                    style={{ backgroundColor: "#12352f" }}
                                >
                                    {towerModal?.loader ? (
                                        <div class="spinner-border spinner-border-sm" role="status">
                                            <span class="sr-only"></span>
                                        </div>
                                    ) : (
                                        "Yes"
                                    )}
                                </button>
                                <button
                                    disabled={towerModal?.loader}
                                    className="btn btn-light me-3 mb-2 shadow-sm text-dark border px-5"
                                    onClick={() => setTowerModal({
                                        ...towerModal,
                                        show: false
                                    })}
                                >
                                    No
                                </button>
                            </div>
                        </div>
                    </Modal>
                </div>

                {/* Villa Modal */}
                <div>
                    <Modal
                        isOpen={villaModal.show}
                        onRequestClose={() => setVillaModal({
                            ...villaModal,
                            show: false
                        })}
                        style={customActionModalStyles}
                        contentLabel="Action Modal"
                        ariaHideApp={false}
                    >
                        <div className="px-3 py-3 modal_width">
                            <div className="row">
                                <span className="d-flex justify-content-end cursorPointer">
                                    <svg
                                        onClick={() => setVillaModal({
                                            ...villaModal,
                                            show: false
                                        })}
                                        className="d-inline"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                                    </svg>
                                </span>
                            </div>
                            <div className="row">
                                <h4 className="text-danger d-flex justify-content-center">
                                    Do you want to Delete Villa ?
                                </h4>
                            </div>
                            <div className="row my-5">
                                <h5 className="d-flex justify-content-center">
                                    {villaModal?.data?.name}
                                </h5>
                            </div>
                            <div className="form-group my-3 d-flex justify-content-center">
                                <button
                                    disabled={villaModal?.loader}
                                    className="btn btn-dark me-3 mb-2 text-warning px-5"
                                    onClick={handleDeleteVillla}
                                    style={{ backgroundColor: "#12352f" }}
                                >
                                    {villaModal?.loader ? (
                                        <div class="spinner-border spinner-border-sm" role="status">
                                            <span class="sr-only"></span>
                                        </div>
                                    ) : (
                                        "Yes"
                                    )}
                                </button>
                                <button
                                    disabled={villaModal?.loader}
                                    className="btn btn-light me-3 mb-2 shadow-sm text-dark border px-5"
                                    onClick={() => setVillaModal({
                                        ...villaModal,
                                        show: false
                                    })}
                                >
                                    No
                                </button>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>


        </MainTemplate >
    );
}

export default SocietyInternal;
