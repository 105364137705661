import React, { useEffect, useMemo } from "react";
import Row from "react-bootstrap/Row";
import placeholder from "../../assets/images/placeholder.png";
import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import QRCode from "react-qr-code";
import Barcode from "react-jsbarcode";
import Modal from "react-modal";
import { BsUpload } from "react-icons/bs";
import MainTemplate from "../../components/MainTemplate";
import { Button, Form } from "react-bootstrap";
import { getTokenApi, postTokenApi } from "../../api_interface/apis";
import { CATEGORY_PATH, CATEGORY_SUB_LIST_PATH, HIBEE_PRODUCT_URL, PRODUCT_PATH } from "../../api_interface/apiURLs";
import { custombulkModalStyles, debounce } from "../../utils/helperFunctin";

function Addinventory() {
    const navigate = useNavigate();
    const location = useLocation();
    const data = location?.state?.data;
    const isEditing = location?.state?.edit;
    const [submittedOnce, setSubmittedOnce] = useState(false);
    const [loader, setLoader] = useState(false);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [query, setQuery] = useState("")
    const [products, setProducts] = useState([]);

    const [productImage, setProductImage] = useState([]);
    const [inventoryData, setInventoryData] = useState(
        data
            ? data
            : {
                product_code: "",
                product_name: "",
                product_variant: "",
                manufacture_name: "",
                manufacturing_date: "",
                expiration_date: "",
                qty: "",
                units: "",
                warehouse: "",
                category: "",
                subcategory: "",
                type: "",
                batch: "",
                image: "",
                offerlimit: "",
                cutout_price: "",
                commission: "",
                description: "",
                sizeList: "",
                viewImage: "",
            }
    );
    const [inventoryDataError, setInventoryDataError] = useState({});

    /**Handle Validation **/
    const handleValidation = () => {
        const newErrors = {};

        // if (!inventoryData?.product_code) {
        //   newErrors.product_code = "Please enter unique code";
        // }

        // if (!inventoryData?.product_name) {
        //     newErrors.product_name = "Please enter product name";
        // }
        // if (!inventoryData?.product_variant) {
        //     newErrors.product_variant = "Please enter product variant";
        // }
        // if (!inventoryData?.manufacture_name) {
        //     newErrors.manufacture_name = "Please enter manufacture name";
        // }
        // if (!inventoryData?.manufacturing_date) {
        //     newErrors.manufacturing_date = "Please select product MFG date";
        // }
        // if (!inventoryData?.expiration_date) {
        //     newErrors.expiration_date = "Please select product expiry date";
        // }
        if (!sizeList?.size) {
            newErrors.qty = "Please enter qty name";
        }
        // if (!inventoryData?.warehouse) {
        //     newErrors.warehouse = "Please select warehouse";
        // }
        if (!sizeList?.unit) {
            newErrors.units = "Please select units";
        }
        // if (!inventoryData?.category) {
        //     newErrors.category = "Please select category";
        // }
        // if (!inventoryData?.subcategory) {
        //     newErrors.subcategory = "Please select sub category";
        // }
        // if (!inventoryData?.type) {
        //     newErrors.type = "Please enter storage type";
        // }
        // if (!inventoryData?.batch) {
        //     newErrors.batch = "Please enter product batch";
        // }
        // if (!inventoryData?.image) {
        //     newErrors.image = "Please upload product variant";
        // }
        // if (!inventoryData?.price) {
        //     newErrors.price = "Please enter price";
        // }
        // if (!inventoryData?.offerlimit) {
        //     newErrors.offerlimit = "Please enter offer limit";
        // }
        // if (!inventoryData?.cutout_price) {
        //     newErrors.cutout_price = "Please enter cutout price";
        // }
        // if (!inventoryData?.commission) {
        //     newErrors.commission = "Please enter commission";
        // }

        // if (!inventoryData?.description) {
        //     newErrors.description = "Please enter description";
        // }

        if (Object.keys(newErrors)?.length) {
            setInventoryDataError(newErrors);
            return false;
        } else {
            setInventoryDataError({});
            return true;
        }
    };



    const getProducts = async search => {
        let res

        res = await getTokenApi(HIBEE_PRODUCT_URL + PRODUCT_PATH + `?&search=${search || query || ""}`);

        if (res.status == 200) {
            setProducts(res?.data?.data);
        }
    };

    const getSearchedData = useMemo(() => debounce(async (search) => {
        getProducts(search)
    }, 500), [])


    useEffect(() => {
        getSearchedData(query)
    }, [query])


    const [showDropdown, setShowDropdown] = useState(false);
    const handleInputChangedropdown = (e) => {
        setQuery(e.target.value);
        setShowDropdown(true);
    };

    /** haandle change **/
    const handleInventorychange = (event, type) => {
        if (type !== "image") {
            setInventoryData({
                ...inventoryData,
                [event.target.name]: event.target.value,
            });
            setInventoryDataError({
                ...inventoryDataError,
                [event.target.name]: null,
            });
        } else {
            var url = URL.createObjectURL(event.target.files[0]);
            setProductImage([...productImage, event.target.files[0]]);
            setInventoryData({
                ...inventoryData,
                [event.target.name]: url,
            });
            setInventoryDataError({
                ...inventoryDataError,
                [event.target.name]: null,
            });
        }
    };

    // const [productImages, setProductImages] = useState([]);
    const handleFileInputChange = (e) => {
        const file = e.target.files[0];
        // const reader = new FileReader();
        // reader.readAsDataURL(file);
        // setProductImages([...productImages, file]);
    };
    const handleImageDelete = (ele, index) => {
        // setInventoryData({ ...inventoryData, viewImage: index })
        const newImages = [...productImage];
        newImages.splice(index, 1);
        setProductImage(newImages);
        if (productImage.length == 0) {
            setInventoryData({ ...inventoryData, image: "" });
        }
    };
    const [sizeList, setSizeList] = useState({
        size: "",
        unit: "",
    })
    /** handle validation **/
    const handleSubmitAddInventory = async (e) => {
        e.preventDefault();
        try {
            setLoader(true);
            setSubmittedOnce(true);

            if (handleValidation()) {
                const fd = new FormData();

                // if (inventoryData._id) {
                //   fd.append("_id", inventoryData._id);
                // }
                // fd.append("product_code", inventoryData?.product_code);
                // fd.append("product_name", inventoryData?.product_name);
                // fd.append("hsn_code", inventoryData?.hsn_code);

                // fd.append("product_variant", JSON.stringify(variantFields));
                // fd.append("manufacture_name", inventoryData?.manufacture_name);
                // fd.append("manufacturing_date", inventoryData?.manufacturing_date);
                // fd.append("expiration_date", inventoryData?.expiration_date);
                // fd.append("qty", inventoryData?.qty);
                // fd.append("discount", inventoryData?.discount);
                fd.append("sizeList", JSON.stringify(sizeList));
                // fd.append("category", inventoryData?.category);
                // fd.append("subcategory", inventoryData?.subcategory);
                // fd.append("type", inventoryData?.type);
                // fd.append("batch", inventoryData?.batch);
                // if (productImage && productImage.length > 0) {
                //     productImage.forEach((image, index) => {
                //         fd.append("images", image, `image_${index}`);
                //     });
                // }
                // fd.append("cess", inventoryData?.cess);

                // fd.append("offerlimit", inventoryData?.offerlimit);
                // fd.append("tax", inventoryData?.tax);
                // fd.append("price", inventoryData?.price);
                // fd.append("commission", inventoryData?.commission);
                // fd.append("description", inventoryData?.description);

                // fd.append("sizeList", JSON.stringify({ size: "500 g", quantity: 17 }));

                const res = await postTokenApi(HIBEE_PRODUCT_URL + PRODUCT_PATH, fd, {
                    "Content-Type": "multipart/form-data",
                });
                toast.success(res?.data?.message, {
                    position: "top-center",
                    autoClose: 2000,
                });
                navigate("/products");
            }
            setLoader(false);
        } catch (err) {
            toast.error(err?.response?.data?.message, {
                position: "top-center",
                autoClose: 2000,
            });
            setLoader(false);
        }
    };

    const getCategories = async () => {
        const res = await getTokenApi(HIBEE_PRODUCT_URL + CATEGORY_PATH);
        setCategories(res?.data?.data);
    };

    const getSubCtegories = async () => {
        const res = await postTokenApi(
            HIBEE_PRODUCT_URL + CATEGORY_SUB_LIST_PATH + `/${inventoryData?.category}`
        );
        setSubCategories(res?.data?.message?.[0]?.SubCategorys);
    };

    useEffect(() => {
        if (inventoryData?.category) getSubCtegories();
    }, [inventoryData?.category]);

    useEffect(() => {
        if (Object.keys(inventoryData)?.length && submittedOnce) {
            handleValidation();
        }
    }, [inventoryData]);

    useEffect(() => {
        getCategories();
    }, []);

    console.log(inventoryData, "inventoryData");
    console.log(inventoryDataError, "inventoryDataError");
    console.log(productImage, "productImages");
    const [modal, setModal] = useState(false);
    const closeCategoryModal = () => {
        setModal(false);
        setLoader(false);
    };

    const handleItemSelect = (selectedProduct) => {
        setInventoryData(selectedProduct);

        setQuery('');
        setShowDropdown(false);
    };

    console.log(inventoryData, "afterseleting");

    return (
        <MainTemplate
            categoryName="Inventory"
            categoryList={["Admin", "Inventory", "Add"]}
        >
            <Form onSubmit={handleSubmitAddInventory}>
                <Row>
                    <div className="row mb-4 rounded bg-white homepageModifierPadding mb-4">
                        {/* <div className="d-flex align-items-baseline">
                            <p className="bulkupload">Uploading many products at once?</p>
                            <div class="bulkuploadbutton-container">
                                <button
                                    type="button"
                                    class="bulkuploadbutton ms-2"
                                    onClick={() => {
                                        setModal(!modal);
                                    }}
                                >
                                    Bulk upload
                                </button>
                            </div>
                        </div> */}
                        {/* <Modal
                            isOpen={modal}
                            onRequestClose={closeCategoryModal}
                            style={custombulkModalStyles}
                            contentLabel="Example Modal"
                            ariaHideApp={false}
                        >
                            <div>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <p className="bulkuploadmodelheader">
                                            Adding multiple categories at once?
                                        </p>
                                        <p className="bulkuploadmodelsubheader">
                                            Upload a file in a .csv format
                                        </p>
                                    </div>
                                    <p className="bulkuploadchoosefile">Browse files 
                                    <input type="file" className="opacity absolute"/>
                                    </p>
                                </div>

                                <div>
                                    <p className="bulkuploadp">Download sample format here</p>
                                    <div className="d-flex justify-content-center align-items-center bulkuploadoption">
                                        <div>
                                            <div className="bulkuploadsinput">
                                                <BsUpload className=""></BsUpload>
                                                <input
                                                    name="CSV"
                                                    type="file"
                                                    autoComplete="off"
                                                    accept=".csv"
                                                    class="bulkupload-input"
                                                />
                                            </div>
                                            <p className="bulkuploadinputtag">Drop files here</p>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center m-4">
                                        <button type="button" class="bulkuploadbutton ms-2">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Modal> */}
                        <div className="col-lg-6 mb-4">
                            <Form.Group className="mb-3" controlId="Product Name ">
                                <Form.Label>Product Name</Form.Label>
                                {inventoryData?.product_name ? (
                                    <Form.Control
                                        className="Textbar"
                                        type="text"
                                        placeholder="Product name"
                                        value={inventoryData?.product_name}
                                        name="product_name"
                                        isInvalid={inventoryDataError?.product_name}
                                        autoComplete="off"
                                        onChange={handleInventorychange}
                                        disabled={inventoryData?.product_name ? true : false}
                                    />) :
                                    (
                                        <input
                                            className="custominventory400 customeinventoryData me-1"
                                            style={{ width: 448 }}
                                            type="text"
                                            placeholder="Enter Item Name"
                                            value={query}
                                            onChange={handleInputChangedropdown}
                                            onFocus={() => setShowDropdown(query.length > 0)}
                                        />)}
                                {showDropdown && (
                                    <div className='dropdowns'>
                                        {products?.length === 0 ? (
                                            <div className='itemSelectingdropdown'>
                                                <p className='p-0 m-0'>Add New Product</p>
                                            </div>
                                        ) : (
                                            <>
                                                {products?.map((el, index) => (
                                                    <div className='itemSelectingdropdown' key={index} onClick={() => handleItemSelect(el)}>
                                                        <img className='itemSelectingdropdownimg' src={el.image[0]} alt={el.product_name} />
                                                        <p className='p-0 m-0'>{el.product_name}</p>
                                                    </div>
                                                ))}
                                                <div className='itemSelectingdropdown'>
                                                    <p className='p-0 m-0'>Add New Item</p>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                )}
                                <span className="text-danger">
                                    {inventoryDataError.product_name}
                                </span>
                            </Form.Group>
                            {/* <Form.Group className="mb-3" controlId="Product variant">
                                <Form.Label>Product variant</Form.Label>
                                <Form.Control
                                    className="Textbar"
                                    type="text"
                                    placeholder="Enter product variant"
                                    name="product_variant"
                                    value={inventoryData?.product_variant}
                                    isInvalid={inventoryDataError?.product_variant}
                                    autoComplete="off"
                                    onChange={handleInventorychange}
                                    disabled={inventoryDataError?.product_variant ? true : false}
                                />
                                <span className="text-danger">
                                    {inventoryDataError.product_variant}
                                </span>
                            </Form.Group> */}
                            {/* <Form.Group className="mb-3" controlId="Manufacture Name">
                                <Form.Label>Manufacture Name</Form.Label>
                                <Form.Control
                                    className="Textbar"
                                    type="text"
                                    placeholder="Enter manufacture name"
                                    name="manufacture_name"
                                    value={inventoryData?.manufacture_name}
                                    isInvalid={inventoryDataError?.manufacture_name}
                                    autoComplete="off"
                                    onChange={handleInventorychange}
                                />
                                <span className="text-danger">
                                    {inventoryDataError.manufacture_name}
                                </span>
                            </Form.Group> */}

                            <div className="mb-3">
                                <div style={{ width: "448px" }}>
                                    <Row>

                                        <div className="col-6">
                                            <Form.Group
                                                className="mb-3"
                                                controlId="Product Expiry Date"
                                            >
                                                <Form.Label>Best Before/Expiry</Form.Label>
                                                <Form.Control
                                                    className="smtext"
                                                    type="date"
                                                    placeholder="Select Expiry date"
                                                    name="expiration_date"
                                                    value={inventoryData?.expiration_date}
                                                    onChange={handleInventorychange}
                                                    isInvalid={inventoryDataError?.expiration_date}
                                                    autoComplete="off"
                                                />
                                                <span className="text-danger">
                                                    {inventoryDataError.expiration_date}
                                                </span>
                                            </Form.Group>
                                        </div>
                                    </Row>
                                </div>
                            </div>

                            <div className="mb-3">
                                <div>
                                    <Row>
                                        <div className="col-4">
                                            <Form.Group className="mb-3" controlId="Product Qty">
                                                <Form.Label>Product Qty</Form.Label>
                                                <Form.Control
                                                    className="smtext w-100"
                                                    type="number"
                                                    placeholder="01"
                                                    name="qty"
                                                    value={inventoryData?.qty}
                                                    onWheelCapture={(e)=>e.target.blur()}

                                                    onChange={(e) => setSizeList({ ...sizeList, size: e.target.value })}
                                                    isInvalid={inventoryDataError?.qty}
                                                    autoComplete="off"
                                                />
                                                <span className="text-danger">
                                                    {inventoryDataError.qty}
                                                </span>
                                            </Form.Group>
                                        </div>
                                        <div className="col-4">
                                            <Form.Group className="mb-3" controlId="units">
                                                <Form.Label>Units</Form.Label>
                                                <Form.Select
                                                    className="Textbar"
                                                    style={{ width: 200 }}
                                                    controlId="unit"
                                                    type="text"
                                                    placeholder="gms"
                                                    name="unit"
                                                    value={sizeList?.unit}
                                                    autoComplete="off"
                                                    onChange={(e) => setSizeList({ ...sizeList, unit: e.target.value })}
                                                >
                                                    <option value="kg">KG</option>
                                                    <option value="gm">GM</option>
                                                    <option value="ml">ML</option>
                                                    <option value="l">L</option>
                                                </Form.Select>
                                            </Form.Group>
                                            <span className="text-danger">
                                                {inventoryDataError.units}
                                            </span>
                                        </div>
                                        {/* <div className="col-4">
                                            <Form.Group className="mb-3" controlId="Product Qty">
                                                <Form.Label>Select Warehouse</Form.Label>
                                                <Form.Select
                                                    className="mb-3 smtext w-auto"
                                                    controlId="Select Warehouse"
                                                    type="text"
                                                    placeholder="Enter Select Warehouse"
                                                    name="warehouse"
                                                    onChange={handleInventorychange}
                                                    value={inventoryData?.warehouse}
                                                    isInvalid={inventoryDataError?.warehouse}
                                                    autoComplete="off"
                                                >
                                                    <option value="Whitefield">Whitefield</option>
                                                    <option value="London">London</option>
                                                    <option value="Delhi">Delhi</option>
                                                </Form.Select>
                                            </Form.Group>
                                            <span className="text-danger">
                                                {inventoryDataError.warehouse}
                                            </span>
                                        </div> */}
                                    </Row>
                                </div>
                            </div>
                            <Form.Group className="mb-3" controlId="Product MFG Date">
                                <Form.Label>Product Purchase Date</Form.Label>
                                <Form.Control
                                    className="smtext"
                                    type="date"
                                    placeholder="product_purchase_date"
                                    name="product_purchase_date"
                                    value={inventoryData?.product_purchase_date}
                                    onChange={handleInventorychange}
                                    isInvalid={inventoryDataError?.product_purchase_date}
                                    autoComplete="off"
                                />
                                <span className="text-danger">
                                    {inventoryDataError.product_purchase_date}
                                </span>
                            </Form.Group>
                            <Row>
                                <div className="col mb-3">
                                    <Form.Label>Product category</Form.Label>
                                    <Form.Select
                                        className="Textbar"
                                        controlId="Product category"
                                        type="text"
                                        placeholder="Enter select product category"
                                        name="category"
                                        onChange={handleInventorychange}
                                        isInvalid={inventoryDataError?.category}
                                        autoComplete="off"
                                        disabled={inventoryData?.category ? true : false}
                                    >
                                        <option selected disabled>
                                            Select category
                                        </option>
                                        {categories?.map((el, index) => (
                                            <option
                                                key={index}
                                                value={el?._id}
                                                selected={el?._id === inventoryData?.category}
                                            >
                                                {el?.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <span className="text-danger">
                                        {inventoryDataError.category}
                                    </span>
                                </div>
                            </Row>


                            {/* <Row>
                                <div className="col mb-3">
                                    <Form.Label>Product sub-category</Form.Label>
                                    <Form.Select
                                        className="Textbar"
                                        controlId="Product category"
                                        type="text"
                                        placeholder="Enter select product category"
                                        name="subcategory"
                                        onChange={handleInventorychange}
                                        isInvalid={inventoryDataError?.subcategory}
                                        autoComplete="off"
                                        disabled={isEditing}
                                    >
                                        <option selected disabled>
                                            Select sub-category
                                        </option>
                                        {subCategories?.map((el, index) => (
                                            <option
                                                key={index}
                                                value={el?._id}
                                                selected={el?._id === inventoryData?.subcategory}
                                            >
                                                {el?.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <span className="text-danger">
                                        {inventoryDataError.subcategory}
                                    </span>
                                </div>
                            </Row> */}
                            {/* <div className="mb-3">
                                <div style={{ width: "448px" }}>
                                    <Row>
                                        <div className="col-6">
                                            <Form.Group className="mb-3" controlId="Storage Type">
                                                <Form.Label>Storage Type</Form.Label>
                                                <Form.Control
                                                    className="smtext"
                                                    type="text"
                                                    placeholder="Enter storage name"
                                                    name="type"
                                                    value={inventoryData?.type}
                                                    onChange={handleInventorychange}
                                                    isInvalid={inventoryDataError?.type}
                                                    autoComplete="off"
                                                />
                                                <span className="text-danger">
                                                    {inventoryDataError.type}
                                                </span>
                                            </Form.Group>
                                        </div>
                                        <div className="col-6">
                                            <Form.Group className="mb-3" controlId="Product Batch">
                                                <Form.Label className="margintext">
                                                    Product Batch
                                                </Form.Label>
                                                <Form.Control
                                                    className="smtext"
                                                    type="text"
                                                    placeholder="Enter batch name"
                                                    name="batch"
                                                    value={inventoryData?.batch}
                                                    onChange={handleInventorychange}
                                                    isInvalid={inventoryDataError?.batch}
                                                    autoComplete="off"
                                                />
                                                <span className="text-danger">
                                                    {inventoryDataError.batch}
                                                </span>
                                            </Form.Group>
                                        </div>
                                    </Row>
                                </div>
                            </div>

                            <Form.Group className="mb-3" controlId="Price">
                                <Form.Label>Offer Limit</Form.Label>
                                <Form.Control
                                    className="Textbar"
                                    type="number"
                                    placeholder="Enter offer Limit"
                                    name="price"
                                    value={inventoryData?.offerlimit}
                                    isInvalid={inventoryDataError?.offerlimit}
                                    autoComplete="off"
                                    onChange={handleInventorychange}
                                    onWheelCapture={(e) => e.target.blur()}
                                />
                                <span className="text-danger">
                                    {inventoryDataError.offerlimit}
                                </span>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="Cutout Price">
                                <Form.Label>Cutout Price</Form.Label>
                                <Form.Control
                                    className="Textbar"
                                    type="number"
                                    placeholder="Enter Cutout Price"
                                    name="cutout_price"
                                    isInvalid={inventoryDataError?.cutout_price}
                                    autoComplete="off"
                                    value={inventoryData?.cutout_price}
                                    onChange={handleInventorychange}
                                    onWheelCapture={(e) => e.target.blur()}
                                />
                                <span className="text-danger">
                                    {inventoryDataError.cutout_price}
                                </span>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="Commission">
                                <Form.Label>Commission</Form.Label>
                                <Form.Control
                                    className="Textbar"
                                    type="number"
                                    placeholder="Enter Commission"
                                    name="commission"
                                    value={inventoryData?.commission}
                                    isInvalid={inventoryDataError?.commission}
                                    autoComplete="off"
                                    onChange={handleInventorychange}
                                    onWheelCapture={(e) => e.target.blur()}
                                />
                                <span className="text-danger">
                                    {inventoryDataError.commission}
                                </span>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="Commission">
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    className="Textbar"
                                    type="textarea"
                                    placeholder="Enter Commission"
                                    name="description"
                                    value={inventoryData?.description}
                                    isInvalid={inventoryDataError?.description}
                                    autoComplete="off"
                                    onChange={handleInventorychange}
                                    onWheelCapture={(e) => e.target.blur()}
                                    as="textarea"
                                    rows={3}
                                />
                                <span className="text-danger">
                                    {inventoryDataError.description}
                                </span>
                            </Form.Group> */}
                        </div>
                        <div className="col-lg-6 mb-4 text-center">
                            {/* <div className="py-2 mb-3">
                                <label>Bar Code</label>
                                <Row>
                                    <Barcode
                                        className="d-flex"
                                        options={{
                                            format: "code128",
                                            width: "2",
                                            marginLeft: 0,
                                            text: inventoryData?.product_code || "Unique Code",
                                        }}
                                        value={inventoryData?.product_code || " "}
                                    />
                                </Row>
                            </div> */}
                            {/* <div className="py-2">
					      <label>Bar code</label>
					      {
                  true 
                  ?
                  <Row>
                    <Form.Group className="mb-3" controlId="Pan Card">
                      <div className="form-group py-2 position-relative mb-3">
                        <input type="file"
                          name='image'
                          onChange={handleInventorychange}
                          isInvalid={inventoryDataError?.image}
                          className="opacity0 position-absolute w-75 placeholder-light form-control py-2 imgheight px-3" />
                        <div className="profile-box text-center" >
                          <div  style={{marginTop:"80px"}}>
                            <img src={placeholder} height='20px' width='20px' />{" "}<span style={{ color: '#A3A3A3' }}>Upload Photo</span>
                          </div>
                        </div>
                      </div>
                      <span className="text-danger" >{inventoryDataError.image}</span>
                    </Form.Group>
                  </Row>
                  :
                  <Row>
                    <div className="profile-box mb-3 g-0 d-flex justify-content-center align-items-center" >
                      <img src={inventoryData.image} width='100%' height='100%' />
                    </div>
                  </Row>
                }
              </div> */}
                            {/* <div className="py-2 mb-3">
                                <label>QR Code</label>
                                <Row className="d-flex justify-content-center">
                                    <QRCode
                                        style={{ height: "250px", width: "250px" }}
                                        value={JSON.stringify(inventoryData)}
                                    />
                                </Row>
                            </div>
                            <div className="py-2">
                                <label>Product Image</label>
                                <Row>
                                    {inventoryData.image == "" ? (
                                        <Form.Group className="" controlId="Pan Card">
                                            <div className="form-group py-2 position-relative">
                                                <input
                                                    type="file"
                                                    name="image"
                                                    onChange={(e) => handleInventorychange(e, "image")}
                                                    isInvalid={inventoryDataError?.image}
                                                    className="opacity0 position-absolute placeholder-light form-control py-2 w-100 h-100  px-3"
                                                />
                                                <div
                                                    className={`d-flex align-items-center justify-content-center flex-column border rounded text-center ${Boolean(inventoryDataError?.image) &&
                                                        "border-danger"
                                                        } `}
                                                    style={{ height: "200px" }}
                                                >
                                                    <div>
                                                        <img src={placeholder} />{" "}
                                                        <span style={{ color: "#A3A3A3" }}>
                                                            Upload Photos
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form.Group>
                                    ) : (
                                        <div className="image">
                                            <img
                                                src={
                                                    inventoryData.viewImage
                                                        ? URL.createObjectURL(inventoryData.viewImage)
                                                        : ""
                                                }
                                                alt=""
                                                className="rounded shadow image g-0"
                                                style={{
                                                    width: "100%",
                                                    height: "200px",
                                                    objectFit: "cover",
                                                }}
                                            />
                                            <div
                                                className="image-containerr"
                                                onClick={() =>
                                                    setInventoryData({ ...inventoryData, viewImage: "" })
                                                }
                                            >
                                                {" "}
                                            </div>
                                        </div>
                                    )}
                                </Row>
                                <Row>
                                    <div className=" mb-3 g-0 d-flex justify-content-center position-relative align-items-center">
                                        <input
                                            type="file"
                                            name="image"
                                            onChange={(e) => handleInventorychange(e, "image")}
                                            isInvalid={inventoryDataError?.image}
                                            className="opacity0 position-absolute placeholder-light form-control py-2 w-100 h-100 px-3"
                                        />
                                    </div>
                                    <div>
                                        <div className="card overflow-auto multiple-image-height">
                                            <div style={{ display: "flex", flexWrap: "nowrap" }}>
                                                {inventoryData.image ? (
                                                        <div  style={{ margin: "10px" }}>
                                                            <img
                                                                src={inventoryData.image}
                                                                alt={`Product image `}
                                                                className="rounded shadow image"
                                                                style={{
                                                                    width: "100px",
                                                                    height: "100px",
                                                                    objectFit: "cover",
                                                                }}
                                                            />
                                                           
                                                        </div>
                                                    ):""}

                                            </div>
                                        </div>
                                    </div>
                                </Row>
                            </div> */}
                        </div>
                        <div className="form-group py-2 pb-3">
                            <Button
                                variant="primary"
                                type="submit"
                                size="lg"
                                id="btnRegister"
                                onClick={handleSubmitAddInventory}
                            >
                                Update Inventory
                            </Button>
                        </div>
                    </div>
                </Row>
            </Form >
        </MainTemplate >
    );
}

export default Addinventory;
