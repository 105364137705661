import './BannerList.css'
import React, { useEffect, useState, useMemo } from "react";
import ReactPaginate from "react-paginate";
import { Link, useLocation } from "react-router-dom";
import "../HomepageModifier/BannerList.css";
import Modal from "react-modal";
import { deleteTokenApi, getApi, getTokenApi, postTokenApi, putTokenApi } from '../../api_interface/apis';
import { BANNER_PATH, HIBEE_ADMIN_URL, HOME_CAROUSEL, HOME_CAROUSEL_BANNER_VISIBILITYHIBEE_ADMIN_URL, ADMIN, HOMEPAGE_SECTION, HOMEPAGE_VISIBILITY, HOME_CAROUSEL_BANNER_VISIBILITY, } from '../../api_interface/apiURLs';
import { customActionModalStyles, customModalStyles, debounce } from '../../utils/helperFunctin';
import MainTemplate from '../../components/MainTemplate';
import { toast } from "react-toastify";

function CarouselBannerList() {
    const location = useLocation();
    const { state } = location || {}

    const [banner, setbanner] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);
    const [loader, setLoader] = useState(false);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [modal, setModal] = useState(false);
    const [sort, setSort] = useState("")
    const [query, setQuery] = useState("")
    const [bannerData, setBannerData] = useState({
        banner_name: "",
        title: "",
        description: "",
        link: "",
        image: "",
        CarousalId: "",
        list: ""
    });
    const [actionModal, setActionModal] = useState({
        loader: false,
        show: false,
        type: "",
        data: {},
    });

    const [updateType, setUpdateType] = useState(true)
    const [aprove, setAprove] = useState({
        status: "",
        index: ""
    })


    const [bannerListLoader, setNotifiicationListLoader] = useState(false)
    const [img1, setImg1] = useState('')
    const endOffset = itemOffset + itemsPerPage;
    const pageCount = Math.ceil(banner.length / itemsPerPage);
    const currentItems = bannerData.list.slice(itemOffset, endOffset);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % banner.length;
        setItemOffset(newOffset);
    };

    const getBanner = async search => {
        setNotifiicationListLoader(true)
        const res = await getTokenApi(HIBEE_ADMIN_URL + BANNER_PATH + `?sort=${sort}&bannersearch=${search || query || ""}`);
        if (res.status == 200) {
            console.log(res.data);
            setNotifiicationListLoader(false)
            setbanner(res?.data?.data.reverse());
        }
    };

    const Submit = async (e) => {
        e.preventDefault();
        setLoader(true);
        const { banner_name,
            title,
            description,
            link,
            image,
            _id } = bannerData;
        const fd = new FormData();
        fd.append("banner_name", banner_name);
        fd.append("title", title);
        fd.append("description", description);
        fd.append("link", link);
        fd.append("image", image);
        if (_id) {
            fd.append("_id", _id);
        }
        const res = await postTokenApi(HIBEE_ADMIN_URL + BANNER_PATH, fd, { "Content-Type": "multipart/form-data" });
        if (res.status == 200) {
            setModal(false);
            setLoader(false);
            getBanner();
        }
    };

    const deleteOffer = async (id) => {
        const res = await deleteTokenApi(HIBEE_ADMIN_URL + BANNER_PATH + `/${id}`);
        setActionModal({});
        getBanner();
    };

    const archiveOffer = (cat) => {
        setActionModal({ ...actionModal, loader: true });

        setActionModal({});
        getBanner();
        // setActionModal({...actionModal, show:true})
    };

    /** Banners List **/
    const handleGetBannersList = async (Id) => {
        const reponce = await getApi(HIBEE_ADMIN_URL + HOME_CAROUSEL + `?carousalId=${Id}`)
        if (reponce?.status == 200) {
            setBannerData({ ...bannerData, list: reponce?.data?.data[0]?.banner })
        }
    }

    const approveAdmin = async (ele, id) => {
        setAprove({ ...aprove, status: ele, index: id })
        try {
            const res = await putTokenApi(HIBEE_ADMIN_URL + HOME_CAROUSEL_BANNER_VISIBILITY + `?id=${id}&status=carousalbanner&visibility=${ele}`)
            if (res.status == 200) {
                if (res?.data?.status == 'approved') {
                    toast.success(res?.data?.message, {
                        position: "top-center",
                        autoClose: 2000,
                    });
                }
                if (res?.data?.status == 'disapproved') {
                    toast.error(res?.data?.message, {
                        position: "top-center",
                        autoClose: 2000,
                    });
                }
                handleGetBannersList()
            }
        } catch (err) {
            toast.error(err?.response?.data?.message, {
                position: "top-center",
                autoClose: 2000,
            })
            handleGetBannersList()
        }
    }

    const updateSort = e => {
        setSort(e.target.value)
    }

    const getSearchedData = useMemo(() => debounce(async (search) => {
        getBanner(search)
    }, 500), [])

    useEffect(() => {
        getBanner()
    }, [sort])

    useEffect(() => {
        getSearchedData(query)
    }, [query])



    useEffect(() => {
        if (state?.data) {
            // setBannerData({
            //     ...bannerData,
            //     CarousalId: state?.data,
            // })
            localStorage.setItem('CarousalId', JSON.stringify(state.data));
        }
    }, [])


    useEffect(() => {
        const savedStateData = localStorage.getItem('CarousalId');
        if (savedStateData) {
            const parsedData = JSON.parse(savedStateData);
            setBannerData({
                ...bannerData,
                CarousalId: parsedData,
            });
            handleGetBannersList(state?.data)
        }
    }, [])

    return (
        <MainTemplate
            categoryName="Banner Listing"
            categoryList={["Admin", "Carousel"]}
        >
            <div className="row mb-4 rounded p-2 bg-white">
                <div className="col-sm-6 my-3 col-md-2">
                    <select className="t-12 form-select" aria-label="Default select example" onChange={updateSort}>
                        <option value="" selected>Sort by</option>
                        <option value="name">Alphabatically</option>
                        <option value="dateold">Date: Acsending</option>
                        <option value="datenew">Date: Descending</option>
                    </select>
                </div>
                <div className="col-sm-6 my-3 col-md-2">
                    <select className="t-12 form-select" aria-label="Default select example" onChange={e => setItemsPerPage(e.target.value)}>
                        <option value="10" selected>10 per page</option>
                        <option value="20">20 per page</option>
                        <option value="50">50 per page</option>
                        <option value="100">100 per page</option>
                    </select>
                </div>
                {/* <div className="col-sm-2 my-3">
                    <select className="t-12 form-select" aria-label="Default select example"
                        onChange={e => setUpdateType(e.target.value === "true" ? true : false)}
                    >
                        <option value={true} selected>Active</option>
                        <option value={false}>Inactive</option>
                    </select>
                </div> */}
                <div className="col-sm-6 my-3 col-md-3">
                    {/* <input type="text" name="query" className="t-12 form-control" autoComplete='off' placeholder="Search" value={query} onChange={e => setQuery(e.target.value)} /> */}
                </div>

                <div className="col-sm-6 my-3 col-md-2 offset-2 mobile-addbanner">
                    <Link
                        to="/homepage-modifier-carousel-add-banners"
                        state={{ data: bannerData?.CarousalId }}
                        className="text-dark px-3 py-1 rounded"
                        style={{ textDecoration: "none" }}
                    >
                        <button
                            type="button"
                            className="t-12 w-100 btn  text-warning"
                            style={{ backgroundColor: "#12352f" }}
                        >
                            Create Banner
                        </button>
                    </Link>
                    {/* // } */}
                </div>
                {/* <div>
                    <p className="homepagemodifieraddedproductslable p-0 m-0 py-1">
                        Carousel Name
                    </p>
                    <input
                        type="text"
                        className="homepagemodifieraddedproductsinput "
                        placeholder="eg: New launch"
                    />
                </div> */}

                {/* Update Banner */}

                <Modal
                    isOpen={modal}
                    onRequestClose={() => {
                        setModal(false);
                        setBannerData({});
                        setLoader(false);
                    }}
                    style={customModalStyles}
                    contentLabel="Example Modal"
                    ariaHideApp={false}
                >
                    <div className="container containerbg overflow-auto rounded bg-white row w-100 ">
                        <div className="px-3 py-3">
                            <div className="row">
                                <span className="d-flex justify-content-end cursorPointer">
                                    <svg
                                        onClick={() => {
                                            setModal(false);
                                            setBannerData({});
                                            setLoader(false);
                                        }}
                                        className="d-inline"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                                    </svg>
                                </span>
                            </div>
                            <h5 className=" mt-4 text-center">Update Carousel Banner</h5>
                            <div className="row">
                                <div className="form-group my-3">
                                    <label className="my-2">Banner Name</label>
                                    <input
                                        value={bannerData?.banner_name}
                                        onChange={(e) =>
                                            setBannerData({ ...bannerData, banner_name: e.target.value })
                                        }
                                        type="text"
                                        className="placeholder-light form-control my-2 py-2 px-3"
                                        placeholder="eg: Hibee"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group my-3">
                                    <label className="my-2">Description</label>
                                    <input
                                        type="text"
                                        value={bannerData?.description}

                                        onChange={(e) =>
                                            setBannerData({ ...bannerData, description: e.target.value })
                                        }
                                        className="placeholder-light form-control my-2 py-2 px-3"
                                        placeholder="eg: 20"
                                    />
                                </div>
                            </div>

                            {/* <div className="row">
                                <div className="form-group my-3">
                                    <label className="my-2">Title</label>
                                    <input
                                        type="text"
                                        value={bannerData?.title}
                                        onChange={(e) =>
                                            setBannerData({ ...bannerData, title: e.target.value })
                                        }
                                        className="placeholder-light form-control my-2 py-2 px-3"
                                        placeholder="eg: 20"
                                    />
                                </div>
                            </div> */}

                            <div className="row">
                                <div className="form-group my-3">
                                    <label className="my-2">Link</label>
                                    <input
                                        type="text"
                                        value={bannerData?.link}
                                        onChange={(e) => setBannerData({ ...bannerData, link: e.target.value })}
                                        className="placeholder-light form-control my-2 py-2 px-3"
                                        placeholder="eg: 20"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group py-2  mb-4 position-relative">
                                    <label>Select Image</label>
                                    <input
                                        type="file"
                                        name="image"
                                        className="homepageModifierInput opacity0 position-absolute w-75 placeholder-light form-control py-2 px-3"
                                        placeholder="eg: https://www.apple.com/in/watch"
                                        onChange={(e) => { setBannerData({ ...bannerData, image: e?.target?.files?.[0] }); setImg1(e?.target?.files?.[0]) }}
                                    />
                                    <div className={`homepageModifierInput select-text-light d-flex align-items-center justify-content-center border rounded w-75}`} >
                                        {
                                            bannerData?.image ? (
                                                <img
                                                    src={img1 == '' ? bannerData?.image : URL.createObjectURL(img1)}
                                                    className="rounded h-100 w-100 shadow"
                                                    alt="img"
                                                />
                                            ) : (
                                                <>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        class="bi bi-cloud-arrow-up"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path
                                                            fill-rule="evenodd"
                                                            d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z"
                                                        />
                                                        <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                                                    </svg>
                                                    <span className="px-2">Upload Photo</span>
                                                </>
                                            )}
                                    </div>
                                </div>
                            </div>

                            <div className="form-group my-3">
                                <button
                                    disabled={loader}
                                    className="btn btn-dark text-warning px-5"
                                    onClick={Submit}
                                >
                                    {loader ? (
                                        <div class="spinner-border spinner-border-sm" role="status">
                                            <span class="sr-only"></span>
                                        </div>
                                    ) : (
                                        "Submit"
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal
                    isOpen={actionModal?.show}
                    onRequestClose={() => setActionModal({})}
                    style={customActionModalStyles}
                    contentLabel="Action Modal"
                    ariaHideApp={false}
                >
                    <div className="px-3 py-3">
                        <div className="row">
                            <span className="d-flex justify-content-end cursorPointer">
                                <svg
                                    onClick={() => setActionModal({})}
                                    className="d-inline"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                                </svg>
                            </span>
                        </div>
                        <div className="row">
                            <h4 className="text-danger d-flex justify-content-center">
                                Do you want to {actionModal?.type} the banner?
                            </h4>
                        </div>
                        <div className="row my-5">
                            <h5 className="d-flex justify-content-center">
                                {actionModal?.data?.name}
                            </h5>
                        </div>
                        <div className="form-group my-3 d-flex justify-content-center">
                            <button
                                disabled={actionModal?.loader}
                                className="btn btn-dark me-3 mb-2 text-warning px-5"
                                onClick={() =>
                                    actionModal?.type === "DELETE"
                                        ? deleteOffer(actionModal?.data?._id)
                                        : archiveOffer(actionModal?.data?._id)
                                }
                            >
                                {actionModal?.loader ? (
                                    <div class="spinner-border spinner-border-sm" role="status">
                                        <span class="sr-only"></span>
                                    </div>
                                ) : (
                                    "Submit"
                                )}
                            </button>
                            <button
                                disabled={actionModal?.loader}
                                className="btn btn-light me-3 mb-2 shadow-sm text-dark border px-5"
                                onClick={() => setActionModal({})}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </Modal>

            </div>
            <div className="row rounded px-3 bg-white">
                {/* header */}
                <div className="row m-0">
                    <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
                        Image
                    </div>
                    <div className="font-weight-bold t-12 text-uppercase my-3 col-md-2">
                        Banner Name
                    </div>

                    <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-3">
                        Deep Link
                    </div>
                    <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
                        Edit
                    </div>
                    <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
                        Active / Inactive
                    </div>
                </div>

                <div className="m-0 p-0 pe-3 overflow-auto caterogyPageScroll">
                    {
                        bannerListLoader == false && currentItems.length != 0 ? currentItems?.map((el) => {
                            return <div
                                className="row border rounded m-0 px-2  mb-2 d-flex align-items-center"
                                key={el?._id}
                            >
                                <div className="col-md-2 my-4">
                                    <img
                                        src={el?.Image}
                                        className="rounded"
                                        style={{ width: "124px", height: "46px" }}
                                    />
                                </div>
                                <div className="col-md-2 my-4">
                                    {/* <Link
                                to="/homepage-modifier"
                                state={{ page: 'BANNER', eidtData: el }}
                            > */}
                                    {el.name}
                                    {/* </Link> */}
                                </div>
                                <div className="col-md-3 my-4">{el.Link}</div>
                                <div className="t-12 text-uppercase my-3 col-md-2">
                                    <Link
                                        to="/homepage-modifier-carousel-add-banners"
                                        state={{ page: 'BANNER', eidtData: el }}
                                        className="t-12 text-uppercase my-3 col-md-2">
                                        <svg
                                            className="bi bi-pencil border-bottom border-dark text-dark cursorPointer"
                                            // onClick={() => {
                                            //   setSocietyModal(true);
                                            //   setcategory(el);
                                            // }}
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                        </svg>
                                    </Link>
                                </div>
                                <div className="t-12 text-uppercase my-3 col-md-1">
                                    <label className="switch">
                                        <input className="switch-input" type="checkbox"
                                            checked={el.visiblity}
                                            onChange={e => e.target.checked ? approveAdmin(e.target.checked, el?._id) : approveAdmin(e.target.checked, el?._id)}
                                        />
                                        <span className="switch-label"></span>
                                        <span className="switch-handle"></span>
                                    </label>
                                </div>
                                {/* <div className="col my-3 dotsize">
                            <div className="dropdown">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    data-menu-target={`drop-down-menu-${el?._id}`}
                                    className="bi bi-three-dots dropdown-btn cursorPointer"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                                </svg>

                                <div
                                    id={`drop-down-menu-${el?._id}`}
                                    className="dropdown-content p-2 mobile-dropdown7"
                                >
                                    <div
                                        className="px-3 py-2 d-flex cursorPointer"
                                        onClick={() =>
                                            setActionModal({
                                                ...actionModal,
                                                show: true,
                                                data: el,
                                                type: "DELETE",
                                            })
                                        }
                                    >
                                        <span className="mx-2 d-flex align-items-center">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-trash3"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                            </svg>
                                        </span>

                                        <span className="mx-2">
                                            <div className="font-weight-bold">Delete</div>
                                            <div className="">Delete this banner</div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                            </div>
                        })
                            :
                            <div className="text-center p-5 homepageModifierCategoryScroll">
                                <div className="" style={{ marginTop: "150px" }} >
                                    <b className="empty-product-list" >No banner have been added to this Carousel</b>
                                </div>
                            </div>

                    }
                    {bannerListLoader == true &&
                        <div className="text-center mt-5" >
                            <button class="btn mt-5" style={{ backgroundColor: '#FFEDC2' }} type="button">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{" "}
                                Loading...
                            </button>
                        </div>
                    }
                </div>
            </div>
            <div className="row m-2 d-flex justify-content-center align-items-center">
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    pageCount={pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    pageClassName="page-item mb-0"
                    subContainerClassName="pages "
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    marginPagesDisplayed={2}
                    containerClassName="pagination"
                    activeClassName="active"
                />
            </div>
        </MainTemplate>
    );
}

export default CarouselBannerList;
