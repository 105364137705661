import React, { useEffect, useState } from "react";
import "./HomepageModifier.css";
import MainTemplate from "../../components/MainTemplate";
import { useLocation, useNavigate } from "react-router-dom";
import { getApi, postApi, postTokenApi } from "../../api_interface/apis";
import { HIBEE_ADMIN_URL, HOME_CAROUSEL } from "../../api_interface/apiURLs";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

function HomepageModifierCarouselAddBanners() {
	const location = useLocation();
	const { state } = location || {}
	const [carouselForm, setCarouserForm] = useState({ title: "", id: "", loader: false })
	const [carouselFormError, setCarouserFormError] = useState({ title: "", id: "", loader: false })

	const [bannerForm, setBannerForm] = useState({ name: "", image: "", link: "", editImage: "", loader: false, id: "" })
	const [bannerFormError, setBannerFormError] = useState({ name: "", image: "", link: "", editImage: "", loader: false, id: "" })
	const navigate = useNavigate()


	/** Carouser **/
	const handleChangeCarousel = (ele) => {
		setCarouserForm({ ...carouselForm, [ele.target.name]: ele.target.value })
		setCarouserFormError({ ...carouselFormError, [ele.target.name]: null })
	}

	const handleValidationCarousel = () => {
		const newErrors = {}
		if (carouselForm?.title?.trim() == "") {
			newErrors.title = "Please carousel name"
		}
		return newErrors
	}

	const handleSubmitCarousel = async () => {
		const handleValidationObject = handleValidationCarousel()
		if (Object.keys(handleValidationObject).length > 0) {
			setCarouserFormError(handleValidationObject)
		} else {
			setCarouserForm({ ...carouselForm, loader: true })
			try {
				const formData = new FormData()
				formData.append('title', carouselForm.title)
				if (carouselForm?.id) {
					formData.append("status", "updateCarousal")
				}
				if (carouselForm?.id) {
					formData.append('carousalId', carouselForm.id)
				}
				const reponce = await postApi(HIBEE_ADMIN_URL + HOME_CAROUSEL, formData, { "Content-Type": "multipart/form-data" })
				if (reponce?.status == 200) {
					console.log(reponce, 'reponcereponce')
					toast.success(reponce.data.message);
					setCarouserForm({ ...carouselForm, loader: false, title: "" })
					navigate('/carousellistingpage')
				}
			} catch (err) {
				const message = err?.response?.data?.message || "Something went wrong";
				toast.error(message, { position: "top-center", autoClose: 2000 });
				setCarouserForm({ ...carouselForm, loader: false, title: "" })
			}
		}
	}


	/** Banner **/
	const handleChangeBanner = (ele) => {
		if (ele?.target?.name == 'image') {
			setBannerForm({ ...bannerForm, [ele.target.name]: ele.target.files[0], editImage: "" })
			setBannerFormError({ ...bannerFormError, [ele.target.name]: null })
		} else {
			setBannerForm({ ...bannerForm, [ele.target.name]: ele.target.value })
			setBannerFormError({ ...bannerFormError, [ele.target.name]: null })
		}

	}

	const handleValidationBanner = () => {
		const newErrors = {}
		if (bannerForm?.name?.trim() == "") {
			newErrors.name = "Please enter banner name"
		}
		if (bannerForm?.image == "" && bannerForm?.editImage == "") {
			newErrors.image = "Please select banner"
		}
		if (bannerForm?.link?.trim() == "") {
			newErrors.link = "Please enter banner link"
		}
		return newErrors
	}


	const handleSubmitBanner = async () => {
		const handleValidationObject = handleValidationBanner()
		if (Object.keys(handleValidationObject).length > 0) {
			setBannerFormError(handleValidationObject)
		} else {
			setBannerForm({ ...bannerForm, loader: true })
			const savedStateData = localStorage.getItem('CarousalId');
			const saveDataParsing = JSON.parse(savedStateData)
			try {
				console.log('ddddd');
				const fd = new FormData();
				fd.append("bannerName", bannerForm.name)
				fd.append("image", bannerForm.image)
				fd.append("Link", bannerForm.link)
				if (bannerForm?.id) {
					fd.append("status", "update")
				} else {
					fd.append("status", true)
				}
				fd.append("carousalId", saveDataParsing)
				if (bannerForm?.id) {
					fd.append("bannerId", bannerForm?.id)
				}

				const reponce = await postTokenApi(HIBEE_ADMIN_URL + HOME_CAROUSEL, fd, { "Content-Type": "multipart/form-data" })
				if (reponce?.status == 200) {
					console.log(reponce, 'reponcereponce')
					toast.success(reponce.data.message);
					setBannerForm({ ...bannerForm, loader: false, name: "", image: "" })
					navigate('/carousellistingpage')
				}
			} catch (err) {
				const message = err?.response?.data?.message || "Something went wrong";
				toast.error(message, { position: "top-center", autoClose: 2000 });
				setCarouserForm({ ...carouselForm, loader: false, name: "", image: "" })
			}
		}
	}


	useEffect(() => {
		if (state && state?.eidtData) {
			setBannerForm(prevState => ({
				...prevState,
				name: state?.eidtData?.name,
				editImage: state?.eidtData?.Image,
				link: state?.eidtData?.Link,
				id: state?.eidtData?._id
			}));
			setCarouserForm({
				...carouselForm,
				title: state?.eidtData?.title,
				id: state?.eidtData?._id
			})
		}
	}, [state]);


	return (
		<div>
			{
				state && state?.page == 'CAROUSEL'
					?
					<div>
						<MainTemplate categoryName="Add Carousel" categoryList={["Homepage Modifier", "Carousel> Add Carousel"]}>
							<div className="row mb-4 rounded bg-white homepageModifierPadding">
								<div className="form-group py-2">
									<label className="">Carousel Name{" "}<span className="text-danger" >*</span></label>
									<input type="text" name='title'
										value={carouselForm.title} onChange={handleChangeCarousel} className={`w-75 placeholder-light form-control py-2 px-3 mt-2 ${carouselFormError?.title && "border-danger"}`} placeholder="eg: New Launch" />
									<span className="text-danger" >{carouselFormError?.title}</span>
								</div>
								<div className="form-group py-2" style={{ marginTop: '100px' }}>
									<button style={{ backgroundColor: "#12352f" }} className="btn text-warning px-5" onClick={handleSubmitCarousel}>Save{" "}
										{
											carouselForm.loader
												?
												<Spinner
													animation="border"
													variant="light"
													size="sm"
												/>
												:
												''
										}
									</button>
								</div>
							</div>
						</MainTemplate>
					</div>
					:
					<div>
						<MainTemplate categoryName="Add Banner" categoryList={["Homepage Modifier", "Carousel> Add Banner"]}>
							<div className="row mb-4 rounded bg-white homepageModifierPadding">
								<div className="form-group py-2">
									<label>Banner Name <span className="text-danger" >*</span></label>
									<input autoComplete="off" value={bannerForm.name} name="name" type="text" className={`w-75 placeholder-light form-control py-2 px-3 ${bannerFormError.name && "border-danger"}`} placeholder="eg: New Launch" onChange={handleChangeBanner} />
									<span className="text-danger" >{bannerFormError.name}</span>
								</div>

								{/* <div className="form-group py-2  mb-4 position-relative">
									<label>Select Image</label>
									<input type="file" name='image' className={`homepageModifierInput opacity0 position-absolute w-75 placeholder-light form-control py-2 px-3`} placeholder="eg: https://www.apple.com/in/watch" onChange={handleChangeBanner} />
									<div className={`homepageModifierInput select-text-light d-flex align-items-center justify-content-center border  rounded w-75  ${bannerFormError.image && "border-danger"}`}>
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cloud-arrow-up" viewBox="0 0 16 16">
											<path fill-rule="evenodd" d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z" />
											<path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
										</svg>
										<span className="px-2">Upload your image file</span>
									</div>
									<span className="text-danger" >{bannerFormError.image}</span>
								</div> */}

								<div className="form-group py-2  mb-4 position-relative">
									<label>Select Image  <span className="text-danger" >*</span></label>
									<input
										type="file"
										name="image"
										className="homepageModifierInput opacity0 position-absolute w-75 placeholder-light form-control py-2 px-3 "
										placeholder="eg: https://www.apple.com/in/watch"
										onChange={handleChangeBanner}
									// 
									/>

									<div className={`homepageModifierInput select-text-light d-flex align-items-center justify-content-center border rounded w-75  ${bannerFormError?.image && "border-danger"}`}
									>
										{
											bannerForm?.image || bannerForm?.editImage ? (
												<div
													style={{
														width: "100%",
														height: "100%",
														objectFit: "cover",
														borderRadius: '5%'
													}}
												>
													{
														bannerForm?.id && bannerForm?.editImage
															?
															<img
																src={bannerForm?.editImage ? bannerForm?.editImage : ""}
																className="rounded h-100 w-100 shadow"
																style={{
																	width: "100%",
																	height: "100%",
																	objectFit: "cover",
																	borderRadius: '5%'
																}}
																alt="img"
															/>
															:
															<img
																src={bannerForm?.image ? URL.createObjectURL(bannerForm?.image) : ""}
																className="rounded h-100 w-100 shadow"
																style={{
																	width: "100%",
																	height: "200px",
																	objectFit: "cover",
																	borderRadius: '5%'
																}}
																alt="img"
															/>
													}
												</div>
											) : (
												<>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="16"
														height="16"
														fill="currentColor"
														class="bi bi-cloud-arrow-up"
														viewBox="0 0 16 16"
													>
														<path
															fill-rule="evenodd"
															d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z"
														/>
														<path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
													</svg>
													<span className="px-2">Upload Photo</span>
												</>
											)}
									</div>
									<span className="text-danger">{bannerFormError?.image}</span>
								</div>

								<div className="form-group py-2">
									<label>Deep Link  <span className="text-danger" >*</span></label>
									<input name='link' value={bannerForm.link} type="text" className={`w-75 placeholder-light form-control py-2 px-3  ${bannerFormError.link && "border-danger"}`} placeholder="eg: https://www.apple.com/in/watch" onChange={handleChangeBanner} />
									<span className="text-danger" >{bannerFormError.link}</span>
								</div>

								<div className="form-group py-2">
									<button className="btn text-warning px-5"
										style={{ backgroundColor: "#12352f" }}
										onClick={handleSubmitBanner} >Save{" "}
										{
											bannerForm?.loader
												?
												<Spinner
													animation="border"
													variant="light"
													size="sm"
												/>
												:
												''
										}
									</button>
								</div>
							</div>
						</MainTemplate>
					</div>
			}
		</div>
	);
}

export default HomepageModifierCarouselAddBanners;