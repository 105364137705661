import React from "react";
import "./ProductEdit.css";
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { getApi } from "../../api_interface/apis";
import { HIBEE_PRODUCT_URL, PRODUCT_PATH } from "../../api_interface/apiURLs";
import MainTemplate from "../../components/MainTemplate";


function ProductEdit() {
  const { adminId } = useParams();

  const [list, setList] = useState({});

  const getProduct = async (id) => {
    const res = await getApi(HIBEE_PRODUCT_URL + PRODUCT_PATH + `/${id}`);
    setList(res?.data?.data?.[0]);
  };

  useEffect(() => {
    getProduct();
  }, []);

  return (
    <MainTemplate
      categoryName="Product"
      categoryList={["Product", "Product Edit"]}
    >
      <div className="row mb-4 rounded bg-white homepageModifierPadding mb-4">
        <div className="col-lg-5 mb-4">
          Image
          <div className="w-50 adminImage border rounded mb-3">
            <img
              className="w-100 h-100 rounded"
              style={{ objectFit: "cover" }}
              src={list?.image}
              alt="img"
            />
          </div>
          <div className="form-group pb-2">
            <label className="font-weight-bold">Name</label>
            <p>{list?.product_name}</p>
          </div>
          <div className="form-group pb-2">
            <label className="font-weight-bold">Unique Code</label>

            <p>{list?.product_code}</p>
          </div>
          <div className="form-group pb-2">
            <label className="font-weight-bold">Varient</label>

            <p>{list.product_variant}</p>
          </div>
          <div className="form-group pb-2">
            <label className="font-weight-bold">Mfg Date</label>
            <p>{list.manufacturing_date}</p>
          </div>
          <div className="form-group pb-2">
            <label className="font-weight-bold">expiry date</label>
            <p>{list.expiration_date}</p>
          </div>
          <div className="form-group pb-2">
            <label className="font-weight-bold">Warehouse</label>
            <p>{list.warehouse}</p>
          </div>
          <div className="form-group pb-2">
            <label className="font-weight-bold">category</label>
            <p>{list.category}</p>
          </div>
          <div className="form-group pb-2">
            <label className="font-weight-bold">subcategory</label>
            <p>{list.subcategory}</p>
          </div>
          <div className="form-group pb-2">
            <label className="font-weight-bold">Price</label>
            <p>{list.price}</p>
          </div>
          <div className="form-group pb-2">
            <label className="font-weight-bold">Cutout Price</label>
            <p>{list.cutout_price}</p>
          </div>
          <div className="form-group pb-2">
            <label className="font-weight-bold">commission</label>
            <p>{list.commission}</p>
          </div>
          <div className="form-group pb-2 d-flex"></div>
        </div>

        <div className="col-lg-10 d-flex justify-content-end">
          <Link
            to="/inventory-product-add"
            state={{ data: list, edit:true }}
            className="text-dark px-3 py-1 rounded"
            style={{ textDecoration: "none" }}
          >
            <span className="cursorPointer">
              <svg
                className="bi bi-pencil border-bottom border-dark"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
              </svg>
              <span className="mx-1">Edit</span>
            </span>
          </Link>
        </div>
      </div>
    </MainTemplate>
  );
}

export default ProductEdit;
