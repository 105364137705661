import './FeedbackList.css'
import React, { useEffect, useState, useMemo } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import StarRating from './StarRating';
import { getApi } from '../../api_interface/apis';
import { FEDBACK_GET_API, HIBEE_ORDER_URL } from '../../api_interface/apiURLs';
import { debounce } from '../../utils/helperFunctin';
import MainTemplate from '../../components/MainTemplate';

function FeedbackList() {
    const [feedback, setfeedback] = useState([]);
    const [itemOffset, setItemOffset] = useState(0);
    const [loader, setLoader] = useState(false);
    const [modal, setModal] = useState(false);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [feedbackData, setFeedbackData] = useState({
        title: "",
        push_datetime: "",
        push_date: "",
        push_time: "",
        description: "",
        image: "",
    });
    const [sort, setSort] = useState("")
    const [query, setQuery] = useState("")
    const [actionModal, setActionModal] = useState({
        loader: false,
        show: false,
        type: "",
        data: {},
    });
    const [feedbackListLoader, setfeedbackloader] = useState(false)
    const [img1, setImg1] = useState('')
    const endOffset = itemOffset + itemsPerPage;
    const pageCount = Math.ceil(feedback.length / itemsPerPage);
    const currentItems = feedback.slice(itemOffset, endOffset);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % feedback.length;
        setItemOffset(newOffset);
    };

    const getfeedback = async search => {
        setfeedbackloader(true)
        const res = await getApi(HIBEE_ORDER_URL + FEDBACK_GET_API + `?sort=${sort}&search=${search || query || ""}`);
        if (res.status == 200) {
            setfeedbackloader(false)
            setfeedback(res?.data?.data.reverse());
        }
    };

    const updateSort = e => {
        setSort(e.target.value)
    }

    const getSearchedData = useMemo(() => debounce(async (search) => {
        getfeedback(search)
    }, 500), [])

    useEffect(() => {
        getfeedback()
    }, [sort])

    useEffect(() => {
        getSearchedData(query)
    }, [query])

    return (
        <MainTemplate
            categoryName="feedback Listing"
            categoryList={["Admin", "feedback listing"]}
        >
            {/* sorting */}
            <div className="row mb-4 rounded p-2 bg-white">
                <div className="col-sm-6 my-3 col-md-2">
                    <select className="t-12 form-select" aria-label="Default select example" onChange={updateSort}>
                        <option value="" selected>Sort by</option>
                        <option value="name">Alphabatically</option>
                        <option value="dateold">Date: Acsending</option>
                        <option value="datenew">Date: Descending</option>
                    </select>
                </div>
                <div className="col-sm-6 my-3 col-md-2">
                    <select className="t-12 form-select" aria-label="Default select example" onChange={e => setItemsPerPage(e.target.value)}>
                        <option value="10" selected>10 per page</option>
                        <option value="20">20 per page</option>
                        <option value="50">50 per page</option>
                        <option value="100">100 per page</option>
                    </select>
                </div>
                <div className="col-sm-6 my-3 col-md-3">
                    <input type="text" name="query" className="t-12 form-control" placeholder="Search" value={query} onChange={e => setQuery(e.target.value)} />
                </div>
            </div>

            {/* table */}
            <div className="row flex-column align-items-center">
                <div className="row rounded text-center bg-white">
                    {/* header */}
                    <div className="row m-0">
                        <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
                            FEEDBACK ID
                        </div>
                        <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
                            FULLNAME
                        </div>
                        <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
                            RATING
                        </div>
                        <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
                            FRANCHISE NO.
                        </div>
                        <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
                        </div>
                    </div>
                    {/* body */}
                    <div className="justify-content-center align-items-center overflow-auto caterogyPageScroll">
                        {
                            feedbackListLoader == false && currentItems.map((el) => (
                                <div
                                    className="row border rounded m-0 mb-2 d-flex align-items-center"
                                    key={el?._id}
                                >
                                    <div className="t-12 text-uppercase my-3 col-md-2">#{el.feedback_id}</div>
                                    <div className="t-12 text-uppercase my-3 col-md-2">
                                        <>
                                            <Link
                                                // to={`/feedback/${el?._id}`}
                                                to={`/fedback-internal/${el?._id}`}
                                                state={{ data: el }}
                                                className="text-dark px-3 py-1 rounded"
                                                style={{ textDecoration: "none" }}
                                            >
                                                {el.username}
                                            </Link>
                                        </>
                                    </div>
                                    <div className="text-uppercase my-3 col-md-2">
                                        <StarRating rating={el.rating} />
                                    </div>
                                    <div className="t-12 text-uppercase my-3 col-md-2">#67686564</div>
                                    <div className="col my-4 dotsize">
                                        <div className="dropdown">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                data-menu-target={`drop-down-menu-${el?._id}`}
                                                className="bi bi-three-dots dropdown-btn cursorPointer"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                                            </svg>

                                            <div
                                                id={`drop-down-menu-${el?._id}`}
                                                className="dropdown-content p-2 mobile-dropdown8"
                                            >
                                                <div
                                                    className="px-3 py-2 d-flex cursorPointer"
                                                    onClick={() =>
                                                        setActionModal({
                                                            ...actionModal,
                                                            show: true,
                                                            data: el,
                                                            type: "DELETE",
                                                        })
                                                    }
                                                >
                                                    <span className="mx-2 d-flex align-items-center">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="16"
                                                            height="16"
                                                            fill="currentColor"
                                                            class="bi bi-trash3"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                                                        </svg>
                                                    </span>

                                                    <span className="mx-2">
                                                        <div className="font-weight-bold">Delete</div>
                                                        <div className="">Delete this feedback</div>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        {
                            feedbackListLoader == true &&
                            <div className="text-center mt-5" >
                                <button class="btn mt-5" style={{ backgroundColor: '#FFEDC2' }} type="button">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{" "}
                                    Loading...
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="row m-2 d-flex justify-content-center align-items-center">
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    pageCount={pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    pageClassName="page-item mb-0"
                    subContainerClassName="pages "
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    marginPagesDisplayed={2}
                    containerClassName="pagination"
                    activeClassName="active"
                />
            </div>
        </MainTemplate>
    );
}

export default FeedbackList;
