import React from "react";
// import "./AdminDetail.css";
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import MainTemplate from "../../components/MainTemplate";
import { getApi, putApi } from "../../api_interface/apis";
import { ADMIN_USER, CHANGE_PASSWORD, HIBEE_AUTH_URL } from "../../api_interface/apiURLs";
import { useSelector } from "react-redux";
import imagenotavailable from '../../assets/images/imagenotavailable.png'
import { Spinner } from 'react-bootstrap'
import { toast } from "react-toastify";
import Modal from "react-modal";
import { checkPasswordValidity, customModalStyles } from "../../utils/helperFunctin";



function AdminAccounts() {
	const moduleList = useSelector((state) => state.sidebardModuleSliceReducer);
	const { adminId } = useParams()
	const [admin, setAdmin] = useState([])
	const [loader, setLoader] = useState(true)
	// const dispatch = useDispatch()
	const [modal, setModal] = useState(false);
	const user = useSelector((state) => state.userReducer);

	const [changePassword, setChanePassword] = useState({ currentPassword: '', newPassword: '', confirmPassword: "", loader: false })
	const [changePasswordError, setChanePasswordError] = useState({ currentPassword: '', newPassword: '', confirmPassword: "", loader: false })

	const handleChange = (ele) => {
		setChanePassword({ ...changePassword, [ele.target.name]: ele.target.value })
		setChanePasswordError({ ...changePasswordError, [ele.target.name]: null })
	}

	const getAdmin = async () => {
		const adminId = user?._id
		const res = await getApi(HIBEE_AUTH_URL + ADMIN_USER + `/admin?userId=${adminId}`);
		if (res?.status == 200) {
			setAdmin(res?.data?.data?.[0])
			setLoader(false)
		}
	}

	const handlaValidation = () => {
		const newErrors = {}
		const { currentPassword, newPassword, confirmPassword } = changePassword
		if (!currentPassword) newErrors.currentPassword = 'please enter current password'
		// else if (!checkPasswordValidity(currentPassword)) newErrors.confirmPassword = 'password && confirm password should be same'
		if (!newPassword) newErrors.newPassword = 'please enter new password'
		else if (!checkPasswordValidity(newPassword)) newErrors.confirmPassword = 'Password must contain min 8 letter, 1 special character, 1 upper and 1 lower case and a number'
		if (!confirmPassword) newErrors.confirmPassword = 'please enter confirm password'
		// else if (!checkPasswordValidity(currentPassword))  = 'Password must contain min 8 letter, 1 special character, 1 upper and 1 lower case and a number'
		else if (newPassword != confirmPassword) newErrors.confirmPassword = 'password && confirm password should be same'
		return newErrors
	}


	const handleSubmit = async (e) => {
		e.preventDefault()
		try {
			setChanePassword({ ...changePassword, loader: true })
			const handlaValidationObject = handlaValidation()
			if (Object.keys(handlaValidationObject).length > 0) {
				setChanePasswordError(handlaValidationObject)
				setChanePassword({ ...changePassword, loader: false })

			} else {
				const payload = {
					old_password: changePassword.currentPassword,
					new_password: changePassword.confirmPassword,
					adminId: admin._id
				}
				const responce = await putApi(HIBEE_AUTH_URL + CHANGE_PASSWORD, payload)
				if (responce.status == 200) {
					console.log(responce, 'responce');
					toast.success(responce?.data?.message, { position: "top-center", autoClose: 2000 });
					handleClear()
				}
			}
		} catch (err) {
			const message = err?.response?.data?.message || "Something went wrong";
			toast.error(message, { position: "top-center", autoClose: 2000 });
			handleClear()
		}
	}


	const handleClear = () => {
		setChanePassword({
			...changePassword,
			currentPassword: '',
			newPassword: '',
			confirmPassword: "",
			loader: false
		})
		setChanePasswordError({
			...changePasswordError,
			currentPassword: '',
			newPassword: '',
			confirmPassword: "",
			loader: false

		})
		setModal(false)
	}

	useEffect(() => {
		getAdmin()
	}, [])


	return (
		<MainTemplate categoryName="Account" categoryList={["Account", "Admin", `${admin?.name && admin?.name}`]}>
			<div className="mb-4 rounded bg-white homepageModifierPadding mb-4">
				{
					loader == false
						?
						<div className="row" >
							<div className="col-lg-5 mb-4">
								<div className="w-50 adminImage border rounded mb-3">
									<img className="w-100 h-100 rounded" style={{ objectFit: "cover" }} src={admin?.image ? admin?.image : imagenotavailable} />
								</div>
								<div className="form-group pb-2">
									<label className="font-weight-bold">Name</label>
									<p>{admin?.name}</p>
								</div>
								<div className="form-group pb-2">
									<label className="font-weight-bold">Phone number</label>
									<a href={`tel:${admin?.phone}`}><p>{admin?.phone}</p></a>
								</div>
								<div className="form-group pb-2">
									<label className="font-weight-bold">Email</label>
									<a href={`mailto:${admin?.email}`}><p>{admin?.email}</p></a>
								</div>
								<div className="form-group pb-2">
									<label className="font-weight-bold">Password</label>
									<p>***********</p>
								</div>
								{/* <div className="form-group pb-2 d-flex">
									<label className="font-weight-bold me-5" checked={admin?.active} >Account Status</label>
									<label className="switch">
										<input className="switch-input" type="checkbox" checked={admin?.active} />
										<span className="switch-label"></span>
										<span className="switch-handle"></span>
									</label>
								</div> */}
							</div>
							<div className="col-lg-5 mb-4">
								<div className="py-2">
									{/* <label className="font-weight-bold">Module Access</label> */}
									{/* <div className="bg-light rounded py-1 mt-2 overflow-auto PageScroll"> */}
									{
										// moduleList?.map((ele, ind) => {
										// 	return <div className="form-check mx-4 my-3 d-flex align-items-center">
										// 		<input checked={admin?.access?.includes(ele?.value)} className="form-check-input me-4 dark-checkbox" type="checkbox" value="" id="flexCheckChecked" />
										// 		<label className="form-check-label" for="flexCheckChecked">
										// 			{ele?.name}
										// 		</label>
										// 	</div>
										// })
									}
									{/* <div className="form-check mx-4 my-3 d-flex align-items-center">
							<input checked={admin?.access?.includes("Categories")} className="form-check-input me-4 dark-checkbox" type="checkbox" value="" id="flexCheckDefault" />
							<label className="form-check-label" for="flexCheckDefault">
								Categories
							</label>
						</div>
						<div className="form-check mx-4 my-3 d-flex align-items-center">
							<input checked={admin?.access?.includes("Orders")} className="form-check-input me-4 dark-checkbox" type="checkbox" value="" id="flexCheckChecked" />
							<label className="form-check-label" for="flexCheckChecked">
								Orders
							</label>
						</div>
						<div className="form-check mx-4 my-3 d-flex align-items-center">
							<input checked={admin?.access?.includes("Inventories")} className="form-check-input me-4 dark-checkbox" type="checkbox" value="" id="flexCheckDefault" />
							<label className="form-check-label" for="flexCheckDefault">
								Inventories
							</label>
						</div>
						<div className="form-check mx-4 my-3 d-flex align-items-center">
							<input checked={admin?.access?.includes("Users")} className="form-check-input me-4 dark-checkbox" type="checkbox" value="" id="flexCheckChecked" />
							<label className="form-check-label" for="flexCheckChecked">
								Users
							</label>
						</div>
						<div className="form-check mx-4 my-3 d-flex align-items-center">
							<input checked={admin?.access?.includes("Franchises")} className="form-check-input me-4 dark-checkbox" type="checkbox" value="" id="flexCheckDefault" />
							<label className="form-check-label" for="flexCheckDefault">
								Franchises
							</label>
						</div> */}
									{/* </div> */}
								</div>
								<div className="col-sm-6 my-3">
									<button
										type="button"
										className="t-12 w-100 btn btn-outline-dark"
										onClick={() => {
											setModal(true);
											// setcategory({});
										}}
									>

										Change Password
									</button>
								</div>
								{/* <div className="form-group py-2 pb-3">
					<label className="font-weight-bold">Roles</label>
					<p className="text-uppercase">{admin?.role}</p>
				</div> */}
							</div>
							<div className="col-lg-10 d-flex justify-content-end">
								<Link to="/admin-form" state={{ data: admin }} className="text-dark px-3 py-1 rounded" style={{ textDecoration: "none" }}>
									<span className="cursorPointer">
										<svg className="bi bi-pencil border-bottom border-dark" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
											<path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
										</svg>
										<span className="mx-1">Edit</span>
									</span>
								</Link>
							</div>
						</div>
						:
						<div className="text-center mt-5" >
							<button class="btn mt-5" style={{ backgroundColor: '#FFEDC2' }} type="button">
								<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{" "}
								Loading...
							</button>
						</div>
				}
			</div>

			<Modal
				isOpen={modal}
				onRequestClose={() => setModal(false)}
				style={customModalStyles}
				contentLabel="Example Modal"
				ariaHideApp={false}
			>
				<div className="px-5 py-3">
					<div className="row">
						<span className="d-flex justify-content-end cursorPointer">
							<svg
								onClick={() => {
									setModal(false)
									handleClear()
								}}
								className="d-inline"
								xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="16"
								fill="currentColor"
								viewBox="0 0 16 16"
							>
								<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
							</svg>
						</span>
					</div>
					<div className="modalMaxHeight">
						<h5 className="text-center" >Change Password</h5>
						<div className="row">
							<div className="col-6 d-flex align-items-center">
								<span>Current Password <span className="text-danger" >*</span></span>
							</div>
							<div className="col-6">
								<input
									className={`placeholder-light form-control my-1 py-2 px-3 ${changePasswordError.currentPassword ? 'border border-danger' : ''}`}
									type="password"
									placeholder="please enter current password"
									name='currentPassword'
									value={changePassword.currentPassword}
									onChange={handleChange}
								/>
								<span className="text-danger"  >{changePasswordError.currentPassword}</span>
							</div>
						</div>
						<div className="row">
							<div className="col-6 d-flex align-items-center">
								<span  >New Password  <span className="text-danger" >*</span></span>
							</div>
							<div className="col-6">
								<input
									className={`placeholder-light form-control my-1 py-2 px-3 ${changePasswordError.newPassword ? 'border border-danger' : ''}`}
									type="password"
									placeholder="please enter new password"
									name='newPassword'
									value={changePassword.newPassword}
									onChange={handleChange}
								/>
								<span className="text-danger">{changePasswordError.newPassword}</span>
							</div>
						</div>
						<div className="row">
							<div className="col-6 d-flex align-items-center">
								<span >Confirm New Password <span className="text-danger" >*</span></span>
							</div>
							<div className="col-6">
								<input
									className={`placeholder-light form-control my-1 py-2 px-3 ${changePasswordError.confirmPassword ? 'border border-danger' : ''}`}
									type="password"
									placeholder="please enter confirm password"
									name='confirmPassword'
									value={changePassword.confirmPassword}
									onChange={handleChange}
								/>
								<span className="text-danger" >{changePasswordError.confirmPassword}</span>
							</div>
						</div>
					</div>

					<div className="row" >
						<div className="col-md-3 form-group my-3">
							<button className="btn btn-dark text-warning px-5" onClick={handleSubmit}>
								Submit
								{
									changePassword?.loader
										?
										<Spinner
											animation="border"
											variant="light"
											size="sm"
										/>
										:
										''
								}
							</button>
						</div>
						<div className="col-md-3 form-group my-3" style={{ marginLeft: '10px' }}>
							<button className="btn btn-dark text-warning px-5" onClick={handleClear}>Clear</button>
						</div>
					</div>
				</div>
			</Modal>

		</MainTemplate>
	);
}

export default AdminAccounts;