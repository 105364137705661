import React, { useEffect, useState, useMemo } from "react";
import "./ProductPrice.css";
import ReactPaginate from "react-paginate";
import { Link, useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal";
import { toast } from "react-toastify";
import MainTemplate from "../../components/MainTemplate";
import { deleteTokenApi, getTokenApi, postTokenApi, putTokenApi } from "../../api_interface/apis";
import { HIBEE_PRODUCT_URL, PRODUCT_ARCHIVE, PRODUCT_PATH, PRODUCT_SUBCATEGORY } from "../../api_interface/apiURLs";
import { customActionModalStyles, customModalStyles, debounce } from "../../utils/helperFunctin";
import { useLocation } from "react-router-dom";

function ProductPrice() {
  const location = useLocation();
  const comingdata = location?.state?.data;
  const { subCategoryId } = useParams()
  const [sort, setSort] = useState("")
  const [query, setQuery] = useState("")
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState({
    _id: "",
    code: "",
    name: "",
    varient: "",
    manufacture: "",
    mfgdate: "",
    expdate: "",
    qty: "",
    warehouse: "",
    category: "",
    subcategory: "",
    type: "",
    batch: "",
    productimage: "",
    price: "",
    cutout_price: "",
    commission: "",
    sizeList: "",
  });
  const [itemOffset, setItemOffset] = useState(0);
  const [modal, setModal] = useState(false)
  const navigate = useNavigate();
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const endOffset = itemOffset + itemsPerPage;
  const pageCount = Math.ceil(products?.length / itemsPerPage);
  const [updateType, setUpdateType] = useState("false");
  const currentItems = products?.slice(itemOffset, endOffset);
  const [error, setError] = useState({});
  const [loader, setLoader] = useState(false);
  const [productImage, setProductImage] = useState("");
  const [actionModal, setActionModal] = useState({
    loader: false,
    show: false,
    type: "",
    data: {},
  });

  console.log(itemsPerPage, "perpage");
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % products?.length;
    console.log(newOffset, "new");
    setItemOffset(newOffset);
  };

  const [activatedItemId, setActivatedItemId] = useState([]);
  const [status, setStatus] = useState(false);
  const handleCheckboxChange = (id, status) => {
    if (activatedItemId.includes(id)) {
      // If the id already exists in activatedItemId, remove it
      setActivatedItemId((prevItemIds) => prevItemIds.filter((itemId) => itemId !== id));
    } else {
      // Otherwise, add the id to activatedItemId
      setActivatedItemId((prevItemIds) => [...prevItemIds, id]);
    }
    setStatus(status ? false : true);
  };
  useEffect(() => {
    getProducts()
  }, [sort])

  useEffect(() => {
    getSearchedData(query)
  }, [query])

  const validation = () => {
    const error = {};
    const {
      _id,
      code,
      name,
      varient,
      manufacture,
      mfgdate,
      expdate,
      qty,
      warehouse,
      category,
      subcategory,
      type,
      batch,
      productimage,
      price,
      cutout_price,
      commission,
      sizeList,
    } = product;

    if (!code) {
      error.code = "Please enter unique code";
    }
    if (!name) {
      error.name = "Please enter product name";
    }
    if (!varient) {
      error.varient = "Please enter product variant";
    }
    if (!manufacture) {
      error.manufacture = "Please enter manufacture name";
    }
    if (!mfgdate) {
      error.mfgdate = "Please select product MFG date";
    }
    if (!expdate) {
      error.expdate = "Please select product expiry date";
    }
    if (!qty) {
      error.qty = "Please enter qty name";
    }
    if (!warehouse) {
      error.warehouse = "Please select warehouse";
    }
    if (!category) {
      error.category = "Please select category";
    }
    if (!subcategory) {
      error.subcategory = "Please select sub category";
    }
    if (!type) {
      error.type = "Please enter storage type";
    }
    if (!batch) {
      error.batch = "Please enter product batch";
    }
    if (!productimage) {
      error.productimage = "Please upload product variant";
    }
    if (!price) {
      error.price = "Please enter price";
    }
    if (!cutout_price) {
      error.cutout_price = "Please enter cutout price";
    }
    if (!commission) {
      error.commission = "Please enter commission";
    }
    if (Object.keys(error)?.length) {
      setError(error);
      return false;
    } else {
      setError({});
      return true;
    }
  };


  const getProducts = async search => {
    setLoader(true);
    let res
    if (subCategoryId) {
      res = await postTokenApi(HIBEE_PRODUCT_URL + PRODUCT_PATH + PRODUCT_SUBCATEGORY + `?sort=${sort}&search=${search || query || ""}&archive=${updateType}`, { subcategory: subCategoryId });
    } else {
      res = await getTokenApi(HIBEE_PRODUCT_URL + PRODUCT_PATH + `?sort=${sort}&search=${search || query || ""}&archive=${updateType}`);
    }
    if (res.status == 200) {
      setProducts(res?.data?.data?.reverse());
      setLoader(false);
    }
  };
  console.log("products",products)
  console.log(getTokenApi,"")


  const inactiveProducts = async () => {
    setLoader(true);

    try {
      const res = await putTokenApi(HIBEE_PRODUCT_URL + PRODUCT_PATH + PRODUCT_ARCHIVE, {
        productId: activatedItemId,
        archive: status,
      });
      if (res.status === 200) {

        toast.success(res?.data?.message, {
          position: "top-center",
          autoClose: 2000,
        });
        setActivatedItemId([]); // Reset the activatedItemIds state
        setStatus(false);
      }
    } catch (error) {
      console.error(error);
      setLoader(false);
    }

  };
  

  useEffect(() => {
    if (activatedItemId.length > 0) {
      inactiveProducts();
    }
  }, [activatedItemId]);


  const updateSort = e => {
    setSort(e.target.value)
  }

  const getSearchedData = useMemo(() => debounce(async (search) => {
    getProducts(search)
  }, 500), [])

  useEffect(() => {
    getProducts()
  }, [sort, updateType, activatedItemId])

  useEffect(() => {
    getSearchedData(query)
  }, [query])

  useEffect(() => {
    if (Object.keys(error)?.length) {
      validation();
    }
  }, [error]);

  const Submit = async (e) => {
    e.preventDefault();
    setLoader(true);
    const {
      _id,
      code,
      name,
      varient,
      manufacture,
      mfgdate,
      expdate,
      qty,
      warehouse,
      category,
      subcategory,
      type,
      batch,
      productimage,
      price,
      cutout_price,
      commission,
      sizeList,
    } = product;
    if (validation()) {
      const fd = new FormData();
      if (_id) {
        fd.append("_id", _id);
      }
      fd.append("product_code", code);
      fd.append("product_name", name);
      fd.append("product_variant", varient);
      fd.append("manufacture_name", manufacture);
      fd.append("manufacturing_date", mfgdate);
      fd.append("expiration_date", expdate);
      fd.append("qty", qty);
      fd.append("warehouse", warehouse);
      fd.append("category", category);
      fd.append("subcategory", subcategory);
      fd.append("type", type);
      fd.append("batch", batch);
      fd.append("image", productimage);
      fd.append("price", price);
      fd.append("cutout_price", cutout_price);
      fd.append("commission", commission);
      fd.append("sizeList", JSON.stringify({ size: "500 g", quantity: 17 }));

      const res = await postTokenApi(HIBEE_PRODUCT_URL + PRODUCT_PATH, fd, {
        "Content-Type": "multipart/form-data",
      });

      setModal(false);
      getProducts();
      toast.success("Product Saved", {
        position: "top-center",
        autoClose: 2000,
      });
    }
    setLoader(false);
  };

  const deleteProduct = async (id) => {
    const res = await deleteTokenApi(
      HIBEE_PRODUCT_URL + PRODUCT_PATH + `/${id}`
    );
    setActionModal({});
    getProducts();
  };

  const onChange = (e, type) => {
    if (type === "IMAGE") {
      setProduct({ ...product, productimage: e.target?.files?.[0] });
    } else {
      setProduct({ ...product, [e.target.name]: e.target.value });
    }
    setTimeout(() => { }, 500);
  };

  const closeCategoryModal = () => {
    setModal(false);
    setProduct({});
    setLoader(false);
    setError({});
  };
  const archiveProduct = (cat) => {
    setActionModal({ ...actionModal, loader: true });

    setActionModal({});
    getProducts();
    // setActionModal({...actionModal, show:true})
  };
  const [aprove, setAprove] = useState({
    status: "",
    index: ""
  })
console.log(currentItems,"currentItemscurrentItemscurrentItems")

  return (
    <MainTemplate
      categoryName="Products List" categoryList={["Product", "Products List"]}
    >
      <div className="row mb-4 rounded p-2 bg-white">
        {/* <div className="col-sm-6 my-3 col-md-2">
          <select className="t-12 form-select" aria-label="Default select example" onChange={updateSort}>
            <option value="" selected>Sort by</option>
            <option value="name">Alphabatically</option>
            <option value="dateold">Date: Acsending</option>
            <option value="datenew">Date: Descending</option>
          </select>
        </div> */}
        <div className="col-sm-6 my-3 col-md-2">
          <select className="t-12 form-select" aria-label="Default select example" onChange={e => setItemsPerPage(e.target.value)}>
            <option value="10" selected>10 per page</option>
            <option value="20">20 per page</option>
            <option value="50">50 per page</option>
            <option value="100">100 per page</option>
          </select>
        </div>
        <div className="col-sm-6 my-3 col-md-2">
          <input type="text" name="query" className="t-12 form-control" placeholder="Search" value={query} onChange={e => setQuery(e.target.value)} />
        </div>
        <div className="col-sm-6 my-3 col-md-2">
          <select className="t-12 form-select" aria-label="Default select example"
            onChange={e => setUpdateType(e.target.value)}
          >
            {/* <option value="" selected>type</option> */}
            <option value={false} selected>Active</option>
            <option value={true}>Inactive</option>
          </select>

        </div>
        <div className="col-sm-6 my-3 col-md-4 d-flex gap-3">

          <Link to={`/inventory-product-add`} state={{ data: { comingdata, subCategoryId }, edit: true }}>
            <button type="button" className="t-12 w-100 btn btn-outline-dark">
              Add Product
            </button>
          </Link>


          {/* <button type="button" className="products-actionbutton">
            Action
          </button> */}
        </div>
      </div>

      <div className="row rounded px-3 bg-white">
        {/* header */}
        <div className="row m-0 font-weight-bold t-12 text-uppercase ">

          <div className="p-0 my-3 col-md-1">ID</div>
          <div className="p-0 my-3 col-md-1">Photo</div>
          <div className="p-0 my-3 col-md-2">Product</div>
          <div className="p-0 my-3 col-md-2 text-center">Size</div>
          <div className="p-0 my-3 col-md-1">Price</div>
          <div className="p-0 my-3 col-md-2  text-center">Commission</div>

          <div className="p-0 my-3 col-md-1">Edit</div>
          <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
            Active{" "}/{" "}Inactive
          </div>

        </div>

        {/* body */}
        <div className="m-0 p-0 pe-3 overflow-auto caterogyPageScroll">
          {currentItems?.map((el, index) => (
            <div
              className="row border rounded m-0 px-2 mb-2 d-flex align-items-center"
              key={el?._id}
            >
              {/* <div className="t-12 text-uppercase my-3 col-md-1">
                <input type="checkbox" />
              </div> */}
              <div className="t-12 text-uppercase my-3 col-md-1" style={{}}>
                <Link className="text-dark" to={`/product/${el?._id}`} style={{ textDecoration: "none" }} >
                  {el?.wmsProductId}
                </Link>
              </div>
              <div className="t-12 text-uppercase my-3 col-md-1">
                <div className="  rounded p-1">
                  <Link className="text-dark" to={`/product/${el?._id}`} style={{ textDecoration: "none" }} >
                    {el?.images && el.images.length > 0 ? (
                      <img src={el.images[0].image} alt="loading" className="categoryImage h-100 rounded" />
                    ) : (
                      <img src={el?.image} alt="loading" className="categoryImage h-100 rounded" />
                    )}
                  </Link>
                  <div>
                  </div>
                </div>
              </div>
              <div className="t-12 text-uppercase my-3 col-md-2">
                <Link className="text-dark" to={`/product/${el?._id}`} style={{ textDecoration: "none" }} >
                  {el?.product_name}
                </Link>
              </div>
              <div className="t-12 text-uppercase my-3 col-md-2 text-center ">
                {el?.sizeList?.size} {el?.sizeList?.unit}
              </div>
              <div className="t-12 text-uppercase my-3 col-md-1">₹{el?.price}</div>

              <div className="t-12 text-uppercase my-3 col-md-2  text-center">
                {el?.commission}
              </div>
              <div className="t-12 text-uppercase my-3 col-md-1">

                <Link
                  to="/inventory-product-add"
                  state={{ id: el?._id, edit: true }}
                  className="text-dark  py-1 rounded"
                  style={{ textDecoration: "none" }}
                >
                  <div className=" py-2 d-flex cursorPointer">
                    <span className="d-flex align-items-center">
                      <svg
                        className="bi bi-pencil border-bottom border-dark"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                      </svg>
                    </span>

                    <span className="mx-2">
                      <div className="font-weight-bold ml-1">Edit</div>
                    </span>
                  </div>
                </Link>
              </div>
              <div className="col my-4  dotsize">
                <label className="switch">
                  <input
                    className="switch-input"
                    type="checkbox"
                    checked={!el?.archive}
                    onChange={() => handleCheckboxChange(el?._id, el?.archive)}
                  />
                  <span className="switch-label"></span>
                  <span className="switch-handle"></span>
                </label>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="row m-2 d-flex justify-content-center align-items-center">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          pageClassName="page-item mb-0"
          subContainerClassName="pages "
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          marginPagesDisplayed={2}
          containerClassName="pagination"
          activeClassName="active"
        />
      </div>
      {/* Product edit model */}
      <Modal
        isOpen={modal}
        onRequestClose={closeCategoryModal}
        style={customModalStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className="px-3 py-3">
          <div className="row">
            <span className="d-flex justify-content-end cursorPointer">
              <svg
                onClick={closeCategoryModal}
                className="d-inline"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </span>
          </div>
          <div className="row">
            <div className="form-group my-3">
              <label className="my-2">Edit Product Name</label>
              <input
                name="name"
                value={product?.name}
                onChange={(e) => onChange(e)}
                type="text"
                className={`placeholder-light form-control my-2 py-2 px-3 ${error?.name && "border-danger"
                  }`}
                placeholder="eg: Apple New Launch Pad"
              />
              <span className="text-danger">{error?.name}</span>
            </div>
          </div>

          <div className="row">
            <div className="form-group my-3">
              <label className="my-2">Edit Commission</label>
              <input
                name="commission"
                value={product?.commission}
                onChange={(e) => onChange(e)}
                type="text"
                className={`placeholder-light form-control my-2 py-2 px-3 ${error?.commission && "border-danger"
                  }`}
                placeholder="eg: Apple New Launch Pad"
              />
              <span className="text-danger">{error?.commission}</span>
            </div>
          </div>

          <div className="row">
            <div className="form-group my-3">
              <label className="my-2">Edit manufacturer date</label>
              <input
                name="mfgdate"
                value={product?.mfgdate}
                onChange={(e) => onChange(e)}
                type="date"
                className={`placeholder-light form-control my-2 py-2 px-3 ${error?.mfgdate && "border-danger"
                  }`}
                placeholder="eg: Apple New Launch Pad"
              />
              <span className="text-danger">{error?.mfgdate}</span>
            </div>
          </div>

          <div className="row">
            <div className="form-group my-3">
              <label className="my-2">Change Image</label>
              <input
                name="productimage"
                type="file"
                onChange={(e) => onChange(e, "IMAGE")}
                accept="image/png, image/gif, image/jpeg"
                className={`py-2 opacity0 position-absolute placeholder-light form-control py-2 px-3 border`}
                placeholder="eg: https://www.apple.com/in/watch"
              />
              <div
                className={`my-2 py-2 select-text-light d-flex align-items-center px-3 border rounded ${error?.productimage && "border-danger"
                  }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-cloud-arrow-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z"
                  />
                  <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                </svg>
                <span className="px-2">Upload your image file</span>
              </div>

              {product?.productimage &&
                URL.createObjectURL(product?.productimage) ? (
                <div>
                  <img
                    id="categoryPageModalImage"
                    src={URL.createObjectURL(product?.productimage)}
                    alt="img"
                    className="rounded shadow-sm mt-2"
                    style={{
                      width: "100px",
                      height: "100px",
                    }}
                  />
                </div>
              ) : (
                ""
              )}
              <span className="text-danger">{error?.productimage}</span>
            </div>
          </div>
          <div className="row">
            <div className="form-group my-3">
              <label className="my-2">Edit Price</label>
              <input
                name="price"
                value={product?.price}
                onChange={(e) => onChange(e)}
                type="number"
                onWheelCapture={(e)=>e.target.blur()}

                className={`placeholder-light form-control my-2 py-2 px-3 ${error?.price && "border-danger"
                  }`}
                placeholder="eg: Apple New Launch Pad"
              />
              <span className="text-danger">{error?.price}</span>
            </div>
          </div>

          <div className="form-group my-3">
            <button
              disabled={loader}
              className="btn btn-dark text-warning px-5"
              onClick={Submit}
            >
              {loader ? (
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="sr-only"></span>
                </div>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={actionModal?.show}
        onRequestClose={() => setActionModal({})}
        style={customActionModalStyles}
        contentLabel="Action Modal"
        ariaHideApp={false}
      >
        <div className="px-3 py-3">
          <div className="row">
            <span className="d-flex justify-content-end cursorPointer">
              <svg onClick={() => setActionModal({})} className="d-inline" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </span>
          </div>
          <div className="row">
            <h4 className="text-danger d-flex justify-content-center">
              Do you want to {actionModal?.type} Product?
            </h4>
          </div>
          <div className="row my-5">
            <h5 className="d-flex justify-content-center">
              {actionModal?.data?.product_name}
            </h5>
          </div>
          <div className="form-group my-3 d-flex justify-content-center">
            <button
              disabled={actionModal?.loader}
              className="btn btn-dark me-3 mb-2 text-warning px-5"
              onClick={() =>
                actionModal?.type === "DELETE"
                  ? deleteProduct(actionModal?.data?._id)
                  : archiveProduct(actionModal?.data?._id)
              }
            >
              {actionModal?.loader ? (
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="sr-only"></span>
                </div>
              ) : (
                "Submit"
              )}
            </button>
            <button
              disabled={actionModal?.loader}
              className="btn btn-light me-3 mb-2 shadow-sm text-dark border px-5"
              onClick={() => setActionModal({})}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </MainTemplate>
  );
}

export default ProductPrice;
