import React, { useEffect, useMemo, useState } from "react";
import "./CategoryPage.css";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { toast } from "react-toastify";
import BulkCategoryCsv from "../../assets/BulkCategory.csv";
import Papa from "papaparse";
import excell from "../../assets/images/excell.jpg";
import axios from "axios";
import imagenotavailable from '../../assets/images/imagenotavailable.png'

import { deleteTokenApi, getTokenApi, postTokenApi, getApi, putTokenApi } from "../../api_interface/apis";
import { BULK_CATEGORY_PATH, CATEGORY_PATH, HIBEE_PRODUCT_URL, HIBEE_BULK_IMAGE } from "../../api_interface/apiURLs";
import { customActionModalStyles, customModalStyles, debounce } from "../../utils/helperFunctin";
import MainTemplate from "../../components/MainTemplate";
import { Spinner } from "react-bootstrap";
const allowedExtensions = ["csv"];

function CategoryPage() {
  const [itemOffset, setItemOffset] = useState(0);
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const reader = new FileReader();
  const [actionModal, setActionModal] = useState({
    loader: false,
    show: false,
    type: "",
    data: {},
  });
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState({ name: "", image: "", discount: "", commission: "", imageFile: "", imagePreview: "" });
  const [categoryError, setcategoryError] = useState({ name: "", image: "", discount: "", commission: "", imageFile: "" });
  const [error, setError] = useState({});
  const [loader, setLoader] = useState(false);
  const [categoryListLoader, setCategoryListLoader] = useState(false);

  const [sort, setSort] = useState("");
  const [query, setQuery] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [bulkCategories, setBulkCategories] = useState({
    bulkData: [],
    show: false,
    error: "",
  });
  const [file, setFile] = useState("");
  const [aprove, setAprove] = useState({
    status: "",
    index: ""
  })
  const [updateType, setUpdateType] = useState('false')

  const endOffset = itemOffset + itemsPerPage;
  const pageCount = Math.ceil(categories.length / itemsPerPage);
  const currentItems = categories.slice(itemOffset, endOffset);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % categories.length;
    setItemOffset(newOffset);
  };

  const getCategories = async (search, updateType, sort) => {
    setCategoryListLoader(true);
    const one = localStorage.getItem('categoryStatus')
    const two = localStorage.getItem('categorySort')
    const res = await getTokenApi(HIBEE_PRODUCT_URL + CATEGORY_PATH + `?sort=${two ? two : 'name'}&search=${search || query || ""}&${`status=${one}`}`);
    if (res.status == 200) {
      setCategories(res?.data?.data);
      setCategoryListLoader(false);
    }
  };
console.log(currentItems,"sortsortsortsortsortsortsortsortsort")

  const validation = () => {
    const { name, image, discount, commission, imageFile, _id } = category;
    const discountPattern = /^(100(\.0{1,2})?|\d{0,2}(\.\d{1,2})?)$/;
    let error = {};
    if (!name) {
      error.name = "Name required";
    }
    // if (!discount) {
    //   error.discount = "Discount Required";
    // }
    // if (!commission) {
    //   error.commission = "Commission Required";
    // }  

    if (discount) {
      if (discountPattern.test(discount)) {
        if (discount <= 100) {
          // error.discount = 'Discount is valid.';
        } else {
          error.discount = 'Discount cannot be greater than 100%.';
        }
      } else {
        error.discount = 'Discount cannot be greater than 100%.';
      }
    }

    if (commission) {
      if (discountPattern.test(commission)) {
        if (commission <= 100) {
          // error.discount = 'Commission is valid.';
        } else {
          error.commission = 'Commission cannot be greater than 100%.';
        }
      } else {
        error.commission = 'Commission cannot be greater than 100%.';
      }
    }

    if (!(image || imageFile)) {
      error.image = "Image required";
    }
    // if (Object.keys(error)?.length > 0) {
    //   setError(error);
    //   return false;
    // } else {
    //   setError({});
    //   return true;
    // }
    return error;
  };
  const [bulkImages, setBulkImages] = useState([]);
  const Submit = async (e) => {
    try {
      e.preventDefault();
      const { name, image, imageFile, _id, commission, discount } = category;
      const validationObject = validation();
      if (bulkCategories.bulkData.length == 0) {
        if (Object.keys(validationObject).length > 0) {
          setcategoryError(validationObject);
        } else {
          setLoader(true);
          const fd = new FormData();
          fd.append("name", name);
          fd.append("activated", true);
          if (imageFile) {
            fd.append("image", imageFile);
            console.log(imageFile, "Sending ImageFile");
          }
          if (commission) {
            fd.append("commission", commission);
          }
          if (discount) {
            fd.append("discount", discount);
          }
          if (_id) {
            fd.append("_id", _id);
          }
          const res = await postTokenApi(HIBEE_PRODUCT_URL + CATEGORY_PATH, fd, { "Content-Type": "multipart/form-data" });
          if (res.status == 200) {
            localStorage.setItem('categoryStatus', true)
            localStorage.setItem('categorySort', 'name')
            setModal(false);
            getCategories();
            toast.success("Category Saved", {
              position: "top-center",
              autoClose: 2000,
            });
            setLoader(false);
          }
        }
      } else {
        // setBulkCategories({ ...bulkCategories, error: "please select file" })

        // const fd = new FormData();
        // fd.append("name", name);
        // if (imageFile) {
        //   fd.append("image", imageFile);
        // }
        // if (commission) {
        //   fd.append("commission", commission);
        // }
        // if (discount) {
        //   fd.append("discount", discount);
        // }
        // if (_id) {
        //   fd.append("_id", _id);
        // }
        // const payload = {
        // }

        // const payload = {
        //   data: [
        //     {
        //       name: "Dry Fruits",
        //       activated: true,
        //       commission: "5",
        //       discount: "2",
        //       "image": ["https://thumbs.dreamstime.com/b/dry-fruits-11010662.jpg"]
        //     }
        //   ]
        // }
        setLoader(true);
        const updatedPayload = bulkCategories?.bulkData.map((ele, ind) => {
          return {
            name: ele.CateogoryName,
            activated: false,
            commission: ele.Commission,
            discount: ele.Discount,
            // image: ele.Image,
          };
        });

        // const payload = {
        //   updatedPayload
        // };
        const res = await postTokenApi(HIBEE_PRODUCT_URL + BULK_CATEGORY_PATH, updatedPayload
        );
        if (res.status == 200) {
          localStorage.setItem('categoryStatus', true)
          localStorage.setItem('categorySort', 'name')
          setModal(false);
          getCategories();
          toast.success("Category Saved", {
            position: "top-center",
            autoClose: 2000,
          });
          setLoader(false);
          setBulkCategories({ ...bulkCategories, bulkData: [] });
        }
      }
    } catch (err) {
      localStorage.setItem('categoryStatus', true)
      localStorage.setItem('categorySort', 'name')
      const message = err?.response?.data?.message || "Something went wrong";
      toast.error(message, { position: "top-center", autoClose: 2000 });
      setLoader(false);
    }
  };

  function fileToBinary(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  }
  // const Submit1 = async (e) => {
  //   try {
  //     e.preventDefault();
  //     const res = await getApi(HIBEE_BULK_IMAGE + bulkImages.length);
  //     const data = res.data;
  //     data.map((el, index) => {
  //       console.log(el);
  //       const uploadFile = async () => {
  //         try {
  //           console.log("SINGLE IMAGE", bulkImages[index]);
  //           const body = new FormData();
  //           body.append("file", bulkImages[index]);
  //           const headers = { "Content-Type": bulkImages[index].type };
  //           const response = await axios.put(el,bulkImages[index], { headers });

  //           console.log(response);
  //         } catch (error) {
  //           console.error(error);
  //         }
  //       };
  //       uploadFile();
  //     });
  //     console.log(res, "getimages");
  //   } catch (err) {
  //     const message = err?.response?.data?.message || "Something went wrong";
  //     toast.error(message, { position: "top-center", autoClose: 2000 });
  //   }
  // };

  const deleteCategory = async (id) => {
    setActionModal({ ...actionModal, loader: true });
    const res = await deleteTokenApi(
      HIBEE_PRODUCT_URL + CATEGORY_PATH + `/${id}`
    );
    setActionModal({});
    getCategories();
  };

  const archieveCategory = (cat) => {
    setActionModal({ ...actionModal, loader: true });

    setActionModal({});
    getCategories();
  };

  const onChange = (e, type) => {
    if (type === "IMAGE") {
      setCategory({ ...category, image: "", imageFile: e.target?.files?.[0] });
    } else {
      setCategory({ ...category, name: e.target.value });
    }
    setTimeout(() => { }, 500);
  };
  function downloadFile(url) {
    const link = document.createElement("a");
    link.href = url;
    link.download = url.split("/").pop();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const closeCategoryModal = () => {
    setModal(false);
    setCategory({});
    setLoader(false);
    hendleCrearCategory();
    setBulkCategories({
      ...bulkCategories,
      bulkData: [],
      error: "",
      ele: "",
    })
  };

  const updateSort = (e) => {
    setSort(e.target.value);
  };

  const getSearchedData = useMemo(() => debounce(async (search, updateType, sort) => {
    getCategories(search, updateType, sort);
  }, 500), []);

  // useEffect(() => {
  //   getCategories();
  // }, [sort]);

  // useEffect(() => {
  //   getCategories();
  // }, [updateType]);

  useEffect(() => {
    getSearchedData(query, updateType, sort);
  }, [query, updateType, sort]);

  useEffect(() => {
    if (Object.keys(error)?.length) {
      validation();
    }
  }, [error]);

  /** Bulk Data **/
  const handleFileChange = (e) => {
    if (e.target.files.length) {
      setBulkCategories({ ...bulkCategories, error: "", ele: "" });
      const inputFile = e.target.files[0];
      const fileExtension = inputFile?.type.split("/")[1];
      if (!allowedExtensions.includes(fileExtension)) {
        // setError("Please input a csv file");
        setBulkCategories({
          ...bulkCategories,
          error: "Please input a csv file",
        });
        return;
      }
      // setFile(inputFile);
      // if (!file) return setBulkCategories({ ...bulkCategories, error: "Enter a valid file" });
      const reader = new FileReader();
      reader.onload = async ({ target }) => {
        const csv = Papa.parse(target.result, { header: true });
        setBulkCategories({
          ...bulkCategories,
          bulkData: csv.data.slice(0, csv.data.length - 1),
          error: "",
        });
      };
      reader.readAsText(inputFile);
    }
  };

  const hendleCrearCategory = () => {
    setcategoryError({
      ...categoryError,
      commission: "",
      discount: "",
      image: "",
      name: "",
    });
  };

  useEffect(() => {
    if (bulkCategories.bulkData.length != 0) {
      // setError({})
      hendleCrearCategory();
    }
  }, [bulkCategories]);

  const approveAdmin = async (ele, id) => {
    setAprove({ ...aprove, status: ele, index: id })
    // Path Params
    const categoryId = id
    const status = ele
    localStorage.setItem('categoryStatus', status)
    try {
      const res = await putTokenApi(HIBEE_PRODUCT_URL + CATEGORY_PATH + `/visiblity/${categoryId}?activated=${status}`)
      if (res.status == 200) {
        const newStatus = localStorage.getItem('categoryStatus')
        localStorage.setItem('categoryStatus', newStatus == 'true' ? false : true)
        if (res?.data?.status == 'activated') {
          toast.success(res?.data?.message, {
            position: "top-center",
            autoClose: 2000,
          });
        }
        if (res?.data?.status == 'disactivated') {
          toast.error(res?.data?.message, {
            position: "top-center",
            autoClose: 2000,
          });
        }
        getCategories()
      }
    } catch (err) {
      const newStatus = localStorage.getItem('categoryStatus')
      localStorage.setItem('categoryStatus', newStatus == 'true' ? false : true)
      toast.error(err?.response?.data?.message, {
        position: "top-center",
        autoClose: 2000,
      })
      getCategories()
    }
  }

  const handlePreview = () => {
    // setBulkCategories({ ...bulkCategories, show: true })

    var requiredKeys = ["Sl.N.", "CateogoryName", "Commission", "Discount"];
    var hasAllKeysAndValues = true;

    for (var i = 0; i < bulkCategories.bulkData.length; i++) {
      var obj = bulkCategories.bulkData[i];

      if (!obj.hasOwnProperty("CateogoryName") || !obj.CateogoryName) {
        hasAllKeysAndValues = false;
        break;
      }

      for (var j = 0; j < requiredKeys.length; j++) {
        if (!obj.hasOwnProperty(requiredKeys[j])) {
          hasAllKeysAndValues = false;
          break;
        }
      }

      // Check for any additional keys in the object
      var extraKeys = Object.keys(obj).filter(function (key) {
        return !requiredKeys.includes(key);
      });

      if (extraKeys.length > 0) {
        hasAllKeysAndValues = false;
        break;
      }
    }

    if (!hasAllKeysAndValues) {
      // console.log("Error: Some objects are missing required keys, have empty values, or contain extra keys.");
      toast.error("Error: Some objects are missing required keys, have empty values, or contain extra keys.", {
        position: "top-center",
        autoClose: 2000,
      });
    } else {
      setBulkCategories({ ...bulkCategories, show: true })
    }
  }

  /** filter **/
  useEffect(() => {
    localStorage.setItem('categoryStatus', true)
  }, [])

  useEffect(() => {
    getCategories();
    const data = localStorage.getItem('categoryStatus')
    setUpdateType(data)
  }, [updateType])

  return (
    <MainTemplate
      categoryName="Categories List"
      categoryList={["Categories", "Categories List"]}
    >
      {/* sorting */}
      <div className="row mb-4 rounded p-2 bg-white">
        <div className="col-sm-6 my-3 col-md-2">
          <select
            className="t-12 form-select"
            aria-label="Default select example"
            onChange={updateSort}
          >
            <option value="" selected>
              Sort by
            </option>
            <option value="name">Alphabatically</option>
            <option value="dateold">Date: Acsending</option>
            <option value="datenew">Date: Descending</option>
          </select>
        </div>
        <div className="col-sm-6 my-3 col-md-2">
          <select
            className="t-12 form-select"
            aria-label="Default select example"
            onChange={(e) => setItemsPerPage(e.target.value)}
          >
            <option value="10" selected>
              10 per page
            </option>
            <option value="20">20 per page</option>
            <option value="50">50 per page</option>
            <option value="100">100 per page</option>
          </select>
        </div>
        <div className="col-sm-6 my-3 col-md-2">
          <select className="t-12 form-select" aria-label="Default select example"
            autoComplete="off"
            onChange={e => {
              setUpdateType(e.target.value === "true" ? true : false)
              localStorage.setItem('categoryStatus', e.target.value === "true" ? true : false)
            }}
          >
            {/* <option value="" selected>type</option> */}
            <option value={true} selected>Active</option>
            <option value={false}>Inactive</option>
          </select>
        </div>
        <div className="col-sm-6 my-3 col-md-3">
          <input
            type="text"
            name="query"
            className="t-12 form-control"
            placeholder="Search"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        <div className="d-none d-md-block col-md-1"></div>
        <div className="col-sm-6 my-3 col-md-2">
          <button
            type="button"
            className="t-12 w-100 btn text-warning"
            style={{ backgroundColor: "#12352f" }}
            onClick={() => {
              setModal(true);
              setCategory({});
            }}
          >
            Add Category
          </button>
        </div>
        {/* <div className="col-sm-6 my-3 col-md-2">
          <button
            type="button"
            className="t-12 w-100 btn btn-outline-dark"
            onClick={() => {
              setModal1(true);
              setcategory({});
            }}
          >
            Add Images
          </button>
        </div> */}
      </div>

      {/* table */}
      <div className="row flex-column align-items-center">
        <div className="row rounded text-center bg-white">
          {/* header */}
          <div className="row m-0 ">
            <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-1">
              Photo
            </div>
            <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
              Category Name
            </div>
            <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
              Discount
            </div>
            <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
              Commission
            </div>
            <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
              Sub category count
            </div>
            <div className="font-weight-bold t-12 text-uppercase  p-0 my-3 col-md-1">
              Edit
            </div>
            <div className="font-weight-bold t-12 text-uppercase  p-0 my-3 col-md-2">
              Active{" "}/{" "}Inactive
            </div>
          </div>

          {/* body */}
          <div className="justify-content-center align-items-center overflow-auto caterogyPageScroll">
            {currentItems?.map((el) => (
              <div
                className="row border rounded m-0 mb-2 d-flex align-items-center"
                key={el?._id}
              >
                <div className="t-12 text-uppercase my-3 col-md-1">
                  <div className="categoryImageDiv shadow-sm  rounded p-1">
                    <Link
                      className="text-dark"
                      to={`/category/subcategory/${el?._id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <img
                        src={el?.image ? el?.image : imagenotavailable}
                        className="categoryImage h-100 rounded"
                      />
                    </Link>
                  </div>
                </div>
                <div className="t-12 text-uppercase my-3 col-md-2">
                  <Link
                    // className="text-dark"
                    to={`/category/subcategory/${el?._id}`}
                    style={{ textDecoration: "none" }}
                  >
                    {el?.name}
                  </Link>
                </div>
                <div className="t-12 text-uppercase my-3 col-md-2">
                  {el?.discount}
                </div>
                <div className="t-12 text-uppercase my-3 col-md-2">
                  {el?.commission}
                </div>
                <div className="t-12 text-uppercase my-3 col-md-2">
                  {el?.SubCategoryCount} Sub categories
                </div>
                <div className="t-12 text-uppercase my-3 col-md-1">
                  <svg
                    className="bi bi-pencil border-bottom border-dark"
                    onClick={() => {
                      setModal(true);
                      setCategory(el);
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                  </svg>
                </div>
                <div className="t-12 text-uppercase my-3 col-md-2">
                  <div className="m-auto" >
                    <label className="switch">
                      <input className="switch-input" type="checkbox"
                        checked={el.activated}
                        onChange={e => e.target.checked ? approveAdmin(e.target.checked, el?._id) : approveAdmin(e.target.checked, el?._id)}

                      />
                      <span className="switch-label"></span>
                      <span className="switch-handle"></span>
                    </label>
                  </div>
                </div>

                {/* <div className="t-12 text-uppercase my-3 col-md-1">
                <div class="dropdown">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    data-menu-target={`drop-down-menu-${el?._id}`}
                    className="bi bi-three-dots dropdown-btn cursorPointer"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                  </svg>
                  <div
                    id={`drop-down-menu-${el?._id}`}
                    className="dropdown-content p-2 mobile-dropdown"
                  >
                    <div
                      className="px-3 py-2 d-flex cursorPointer"
                      onClick={() =>
                        setActionModal({
                          ...actionModal,
                          show: true,
                          data: el,
                          type: "DELETE",
                        })
                      }
                    >
                      <span className="mx-2 d-flex align-items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-trash3"
                          viewBox="0 0 16 16"
                        >
                          <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                        </svg>
                      </span>
                      <span className="mx-2">
                        <div className="font-weight-bold">Delete</div>
                        <div className="">Delete this product</div>
                      </span>
                    </div>
                    <div className="px-3 py-2 d-flex cursorPointer" onClick={() => setActionModal({ ...actionModal, show: true, data: el, type: "ARCHIEVE" })}>
                      <span className="mx-2 d-flex align-items-center">
                        <svg width="16" height="16" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2.44444 22C1.77222 22 1.19656 21.7609 0.717444 21.2826C0.239148 20.8034 0 20.2278 0 19.5556V4.27778C0 3.97222 0.0509259 3.69722 0.152778 3.45278C0.25463 3.20833 0.387037 2.97407 0.55 2.75L2.26111 0.672222C2.42407 0.448148 2.62778 0.279889 2.87222 0.167445C3.11667 0.0558149 3.38148 0 3.66667 0H18.3333C18.6185 0 18.8833 0.0558149 19.1278 0.167445C19.3722 0.279889 19.5759 0.448148 19.7389 0.672222L21.45 2.75C21.613 2.97407 21.7454 3.20833 21.8472 3.45278C21.9491 3.69722 22 3.97222 22 4.27778V19.5556C22 20.2278 21.7609 20.8034 21.2826 21.2826C20.8034 21.7609 20.2278 22 19.5556 22H2.44444ZM2.93333 3.66667H19.0667L18.0278 2.44444H3.97222L2.93333 3.66667ZM2.44444 6.11111V19.5556H19.5556V6.11111H2.44444ZM11 18.3333L15.8889 13.4444L14.1778 11.7333L12.2222 13.6889V8.55556H9.77778V13.6889L7.82222 11.7333L6.11111 13.4444L11 18.3333ZM2.44444 19.5556H19.5556H2.44444Z" fill="#666666" />
                        </svg>
                      </span>
                      <span className="mx-2">
                        <div className="font-weight-bold">Archive</div>
                        <div className="">Archive this product</div>
                      </span>
                    </div>
                  </div>
                </div>
              </div> */}


              </div>
            ))}

            {categoryListLoader == true && (
              <div className="text-center mt-5">
                <button
                  class="btn mt-5"
                  style={{ backgroundColor: "#FFEDC2" }}
                  type="button"
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  Loading...
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="row m-2 d-flex justify-content-center align-items-center">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          pageClassName="page-item mb-0"
          subContainerClassName="pages "
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          marginPagesDisplayed={2}
          containerClassName="pagination"
          activeClassName="active"
        />
      </div>

      {/* Catgory model */}
      <Modal
        isOpen={modal}
        onRequestClose={closeCategoryModal}
        style={customModalStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className="px-3 py-3">
          <div className="row ">
            {category?._id ? (
              <h4>Update Category Form</h4>
            ) : (
              <h4>Add Category Form</h4>
            )}
            <span className="d-flex justify-content-end cursorPointer">
              <svg
                onClick={closeCategoryModal}
                className="d-inline"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </span>
          </div>
          <div className="row">
            <div className="form-group my-2">
              <label className="">Category Name <span className="text-danger" >*</span></label>
              <input
                name="name"
                value={category?.name}
                onChange={(event) => {
                  setCategory((prevCategory) => ({
                    ...prevCategory,
                    name: event.target.value,
                  }));
                  setcategoryError({ ...categoryError, error: "", name: "" });
                }}
                type="text"
                autoComplete="off"
                className={`placeholder-light form-control my-2 py-2 px-3 ${categoryError?.name && "border-danger"
                  }`}
                placeholder="Enter category name here..."
              />
              <span className="text-danger">{categoryError?.name}</span>
            </div>
          </div>
          <div className="row">
            <div className="form-group my-2">
              <label className="">Discount</label>
              <input
                name="discount"
                value={category?.discount}
                onChange={(event) => {
                  const inputValue = event.target.value;
                  if (!isNaN(inputValue) && Number(inputValue) >= 0) {
                    setCategory((prevCategory) => ({
                      ...prevCategory,
                      discount: event.target.value,
                    }));
                    setcategoryError({
                      ...categoryError,
                      error: "",
                      discount: "",
                    });
                  }
                }}
                maxLength={3}
                onInput={(e) => {
                  if (e.target.value.length > e.target.maxLength) {
                    e.target.value = e.target.value.slice(
                      0,
                      e.target.maxLength
                    );
                  }
                }}
                type="number"
                autoComplete="off"
                className={`placeholder-light form-control my-2 py-2 px-3  remove-spinner ${categoryError?.discount && "border-danger"}`}
                placeholder="Enter discount here..."
                onWheelCapture={(e) => e.target.blur()}
              />
              <span className="text-danger">{categoryError?.discount}</span>
            </div>
          </div>
          <div className="row">
            <div className="form-group my-2">
              <label className="">Commission</label>
              <input
                name="commission"
                value={category?.commission}
                onChange={(event) => {
                  const inputValue = event.target.value;
                  if (!isNaN(inputValue) && Number(inputValue) >= 0) {
                    setCategory((prevCategory) => ({
                      ...prevCategory,
                      commission: event.target.value,
                    }));
                    setcategoryError({
                      ...bulkCategories,
                      error: "",
                      commission: "",
                    });
                  }
                }}
                maxLength={3}
                onInput={(e) => {
                  if (e.target.value.length > e.target.maxLength) {
                    e.target.value = e.target.value.slice(
                      0,
                      e.target.maxLength
                    );
                  }
                }}
                type="number"
                autoComplete="off"
                className={`placeholder-light form-control my-2 py-2 px-3  remove-spinner ${categoryError?.commission && "border-danger"}`}
                placeholder="Enter commission here..."
                onWheelCapture={(e) => e.target.blur()}
              />
              <span className="text-danger">{categoryError?.commission}</span>
            </div>
          </div>
          <div className="row">
            <div className="form-group my-2">
              <label className="">Upload Image <span className="text-danger" >*</span></label>
              <input
                name="image"
                type="file"
                autoComplete="off"
                onChange={(event) => {
                  const file = event.target.files[0];
                  setCategory((prevCategory) => ({
                    ...prevCategory,
                    image: "",
                    imageFile: file,
                    imagePreview: URL.createObjectURL(file),
                  }));
                  setBulkCategories({
                    ...bulkCategories,
                    error: "",
                    image: "",
                  });
                  setcategoryError({ ...bulkCategories, image: "" });
                }}
                accept="image/png, image/gif, image/jpeg"
                className={`py-2 opacity0 position-absolute placeholder-light form-control py-2 px-3 border`}
                placeholder="eg: https://www.apple.com/in/watch"
              />


              {/* <input src={URL.createObjectURL('file:///C:/Users/sures/Downloads/tree-736885__480%20(4).jpg')} /> */}

              <div
                className={`form-group my-2 my-2 py-2 select-text-light d-flex align-items-center px-3 border rounded ${categoryError?.image && "border-danger"
                  }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-cloud-arrow-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z"
                  />
                  <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                </svg>
                <span className="px-2">Upload image here...</span>
              </div>
              {category?.image ||
                category?.imagePreview
                ? (
                  <div className="d-flex" >
                    <div className="d-flex col-2" >
                      <img
                        id="categoryPageModalImage"
                        src={category?.image || category?.imagePreview}
                        alt=""
                        className="roundedshadow-sm mt-2"
                        style={{
                          width: "100%",
                          height: "100px",
                          objectFit: "cover",
                          borderRadius: '5%'
                        }}
                      />
                      <div
                        className="image-containerr-category"
                        onClick={() => setCategory({ ...category, imageFile: "", imagePreview: "", image: "" })}
                      >
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              <span className="text-danger">{categoryError?.image}</span>
            </div>
          </div>

          {category?._id ? (
            ""
          ) : (
            <div>
              <p className="d-flex justify-content-center">or</p>
              <div>
                <h6>Uploading many categories at once?</h6>
                <p className="select-text-light">
                  Upload a file in a .csv format{" "}
                  <span
                    className="text-success fw-bold"
                    role="button"
                    onClick={() => downloadFile(BulkCategoryCsv)}
                  >
                    Download sample format here
                  </span>
                </p>
                {/* Bulk Upload */}
                <input
                  name="file"
                  type="file"
                  autoComplete="off"
                  accept=".csv"
                  onChange={handleFileChange}
                  value={bulkCategories.ele}
                  className={`py-2 opacity0 position-absolute  w-25 placeholder-light form-control py-2 px-3 border`}
                  placeholder="eg: https://www.apple.com/in/watch"
                />

                <div className="d-flex">
                  <div
                    className={`my-2 col-5 py-2 select-text-light w-25 d-flex align-items-center px-3 border rounded ${bulkCategories?.error != "" ? "border-danger" : ""
                      }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-cloud-arrow-up"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z"
                      />
                      <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                    </svg>
                    <span className="px-2">Upload</span>
                  </div>
                  <div className="col-1"></div>
                  <div className="col-6 form-group my-2">
                    <button
                      className="btn btn-dark text-warning px-5"
                      style={{ backgroundColor: "#12352f" }}
                      onClick={() => {
                        bulkCategories.bulkData.length != 0
                          ? handlePreview()
                          : setBulkCategories({
                            ...bulkCategories,
                            error: "select",
                          });
                      }}
                    >
                      {" "}
                      Preview
                    </button>
                  </div>
                </div>
                <div className="row justify-content-md-start" >
                  <div className="col-3" >
                    {bulkCategories.bulkData.length != 0 ? (
                      <div
                        className="bg-danger rounded categoryImageDiv shadow-sm "
                        style={{ width: "80px", height: "50px" }}
                      >
                        <div
                          className="image-containerr-excell"
                          onClick={() =>
                            setBulkCategories({
                              ...bulkCategories,
                              bulkData: [],
                              error: "",
                              ele: "",
                            })
                          }
                        ></div>
                        <img src={excell} className="h-100 rounded" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* bulk model */}
          <Modal
            isOpen={bulkCategories.show}
            onRequestClose={() =>
              setBulkCategories({ ...bulkCategories, show: false })
            }
            style={customModalStyles}
            contentLabel="Example Modal"
            ariaHideApp={false}
          >
            <div className="px-3 py-3">
              <div className="row ">
                <h4 className="font-weight-bold t-16 text-uppercase" >Bulk Upload preview</h4>

                <span className="d-flex justify-content-end cursorPointer">
                  <svg
                    onClick={() =>
                      setBulkCategories({
                        ...bulkCategories,
                        show: false,
                        bulkData: [],
                        error: "",
                        ele: ""
                      })
                    }
                    className="d-inline"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                  </svg>
                </span>
              </div>
              <div className="row">
                <div>
                  <div className="row rounded px-3 bg-white">
                    {/* header */}
                    <div className="row m-0  text-center">
                      <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-3">
                        Sl.No
                      </div>
                      <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-3">
                        Category Name
                      </div>
                      <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-3">
                        Discount
                      </div>
                      <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-3">
                        Commission
                      </div>
                    </div>

                    {/* body */}
                    <div className="m-0 p-0 pe-3 overflow-auto caterogyPageScroll  text-center" >
                      {bulkCategories?.bulkData?.map((el,ind) => (
                        <div
                          className="row border rounded m-0 px-2  mb-2 d-flex align-items-center"
                          key={el?._id}
                        >
                          {/* <div className="t-12 text-uppercase my-3 col-md-3">
                            <div className="categoryImageDiv shadow-sm  rounded p-1">
                              <img
                                src={el?.Image}
                                className="categoryImage h-100 rounded"
                              />
                            </div>
                          </div> */}
                          <div className="t-12 text-uppercase my-3 col-md-3">
                            {ind + 1}
                          </div>
                          <div className="t-12 text-uppercase my-3 col-md-3">
                            {el?.CateogoryName}
                          </div>
                          <div className="t-12 text-uppercase my-3 col-md-3">
                            {el?.Discount}
                          </div>
                          <div className="t-12 text-uppercase my-3 col-md-3">
                            {el?.Commission}
                          </div>
                        </div>
                      ))}
                    </div>
                    <div>
                      <button
                        className="btn btn-dark text-warning px-5"
                        onClick={Submit}
                        style={{ backgroundColor: "#12352f" }}
                      >
                        Submit{" "}
                        {
                          loader
                            ?
                            <Spinner
                              animation="border"
                              variant="light"
                              size="sm"
                            />
                            :
                            ''
                        }
                      </button>
                      <button
                        className="btn btn-white text-dark shadow-lg  px-5"
                        style={{ marginLeft: "10px", backgroundColor: "#FFFFFF", color: '#000000', boxShadow: '0 23.486127853393555px 0 rgba(0, 0, 0, 0.25)' }}
                        onClick={() =>
                          setBulkCategories({
                            ...bulkCategories,
                            show: false,
                            bulkData: [],
                            error: "",
                            ele: ""
                          })
                        }
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          {bulkCategories.bulkData.length == 0
            ?
            <div className="form-group my-3">
              <button
                className="btn btn-dark text-warning px-5"
                onClick={Submit}
                style={{ backgroundColor: "#12352f" }}
              >
                Submit{" "}
                {
                  loader
                    ?
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span class="sr-only"></span>
                    </div>
                    :
                    ''
                }
              </button>
            </div>
            :
            ''
          }

        </div>
      </Modal >

      {/* <Modal
        isOpen={modal1}
        onRequestClose={closeCategoryModal}
        style={customModalStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className="px-3 py-3">
          <div className="row ">
            {category?._id ? (
              <h4>Update Category Form</h4>
            ) : (
              <h4>Add Category Form</h4>
            )}
            <span className="d-flex justify-content-end cursorPointer">
              <svg
                onClick={() => setModal1(false)}
                className="d-inline"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </span>
          </div>

          <input
            name="image"
            type="file"
            autoComplete="off"
            multiple
            onChange={(event) => {
              const file = event.target.files;
              setBulkImages(file);
              setcategoryError({ ...bulkCategories, image: "" });
            }}
            accept="image/png, image/gif, image/jpeg"
            className={`py-2  position-absolute placeholder-light form-control py-2 px-3 border`}
            placeholder="eg: https://www.apple.com/in/watch"
          />

          <div className="form-group my-3">
            <button
              disabled={loader}
              className="btn btn-dark text-warning px-5 mt-5"
              onClick={Submit1}
            >
              Submit1
            </button>
          </div>
        </div>
      </Modal> */}

      {/* Action Model */}
      <Modal
        isOpen={actionModal?.show}
        onRequestClose={() => setActionModal({})}
        style={customActionModalStyles}
        contentLabel="Action Modal"
        ariaHideApp={false}
      >
        <div className="px-3 py-3">
          <div className="row">
            <span className="d-flex justify-content-end cursorPointer">
              <svg
                onClick={() => setActionModal({})}
                className="d-inline"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </span>
          </div>
          <div className="row">
            <h4 className="text-danger d-flex justify-content-center">
              Do you want to {actionModal?.type} Category?
            </h4>
          </div>
          <div className="row my-5">
            <h5 className="d-flex justify-content-center">
              {actionModal?.data?.name}
            </h5>
          </div>
          <div className="form-group my-3 d-flex justify-content-center">
            <button
              disabled={actionModal?.loader}
              className="btn btn-dark me-3 mb-2 text-warning px-5"
              onClick={() =>
                actionModal?.type === "DELETE"
                  ? deleteCategory(actionModal?.data?._id)
                  : archieveCategory(actionModal?.data?._id)
              }
            >
              {actionModal?.loader ? (
                <div class="spinner-border spinner-border-sm" role="status"
                // style={{ backgroundColor: "#12352f" }}

                >
                  <span class="sr-only"></span>
                </div>
              ) : (
                "Submit"
              )}
            </button>
            <button
              disabled={actionModal?.loader}
              className="btn btn-light me-3 mb-2 shadow-sm text-dark border px-5"
              onClick={() => setActionModal({})}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </MainTemplate >
  );
}

export default CategoryPage;
