import React, { useEffect, useMemo, useState } from 'react'
import MainTemplate from '../../components/MainTemplate'
import './custominventory.css'
import { RiDeleteBin6Line } from "react-icons/ri"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { HIBEE_ADMIN_URL, HIBEE_AUTH_URL, HIBEE_PRODUCT_URL, INVENTORY, PRODUCT_PATH, PRODUCT_SUBCATEGORY } from "../../api_interface/apiURLs";
import { customActionModalStyles, customModalStyles, debounce } from "../../utils/helperFunctin";
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getTokenApi, postTokenApi } from "../../api_interface/apis";
import { useNavigate, useLocation } from "react-router-dom";

function Custominventory() {
    const navigate = useNavigate();
    const location = useLocation();
    const data = location?.state?.data;
    console.log(data, 'comingdata');
    const { subCategoryId } = useParams()
    const [sort, setSort] = useState("")
    const [query, setQuery] = useState("")
    const [products, setProducts] = useState([]);
    const [validationError, setValidationError] = useState(false); // Step 1: Validation state

    const [items, setItems] = useState(data?._id
        ? [
            {
                _id: data._id || "",
                product_name: data.product_name || "",
                Qty: "",
                unit: `${data.sizeList?.size} ${data.sizeList?.unit}` || "",
                exp_date: "",
                rate: "",
                query: "", // Add a 'query' state for each item
                showDropdown: false, // Add a 'showDropdown' state for each item
            },
        ]
        : [
            {
                _id: "",
                product_name: "",
                Qty: "",
                unit: "",
                exp_date: "",
                rate: "",
                query: "", // Add a 'query' state for each item
                showDropdown: false, // Add a 'showDropdown' state for each item
            },
        ]
    );


    const [customeInventory, setCustomeInventory] = useState([
        {
            invoiceNumber: "",
            purchaseDate: "",
            cgst: "",
            sgst: "",
            totalbillamount: "",
        },
    ]);

    console.log(customeInventory,'jhj');


    const getProducts = async search => {
        let res
        if (subCategoryId) {
            res = await postTokenApi(HIBEE_PRODUCT_URL + PRODUCT_PATH + PRODUCT_SUBCATEGORY + `?search=${search || query || ""}`, { subcategory: subCategoryId });
        } else {
            res = await getTokenApi(HIBEE_PRODUCT_URL + PRODUCT_PATH + `?&search=${search || query || ""}`);
        }
        if (res.status == 200) {
            setProducts(res?.data?.data);
        }
    };

    const getSearchedData = useMemo(() => debounce(async (search) => {
        getProducts(search)
    }, 500), [])


    const [selectedProducts, setSelectedProducts] = useState({});


    useEffect(() => {
        getSearchedData(query)
    }, [query])

    const addNewItem = () => {
        const newItem = {
            _id: "",
            product_name: "",
            Qty: "",
            unit: "",
            exp_date: "",
            rate: "",
            query: "", // Add a 'query' state for each item
            showDropdown: false, // Add a 'showDropdown' state for each item
        }

        setItems([...items, newItem]);
    };
    const handleInputChange = (index, field, value) => {
        setItems((prevItems) => {
            const updatedItems = [...prevItems];
            updatedItems[index][field] = value;

            // Calculate the total amount if both rate and quantity are available
            if (field === "rate" || field === "Qty") {
                const rate = parseFloat(updatedItems[index].rate);
                const Qty = parseFloat(updatedItems[index].Qty);

                if (!isNaN(rate) && !isNaN(Qty)) {
                    updatedItems[index].total = rate * Qty;
                } else {
                    updatedItems[index].total = 0;
                }
            }

            setCustomeInventory((prevInventory) => [
                {
                    ...prevInventory[0],
                    fields: updatedItems,
                },
            ]);

            return updatedItems;
        });
    };

    const handleItemSelect = (selectedProduct, index) => {
        setItems(prevItems => {
            const updatedItems = prevItems.map((item, i) => {
                if (i === index) {
                    return {
                        ...item,
                        _id: selectedProduct._id,
                        product_name: selectedProduct.product_name,
                        unit: selectedProduct.sizeList ? ` ${selectedProduct.sizeList.size} ${selectedProduct.sizeList.unit}` : '',
                        selectedProduct: selectedProduct,
                        showDropdown: false, // Hide the dropdown after selecting the product for the current item
                    };
                }

                return item;
            });

            return updatedItems;
        });

        setItems(prevItems => {
            const updatedItems = prevItems.map((item, i) => {
                if (i === index) {
                    return {
                        ...item,
                        selectedProduct: selectedProduct, // Set the selected product for the current item
                    };
                }

                return item;
            });

            return updatedItems;
        });

        setQuery('');
        setShowDropdown(false);
    };






    const handleDeleteItem = (index) => {
        const updatedItems = [...items];
        updatedItems.splice(index, 1);
        setItems(updatedItems);
    };
    const [showDropdown, setShowDropdown] = useState(false);

    const handleInputChangedropdown = (e, index) => {
        const main = e.target.value;
        const shouldFetchProducts = main.length >= 2; // Check if length is two or more

        setItems(prevItems => {
            const updatedItems = [...prevItems];
            updatedItems[index].query = main; // Update the 'query' state for the specific item

            // Update the 'showDropdown' state for the specific item
            updatedItems[index].showDropdown = shouldFetchProducts;

            if (shouldFetchProducts) {
                // Fetch products only when the length is two or more
                getSearchedData(main);
            }

            return updatedItems;
        });
    };



    console.log(customeInventory, "inventr");

    const [formattedDate, setFormattedDate] = useState("");

    const handleDateChange = (date) => {
        const options = {
            timeZone: "Asia/Kolkata",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        };
        const formattedDate = date
            ? date.toLocaleDateString("en-GB", options).split("/").reverse().join("-")
            : "";
        setFormattedDate(formattedDate);
        setCustomeInventory([
            {
                ...customeInventory[0],
                purchaseDate: formattedDate,
            },
        ])
    };


    const handleSubmitInventory = async (e) => {
        e.preventDefault();
        if (!customeInventory[0]?.invoiceNumber || !customeInventory[0]?.purchaseDate || !items.every(item => item.product_name && item.Qty && item.rate)) {
            setValidationError(true);
            return;
        }

        // Validation passed, so clear the error
        setValidationError(false);
        try {
            const data = {
                invoice_number: customeInventory[0]?.invoiceNumber,
                purchase_date: customeInventory[0]?.purchaseDate,
                product: items,
                cgst: customeInventory[0]?.cgst,
                sgst: customeInventory[0]?.sgst,
                final_bill_amount: customeInventory[0]?.totalbillamount
            };

            const res = await postTokenApi(HIBEE_ADMIN_URL + INVENTORY, data, {
                "Content-Type": "application/json"
            });

            toast.success(res?.data?.message, {
                position: "top-center",
                autoClose: 2000
            });

            navigate("/custominventorylist");
        } catch (err) {
            toast.error(err?.response?.data?.message, {
                position: "top-center",
                autoClose: 2000
            });
        }
    };

    const today = new Date();
    // Set hours, minutes, seconds, and milliseconds to 0 to ensure only the date is considered
    today.setHours(0, 0, 0, 0);




    return (
        <MainTemplate
            categoryName="Custom Inventory"
            categoryList={["Inventory", "Custom Inventory"]}
        >
            <div className='bg-white' style={{ paddingLeft: 22, paddingRight: 22 }}>
                <div className='d-flex justify-content-between align-items-center gap-5' style={{ paddingTop: 26, width: 350 }}>
                    <p className='custominventory500'>Invoice Number<span className='text-danger'>*</span></p>
                    <input
                        className="custominventory400 customeinventoryData me-1"
                        type="text"
                        placeholder="Invoice No"
                        // value={customeInventory[0].invoiceNumber} // Access the first item in the state array
                        onChange={(e) =>
                            setCustomeInventory([
                                {
                                    ...customeInventory[0],
                                    invoiceNumber: e.target.value,
                                },
                            ])
                        }
                    />
                </div>

                <div className='d-flex justify-content-between align-items-center gap-5' style={{ paddingTop: 26, width: 350 }}>
                    <p className='custominventory500'>Purchase Date<span className='text-danger'>*</span></p>
                    <p>
                        <DatePicker
                            className="custominventory400 customeinventoryData me-1"
                            placeholderText="Enter Date"
                            value={formattedDate ? formattedDate : "YYYY-MM-DD"}
                            onChange={handleDateChange}
                            dateFormat={"yyyy-MM-dd"}
                            maxDate={today}
                        />
                    </p>

                </div>

                <div style={{ paddingTop: 30 }}>
                    <div className='d-flex justify-content-between'>
                        <p></p>
                        <button className='addInventoryButton' onClick={addNewItem}>
                            +  Add Item
                        </button>
                    </div>
                    <div className=' mt-2'>
                        <table className=" w-100">
                            <thead className="">
                                <tr className="">
                                    <th className="orderstableheading "><div className="d-flex align-items-center p-1">
                                        <p className="m-0">Item Name<span className='text-danger'>*</span></p></div></th>
                                    <th className="orderstableheading "><div className="d-flex align-items-center p-1">
                                        <p className="m-0">Qty<span className='text-danger'>*</span></p></div></th>
                                    <th className="orderstableheading "><div className="d-flex align-items-center p-1">
                                        <p className="m-0">Unit<span className='text-danger'>*</span></p></div></th>
                                    <th className="orderstableheading "><div className="d-flex align-items-center p-1">
                                        <p className="m-0">Exp Date<span className='text-danger'>*</span></p></div></th>
                                    <th className="orderstableheading "><div className="d-flex align-items-center p-1">
                                        <p className="m-0">Rate<span className='text-danger'>*</span></p></div></th>
                                    <th className="orderstableheading "><div className="d-flex align-items-center p-1">
                                        <p className="m-0">Total<span className='text-danger'>*</span></p></div></th>
                                    <th className="orderstableheading "><div className="d-flex align-items-center p-1">
                                        <p className="m-0"></p></div></th>
                                </tr>
                            </thead>
                            <tbody className="">
                                {items.map((item, index) => (
                                    <>
                                        <tr key={index} className="" style={{ marginTop: 1 }}>

                                            <td className="orderstabledetails">
                                                {item.product_name ? (
                                                    <div className="d-flex align-items-center custominventory400 customeinventoryData me-1">
                                                        {item.product_name}
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <input
                                                            className="custominventory400 customeinventoryData me-1"
                                                            type="text"
                                                            placeholder="Enter Item Name"
                                                            value={item.query} // Use the 'query' state for each item
                                                            onChange={(e) => handleInputChangedropdown(e, index)} // Pass the index of the item
                                                            onFocus={() => setShowDropdown(item.query.length > 0)} // Use the 'query' state for each item
                                                        />

                                                    </div>
                                                )}
                                            </td>
                                            <td className="orderstabledetails " >
                                                <input
                                                    className="custominventory400 customeinventoryData me-1"
                                                    type="number"
                                                    // style={{ width: 110 }}
                                                    placeholder="Qty"
                                                    value={item.Qty || ''}
                                                    onWheelCapture={(e)=>e.target.blur()}

                                                    onChange={(e) => handleInputChange(index, 'Qty', e.target.value)}
                                                />

                                            </td>
                                            <td className="orderstabledetails">
                                                <div className="d-flex align-items-center custominventory400 customeinventoryData   me-1"
                                                // style={{ width: '110px' }}
                                                >
                                                    {item?.unit} { }
                                                </div>
                                            </td>
                                            <td className="orderstabledetails">
                                                <DatePicker
                                                    className="custominventory400 customeinventoryData me-1"
                                                    placeholderText="Enter Exp Date"
                                                    selected={item.exp_date ? new Date(item.exp_date) : null}
                                                    onChange={(date) => handleInputChange(index, 'exp_date', date)}
                                                    minDate={today}
                                                />
                                            </td>

                                            <td className="orderstabledetails">
                                                <input
                                                    className="custominventory400 customeinventoryData  me-1"
                                                    type="number"
                                                    // style={{ width: 110 }}
                                                    placeholder="Enter Rate"
                                                    value={item.rate || ''}
                                                    onWheelCapture={(e)=>e.target.blur()}

                                                    onChange={(e) => handleInputChange(index, 'rate', e.target.value)}
                                                />
                                            </td>
                                            <td className="orderstabledetails">
                                                <div className="d-flex align-items-center custominventory400 customeinventoryData py-3  me-1"
                                                // style={{ width: 110 }}
                                                >
                                                    {item.rate && item.Qty ? `₹ ${Number(item.rate) * Number(item.Qty)}` : ''}
                                                </div>
                                            </td>
                                            <td className="orderstabledetails">
                                                <div className="d-flex justify-content-center align-items-center me-1">
                                                    <p className="custominventory400 me-1 p-0">
                                                        <RiDeleteBin6Line
                                                            className="text-danger"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => handleDeleteItem(index)}
                                                        />
                                                    </p>
                                                </div>
                                            </td>
                                        </tr>
                                        {item.showDropdown && (
                                            <div className=''>
                                                {!products || products.length === 0 ? ( // Update this condition
                                                    <Link to='/inventory-product-add' className='itemSelectingdropdown' style={{ cursor: "pointer" }}>
                                                        <p className='p-0 m-0'>Add New Item</p>
                                                    </Link>
                                                ) : (
                                                    <>
                                                        {products.map((el, dropdownIndex) => (
                                                            <div
                                                                className='itemSelectingdropdown'
                                                                key={dropdownIndex}
                                                                onClick={() => handleItemSelect(el, index)}
                                                            >
                                                                {el?.images && el.images.length > 0 ? (
                                                                    <img className='itemSelectingdropdownimg' src={el.images[0].image} alt={el.product_name} />
                                                                ) : (
                                                                    <img src={el?.image} alt="loading" className="categoryImage h-100 rounded" />
                                                                )}
                                                                <p className='p-0 m-0'>{el.product_name}</p>
                                                                <p className='p-0 m-0'>{`${el.sizeList?.size} ${el.sizeList?.unit}`}</p>
                                                            </div>
                                                        ))}
                                                        <Link to='/inventory-product-add' className='itemSelectingdropdown' style={{ cursor: "pointer" }}>
                                                            <p className='p-0 m-0'>Add New Item</p>
                                                        </Link>
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </>
                                ))}





                            </tbody>
                        </table>
                    </div>
                    <div className='d-flex justify-content-between align-items-center gap-5 ' style={{ marginTop: 46, width: 350 }}>
                        <p className='custominventory500'>CGST Amount<span className='text-danger'>*</span></p>
                        <input
                            className="custominventory400 customeinventoryData mt-1"
                            type="number"
                            onWheelCapture={(e)=>e.target.blur()}

                            placeholder="Enter Amount"
                            // value={customeInventory[0].invoiceNumber} // Access the first item in the state array
                            onChange={(e) =>
                                setCustomeInventory([
                                    {
                                        ...customeInventory[0],
                                        cgst: e.target.value,
                                    },
                                ])
                            }
                        />                    </div>
                    <div className='d-flex justify-content-between align-items-center gap-5 ' style={{ paddingTop: 2, width: 350 }}>
                        <p className='custominventory500'>SGST Amount<span className='text-danger'>*</span></p>
                        <input
                            className="custominventory400 customeinventoryData mt-1"
                            type="number"
                            onWheelCapture={(e)=>e.target.blur()}

                            placeholder="Enter Amount"
                            // value={customeInventory[0].invoiceNumber} // Access the first item in the state array
                            onChange={(e) =>
                                setCustomeInventory([
                                    {
                                        ...customeInventory[0],
                                        sgst: e.target.value,
                                    },
                                ])
                            }
                        />                       </div>
                    <div className='d-flex justify-content-between align-items-center gap-5 ' style={{ paddingTop: 2, width: 350 }}>
                        <p className='custominventory500'>Final Bill Amount<span className='text-danger'>*</span></p>
                        <input
                            className="custominventory400 customeinventoryData mt-1"
                            type="number"
                            onWheelCapture={(e)=>e.target.blur()}

                            placeholder="Enter Amount"
                            // value={customeInventory[0].invoiceNumber} // Access the first item in the state array
                            onChange={(e) =>
                                setCustomeInventory([
                                    {
                                        ...customeInventory[0],
                                        totalbillamount: e.target.value,
                                    },
                                ])
                            }
                        />                       </div>
                    <div className='d-flex gap-4 py-2'>
                        <button className='addInventoryButton' onClick={handleSubmitInventory}>
                            Submit
                        </button>
                        <button
                            className='addInventoryButton'
                            onClick={() => {
                                setItems([
                                    {
                                        _id: "",
                                        product_name: "",
                                        Qty: "",
                                        unit: "",
                                        exp_date: "",
                                        rate: "",
                                        query: "", // Add a 'query' state for each item
                                        showDropdown: false, // Add a 'showDropdown' state for each item
                                    },
                                ]);
                                setCustomeInventory([
                                    {
                                        invoiceNumber: "",
                                        purchaseDate: "",
                                        cgst: "",
                                        sgst: "",
                                        totalbillamount: "",
                                    },
                                ]);
                            }}
                        >
                            Cancel
                        </button>

                    </div>
                    {validationError && (
                        <p className="text-danger">Please fill in all the required fields.</p>
                    )}
                </div>
            </div>

        </MainTemplate >
    )
}

export default Custominventory
